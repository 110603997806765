import { createContext, ReactElement, useMemo, useState ,useEffect} from 'react';

export const LeadContext = createContext<any>('');

interface LeadContextProviderProps {
  children: ReactElement | ReactElement[];
}

export const LeadContextProvider = (props: LeadContextProviderProps) => {
  const { children } = props;
  const [selectedOffers, setSelectedOffers] = useState<any>([]);
  const [availableOffers, setAvailableOffers] = useState<any>(null);
  const leadValues = useMemo(
    () => ({
      selectedOffers,
      setSelectedOffers,
      availableOffers,
      setAvailableOffers,
    }),
    [selectedOffers,availableOffers],
  );

  return (
    <LeadContext.Provider value={leadValues}>{children}</LeadContext.Provider>
  );
};
