import React, { useEffect, useState } from 'react'
import { Box, Typography, Card, CardContent, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { cardProperty } from '../../constants/styles';
import { LeadFormMilitaryAffiliationName, LeadFormMilitaryAffiliationTitle, LeadFormMilitaryConfirmation, LeadFormMilitaryOptions } from '../../constants/leadForms';

const LeadFormMilitary = ({ onNext, leadFormData, setLeadFormData }: any) => {
  const [isYesClicked, setIsYesClicked] = useState<Boolean>(false);
  const [isMilitaryChange, setIsMilitaryChange] = useState('')
  const [selectedCard, setSelectedCard] = useState(null);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const handleCardClick = (confirmName: any) => {
    setLeadFormData({ ...leadFormData, military: confirmName });
    setIsYesClicked(confirmName === 'Yes');
    setSelectedCard(confirmName)
    if (confirmName === 'No') {
      onNext();
    }
  };

  const handleChangeMilitary = (event: any) => {
    setLeadFormData({ ...leadFormData, militaryAffiliationName: event.target.value });
    setIsMilitaryChange(event.target.value)
    onNext();
  }

  return (
    <Box>
      <Box textAlign='center'>
        <Typography component="h5" fontSize="30px" maxWidth="600px" lineHeight="1.3" mx="auto" fontWeight="800" mb={2}>{LeadFormMilitaryAffiliationTitle}</Typography>
      </Box>
      <Box display='flex' flexWrap='wrap' justifyContent='center' gap="30px" maxWidth="510px" mx="auto">
        {LeadFormMilitaryConfirmation.map((confirm, index) =>
          <Card sx={cardProperty} key={index} onClick={() => handleCardClick(confirm.name)}
            className={`frm-card ${selectedCard === confirm.name ? 'selected' : ''}`}
          >
            <img src={confirm.src} alt='icons' />
            <CardContent sx={{ p: "0 !important" }}>
              <Typography fontWeight="600" lineHeight="1.3" fontSize="14px" px={2} sx={{ height: "48px" }}> {confirm.name} </Typography></CardContent>
          </Card>
        )}
      </Box>

      {isYesClicked && (
        <Box mt={3}>
          <Box textAlign="center">
            <Typography component="h6" fontSize="22px" maxWidth="600px" lineHeight="1.3" mx="auto" fontWeight="800">{LeadFormMilitaryAffiliationName}</Typography>
          </Box>

          <FormControl sx={{ width: "300px", display: "flex", m: "8px auto 0" }}>
            <InputLabel>Military Affiliation</InputLabel>
            <Select sx={{ backgroundColor: '#ffffff !important' }}
              value={isMilitaryChange}
              onChange={handleChangeMilitary}
              label="Military Affiliation"
            >
              {LeadFormMilitaryOptions.map((military,index) =>
                <MenuItem key={index} value={military as any}>{military.name}</MenuItem>
              )}
            </Select>
          </FormControl>
        </Box>
      )
      }
    </Box >
  )
}

export default LeadFormMilitary