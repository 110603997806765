import { Box, Container, Link, List, ListItem, Toolbar, Typography } from '@mui/material'

const toolbarStyle = {
    minHeight: "71px",
};

export default function PrivacyPolicy() {
    return (
        <div>
            <Toolbar style={toolbarStyle} />
            <Box>
                <Container maxWidth="lg" sx={{ py: "30px" }}>
                    <Typography style={{ textAlign: 'center' }} component="h2" fontSize="35px">Privacy Policy</Typography>
                    <Typography fontWeight="600" mb={2}>Last Updated: May 2022</Typography>
                    <Typography mb={2}>The website or online service, and portions of other webpages or online content through which you
                        have accessed this Privacy Policy (collectively, this "Site"), is owned and operated by Media-
                        Matchers LLC (hereafter referred to as "we," "us," "our"or "Media-Matchers"). This Privacy Policy
                        describes how we treat personal information received about you when you visit our Site such as the
                        collection and use of the personal information you provide. It also describes the choices available to
                        you regarding the use of, your access to, and how to update and correct your personal information.</Typography>

                    <Typography mb={2}>This Privacy Policy applies to all Media-Matchers LLC, and all other websites and applications that
                        link to this Privacy Policy("Services"). Other privacy policies may apply to some of our offerings.
                        Please see the Privacy Policy Scope section below. Please read this Privacy Policy carefully,
                        because by visiting or using this Site, you agree to its terms</Typography>

                    <ul>
                        <li><a href='#section101'>Information We Collect</a></li>
                        <li><a href='#section102'>Use of Personal Information</a></li>
                        <li><a href='#section103'>Sharing Practices</a></li>
                        <li><a href='#section104'>Online Advertising and Tracking</a></li>
                        <li><a href='#section105'>Opt-Outs</a></li>
                        <li><a href='#section106'>Data Security</a></li>
                        <li><a href='#section107'>Children's Privacy</a></li>
                        <li><a href='#section108'>Contact us</a></li>
                        <li><a href='#section109'>California Notice at Collection</a></li>
                        <li><a href='#section110'>Nevada Notice at Collection</a></li>
                    </ul>

                    <section id='section101'>
                        <Typography component="h2" fontSize="25px" fontWeight="800" mb={2}>Information that We Collect</Typography>
                        <Typography mb={2}>Personal Information: Depending on the Services you are visiting or using, we may collect various
                            times of information from and about you, including information that can personally identify you. This
                            information includes, but is not limited to: name, age, postal address, email address, telephone
                            number, fax number, short message service or text message address, other wireless device
                            address, instant messaging address, demographic information, education and scholastic information
                            (including but not limited to level of education, desired level of educational program, and areas of
                            interest), current occupation, military status, governmental loan status, information about any
                            transaction you conduct or submit to on or through the Services, and other information that would
                            allow online or offline contact with you as an individual (collectively, "Personal Information"). We may
                            also collect information that you provide to us about other people (such as family members or co-
                            borrowers in the case of www.estudentloan.com), including, but not limited to, their names, postal
                            addresses, telephone numbers, mobile numbers, and email addresses for purposes of fulfilling the
                            purposes for which you provide such information.
                        </Typography>
                    </section>

                    <Typography mb={2}>Non-Personal Information: We also collect Usage Information (as defined below) and other
                        information from and about you that does not personally identify you or allow us or others to contact
                        you individually online or offline ("Non-Personally Identifiable Information" or "Non-PII"). We may
                        combine Non-PII with other information in a way that allows you to be identified or personally
                        contacted. If this happens, we will treat the combined information as Personal Information.</Typography>

                    <Typography mb={2}>How We Collect Information: We may collect information from you in the following ways:</Typography>

                    <Typography mb={2}>• Information Provided by You. We collect information provided by you when you (1) inquire about or
                        obtain our services or products; (2) communicate with us or request information about or from us,
                        whether via email or other means; (3) fill out forms or fields on the Services; (4) register as a user or
                        sign-up for any of our newsletters, materials or services through the Services; (5) enter into online
                        transactions; (6) create profiles; (7) provide college reviews or participate in our online surveys or
                        questionnaires; (8) input information into our interactive programming features or participate in our
                        online forums; (9) participate in any of our marketing initiatives, including contests, scholarship
                        offers, events, or promotions; and (10) post to the Services or provide user-generated content or
                        submissions to us, including without limitation media files, photos, videos, documents or other
                        information, and contributions to a chat room, list serve, blog, or other open forum or community
                        feature that we make available on the Services ("User Contributions").</Typography>

                    <Typography mb={2}> • Information from Other Sources. We may collect information about you from other services that we
                        may provide, our affiliates, third-party or public sources (such as from public forums, social networks,
                        and blogs), other users of the Services, and our business partners.</Typography>

                    <Typography mb={2}> • Automatic Information Collection. We and our service providers use automatic data collection
                        technologies to collect and store Usage Information (as defined below). This information helps us
                        evaluate how our visitors use and navigate the Site on an aggregate basis, including but not limited
                        to the number and frequency of visitors to each Site, and the length of their visits. We may combine
                        this automatically collected log information with other information we collect about you. We do this to
                        improve services we offer you, to improve marketing, analytics, or site functionality. We also may
                        allow third-party advertising companies and ad networks to use automatic data collection
                        technologies to collect Usage Information for purposes of providing interest based ads. See the
                        Automatic Information Collected Technologies and Usage Information and the Third-Party
                        Advertisers and Interest-Based Ads sections for more information.</Typography>

                    <section id='section102'>
                        <Typography component="h2" fontSize="25px" fontWeight="800" mb={2}>How We Use the Information that We Collect</Typography>
                        <Typography mb={2}>We may use your information, including Personal Information, to:</Typography>
                        <Typography> • Provide products and services requested by you;</Typography>
                        <Typography>  • Provide  customer support, including responding to your requests and questions and
                            troubleshooting and resolving problems or complaints, and for any other purposes that we deem
                            appropriate;</Typography>
                        <Typography>  • Verify the information you provide to us; Communicate with you;</Typography>
                        <Typography>  • Confirm your identity and eligibility to use the Services;</Typography>
                        <Typography> • Establish and manage your account, as applicable;</Typography>
                        <Typography>  • Perform research and analysis to develop and improve the Services and other products and
                            services that we may offer;</Typography>
                        <Typography>• Understand and anticipate your use of or interest in, our products, services, and content, and the
                            products, services, and content offered by others;</Typography>
                        <Typography> • Develop and display products, services, and content tailored to your interests on the Services and
                            other online properties;</Typography>
                        <Typography> • Measure the overall effectiveness of our online advertising, content, and programming, and other
                            activities.</Typography>
                        <Typography> • Offer  you personalized information and products, services, promotions, contests, or scholarships
                            of ours, our affiliates, or our third-party business partners that may be of interest to you;</Typography>
                        <Typography> • Manage our business and operations;</Typography>
                        <Typography>  • We use the information we automatically collect (such as contact information, IP address and basic
                            browser and system details) to help us optimize our sites and the services we offer on the Sites and
                            may offer in the future;</Typography>
                        <Typography>  • To provide you with a customized browsing experience;</Typography>
                        <Typography> • Protect the security and integrity of the Services; Carry out our obligations and enforce our rights
                            arising from this Privacy Policy, the Terms of Use and any other applicable agreements and policies;</Typography>
                        <Typography> • Use or post User Contributions as permitted in our Terms of Use; and</Typography>
                        <Typography> • Fulfill any other purposes for which you provide your information and for any other purpose as
                            described to you at the time your information is collected or for which your consent is given.</Typography>
                    </section>

                    <section id='section103'>
                        <Typography component="h2" fontSize="25px" fontWeight="800" mb={2}>Our Sharing Practices</Typography>
                        <Typography mb={2}>We may disclose or share information from or about you, including your Personal Information, as
                            follows:</Typography>
                        <Typography>   • With Media-Matchers’ affiliated companies (i.e., companies that control, are controlled by, or are
                            under common control with us) for support of our internal and business operations, for cross-
                            marketing purposes or in connection with a request made by you or your opt-in or consent.</Typography>
                        <Typography>    • With our non-affiliated third-party business partners, service providers, and vendors to enable them
                            to assist us in providing you the products or services you request through the Services or to provide
                            us with support for our internal and business operations, such as customer service, data processing,
                            data storage, research, analytics, web hosting, marketing, providing suggestions and strategies to
                            improve our business processes and the services we provide, and delivery of promotional or
                            transactional materials. We may provide your personal information to companies that  provide
                            services to help us with our business activities such as offering customer service. These companies
                            are authorized to use your Personal Information only as necessary to provide these services to us.
                            With other non-affiliated companies, parties, or organizations (with whom we maintain business
                            relationships) when you engage in certain activities through the Services or services that are
                            sponsored or provided by them, including, but not limited to requesting or purchasing products or
                            services offered by these third parties, electing to receive information or communications from them,
                            or electing to participate in contests, sweepstakes, games, scholarships, or other programs
                            sponsored or provided by these third parties, in whole or in part. For example, if you provide us with
                            Personal Information for the purposes of being matched with an educational institution, we share
                            your information with the educational institutions with whom you are matched so that they may
                            contact you regarding educational or related opportunities. When we disclose your information to
                            these third parties, your information will become subject to the information use and sharing practices
                            of these third parties, and these third parties will not be restricted by this Privacy Policy with respect
                            to their use and further sharing of your information.</Typography>
                        <Typography> • If  you submit information to an advertiser or other third party that is made available on or through
                            Media-Matchers, the information obtained during your visit to that advertiser’s website, and the
                            information you give to the advertiser will be governed by the advertiser’s privacy policy. For further
                            information on such advertiser’s use of your information, please visit the applicable privacy policy of
                            such advertisers. Advertisers made available on this site have separate policy practices for which
                            Media-Matchers has no responsibility or liability.</Typography>
                        <Typography> • In  addition, from time to time, we may share Personal Information (such as e-mail addresses and
                            other contact information such as name, email address and phone number) with selected third
                            parties, so they may offer educational or non-educational goods and services that we believe may
                            be of interest to our customers. To opt-out of our sharing of your information with such third parties,
                            see the Your Choices about How We Use and Disclose Your Information section.</Typography>
                        <Typography> • With any person who, in our reasonable judgment, is authorized to receive your information, such
                            as your agent, attorney, parent, legal guardian, or co-signor of a loan application.</Typography>
                        <Typography>• We may disclose information we collect from or about you when we believe disclosure is
                            appropriate to comply with the law; when we believe that disclosure is necessary to comply with a
                            judicial proceeding, court order or other similar legal process served on our Site; to enforce or apply
                            applicable terms and conditions and other agreements; or to protect the rights, property, or safety of
                            our company, our affiliates, our other customers or users of the Services, or others. If some or all of
                            our business assets are sold or transferred, whether through a sale, acquisition, merger,
                            consolidation, reorganization, bankruptcy, or other corporate change involving us and/or our
                            affiliates, we may transfer the corresponding information regarding our customers and users of the
                            Services, including Personal Information, to third parties evaluating or facilitating the transaction. We
                            also may retain a copy of such information. Nothing in this Privacy Policy is intended to interfere with
                            our ability to transfer all or part of our business, equity interests, or assets (including the Services) to
                            an affiliate or non-affiliated third party at any time, for any purpose, without any limitation, and
                            without notice or any compensation to you, whatsoever.</Typography>
                        <Typography> • We cannot ensure that all of your private communications and other Personal Information will never
                            be disclosed in ways not otherwise described in this Policy. For example, we may be forced to
                            disclose information to the government or third parties under certain circumstances, third parties
                            may unlawfully intercept or access transmissions or private communications, or users may abuse or
                            misuse your personal information that they unlawfully collect from the Site. We will try to protect your
                            privacy, however, we do not promise, and you should not expect, that your Personal Information or
                            private communications will always remain private.</Typography>
                    </section>


                    <Typography component="h2" fontSize="25px" fontWeight="800" mb={2}>User Contributions</Typography>
                    <Typography mb={2}>The Services may contain public areas, forums, message boards, chat rooms or features that allow
                        you to provide your User Contributions to be published or displayed or transmitted to other users of
                        the Services. Your User Contributions are posted on the Services and transmitted to others at your
                        own risk. For this reason, we recommend that you do not include any sensitive or personally
                        identifiable information about yourself or others in your User Contributions for these areas of the
                        Services. Please see our Terms of Use for more information regarding User Contributions.</Typography>


                    <Typography component="h2" fontSize="25px" fontWeight="800" mb={2}>Automatic Information Collection Technologies and
                        Usage Information</Typography>
                    <Typography mb={2}>We and our service providers automatically collect "UsageInformation" from or about you, such as
                        information about your computer or mobile device, IP address, web browser, computer or device
                        platform, related software and settings you are using, browsing actions and patterns, the referring
                        page that linked you to us and the website you visit after using theServices, and other usage activity
                        on the Services. Collection of UsageInformation helps us to improve the Services and to deliver a
                        better and more personalized service by enabling us to, among other things: (1) estimate our
                        audience size and usage patterns; (2) store information about your preferences, allowing us to
                        customize the Services according to your individual interests;(3) speed up your searches; and (4)
                        recognize you when you return to theServices.</Typography>

                    <Typography mb={2}>We and certain third parties, such as but not limited to advertising service providers, may use certain
                        technologies to collect information about your online activities over time and across different
                        services.</Typography>

                    <Typography mb={2}>The automatic collection technologies we and our service providers may use for this automatic
                        information collection include:</Typography>

                    <Typography>• Cookies (or browser cookies). A cookie is a small data file that is sent to your browser or related
                        software and stored on your computer's hard drive when you visit a website. Generally, cookies can
                        track what you look at and     where you travel on websites and what transactions you make. They
                        can also store Personal Information about you, including your username and password. A "session
                        cookie" expires immediately when you end your browsing session with the particular website. A
                        "persistent cookie" stores information on your hard drive so when you end your browsing session
                        and return to the same website later, the cookie information is still available. The Services may use
                        both types of cookies.</Typography>
                    <Typography>  • Flash Cookies. Certain features of the Services may use locally stored objects (or Flash cookies) to
                        collect and store information about your preferences and navigation to, from and on the Services.</Typography>
                    <Typography>   • Web Beacons. Pages of the Services and any emails we send to you may contain small electronic
                        files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that
                        permit us, for example, to count users who have visited those pages or opened our emails and for
                        other related website analytics (e.g., recording the popularity of certain website content and verifying
                        system and server integrity).</Typography>
                    <Typography>• Local Storage (LSs). We use LSs such as HTML5 to store content information and preferences.
                        Third parties with whom we partner to provide certain features on our site or to display advertising
                        based upon your Web browsing activity use LSs such as HTML5 and Flash to collect and store
                        information. Various browsers may offer their own management tools for removing HTML5 LSs.We
                        may receive reports based on the use of these technologies by these companies on an individual as
                        well as aggregated basis</Typography>

                    <Typography mb={2}>For more information about managing your privacy settings regarding automatic collection of Usage
                        Information, see the Your Choices aboutHow We Use and Disclose Your Information section.</Typography>

                    <Typography component="h2" fontSize="25px" fontWeight="800" mb={2}>Supplementation of Information</Typography>
                    <Typography mb={2}>If applicable to the Services you are using, in order to provide accurate school matching services to
                        you, we may on occasion supplement thePersonal Information you submitted to us with information
                        from third party sources.</Typography>


                    <Typography component="h2" fontSize="25px" fontWeight="800" mb={2}>Address and Phone Number Verification</Typography>
                    <Typography mb={2}>If applicable to the Services you are using, we work with service providers to verify your address and
                        phone number, in order to accurately match you to a course of study and school program, if
                        applicable.</Typography>

                    <section id='section104'>
                        <Typography component="h2" fontSize="25px" fontWeight="800" mb={2}>Customer Testimonials</Typography>
                        <Typography mb={2}>We may post customer testimonials Site, which may contain personally identifiable information like a
                            video along with their first and last name. We do obtain the customer's consent via email or via other
                            means prior to posting the testimonial to post their name and video along with their testimonial.</Typography>

                        <Typography component="h2" fontSize="25px" fontWeight="800" mb={2}>Third-Party Advertisers and Interest-Based Ads</Typography>
                        <Typography mb={2}>With the exception of certain of our webpages, services or features that may collect sensitive
                            Personal Information such as financial information, we may permit third-party advertising companies or ad networks to display third-party advertisements on our websites and/or automatically collect
                            UsageInformation from our websites for purposes of determining your preferences and delivering
                            interest-based advertising and content to you on the Services or other online properties, including
                            those operated by third parties. Those ads may include ads for our products or services or for other
                            companies' products or services.</Typography>

                        <Typography mb={2}>These third-party advertisers may combine the Usage Information with Personal Information you
                            directly submit to them or that they receive from other sources, or the Usage Information they collect
                            may include information that could allow someone to specifically contact you online without
                            necessarily knowing your identity. We do not have access to or control over the automatic collection
                            technologies that these third-party advertisers may use, and the information practices of these third-
                            party advertisers are subject to these parties' respective privacy policies, not this Privacy Policy.</Typography>

                        <Typography mb={2}>If you click on a link to a third party site, including on an advertisement, you will leave the Site you
                            are visiting and go to the site you selected. Because we cannot control the activities of third parties,
                            we cannot accept responsibility for any use of your personally identifiable information by such third
                            parties, and we cannot guarantee that they will adhere to the same privacy practices as this Site. We
                            encourage you to review the privacy statements of any other service provider from whom you
                            request services. If you visit a third party website that is linked to this Site, you should read that site’s
                            privacy statement before providing any personally identifiable information.</Typography>
                    </section>

                    <Typography component="h2" fontSize="25px" fontWeight="800" mb={2}>Third-Party Content</Typography>
                    <Typography mb={2}>The Services and all electronic communications sent in connection with the Services, may contain
                        links, videos, images, or other content provided by third parties. We do not necessarily endorse
                        these websites, products, services, or practices. We do not control, and are not responsible for, the
                        privacy and security practices of these third parties. We recommend that you review the privacy and
                        security policies of these third parties to determine how they handle information they may collect
                        from or about you once you are on the third party site.The Services may also allow you to engage
                        with social media features, such as the Facebook Like button, Google Plus, and Twitter widgets,
                        when you are logged into those services. These features may collect information about your IP
                        address and which page you are visiting on the Services, and they may set a cookie to make sure
                        the feature functions properly. Your interactions with these features and the information from or
                        about you collected by them are governed by the privacy policies of the companies that provide
                        them.</Typography>

                    <Typography component="h2" fontSize="25px" fontWeight="800" mb={2}>Your Choices About Use and Disclosure of Your
                        Information</Typography>
                    <Typography mb={2}>We strive to provide you with choices regarding the Personal Information you provide to us. Below
                        are some mechanisms that provide you with control over your information:</Typography>

                    <Typography mb={2}>• Promotional Emails or other Electronic Communications. If you do not wish to receive promotional
                        emails or other electronic communications from us, including but not limited to our e-newsletters,
                        contact us at Admin@Media-Matchers.com to opt-out or follow the unsubscribe process at the
                        bottom of the promotional     email or other electronic communication. Note that even if you opt-out,
                        you may still receive transactional or account-related emails from us (e.g., emails related to the
                        completion of your registration, correction of user data, password reset requests, reminder emails
                        that you have requested, and any other similar communications essential to your transactions on or
                        made through the Services).</Typography>

                    <Typography mb={2}>  • Telephone Calls and Texts. If you do not wish to receive promotional telephone calls from us,
                        contact us at Admin@Media-Matchers.com to opt-out,  and we will add your phone number to our
                        internal Do Not Call List. If you do not wish to receive texts, please follow the unsubscribe process in
                        the text by typing "STOP" and sending it to us.</Typography>

                    <Typography mb={2}> • Automatic Information Collection Technologies and Advertising. Our systems currently recognize
                        "do not track" signals from your browser. In addition, the "help" function of your browser should
                        contain instructions on how to set your browser to not accept new cookies, to notify you when a
                        cookie is issued, or how to disable cookies altogether. If Flash cookies are used on the Services,
                        you can manage your Flash cookie settings, by  visiting the Flash player settings page on Adobe's
                        website. If you disable or refuse cookies, please note that some parts of the Services may be
                        inaccessible or not function properly.</Typography>

                    <Typography mb={2}>  • Disclosure of Your Information to Third-Parties for Advertising. If you no longer want us to share
                        your Personal Information with non-affiliated third parties for promotional purposes, contact us at
                        Admin@Media-Matchers.com to opt-out. Any opt-out request will only apply from the date of your
                        request, and we will not be responsible for any communications that you may receive from third
                        parties who received your Personal Information prior to your request. In these cases, please contact
                        the third parties directly.
                    </Typography>

                    <section id='section105'>
                        <Typography component="h2" fontSize="25px" fontWeight="800" mb={2}>Accessing and Correcting Your Information</Typography>
                        <Typography mb={2}>The appropriate method(s) for accessing your information, if any, will depend on which of our
                            websites and services you have visited or used. Depending on the website and service, you may
                            have the ability to view or edit some of your information online, by logging into the Services and
                            visiting your account profile page. You may also contact us at Admin@Media-Matchers.com to
                            request access to, correct, or delete any Personal Information that you have provided to us;
                            however, we may ask you to verify your identity and provide other details to help us to respond to
                            your request. We will respond to your request within a reasonable timeframe.</Typography>

                        <Typography mb={2}>We cannot delete your Personal Information except by also deleting your user account, if applicable.
                            Once your user account is deleted, it cannot be recovered. We also may not accommodate a
                            request to change or delete information if we believe the change would violate any law or legal
                            requirements, not allow us to enforce our agreements, be contrary to our Terms of Use or any other
                            applicable agreement between you and us, or cause the information to be incorrect. After deletion of
                            your user account, your Personal Information may remain on our archived database and records.</Typography>

                        <Typography mb={2}>To request removal of your personal information from our blog or forum, contact us at
                            Admin@Media-Matchers.com. In some cases, we may not be able to remove your Personal
                            Information, in which case we will let you know if we are unable to do so and why. If you delete or
                            request the deletion of your User Contributions from the Services, copies of your User Contributions
                            may remain viewable in cached and archived pages, or might have been copied, stored, or reposted
                            to another website by other users of the Services.</Typography>

                        <Typography mb={2}>Any updates you make or request to be made to your Personal Information will be applied in our own
                            records but will not be updated in the records of the educational institutions or other third parties to
                            which we have already shared your information. If you would like to make updates to the information
                            that has been shared with educational institutions and other third parties, you will need to contact
                            them separately.</Typography>
                    </section>

                    <section id='section106'>
                        <Typography component="h2" fontSize="25px" fontWeight="800" mb={2}>Data Security</Typography>
                        <Typography mb={2}>We strive to use reasonable security measures to protect your information collected through the
                            Services. However, no method of transmission or electronic storage is 100% effective, and we
                            cannot guarantee absolute security. Therefore, your use of the Services is at your own risk and we
                            do not promise or guarantee, and you should not expect, that your information will always and
                            absolutely remain private and secure. We are not responsible for the circumvention of any privacy
                            settings or security measures contained on or concerning the Services. You are also responsible for
                            taking reasonable steps to protect your personal information against unauthorized disclosure or
                            misuse.</Typography>
                    </section>

                    <Typography mb={2}>In the unlikely event that we believe that the security of your Personal Information in our possession
                        or control may have been compromised, we may seek to notify you of that development. If a
                        notification is appropriate, we would endeavor to do so as promptly as possible under the
                        circumstances, and, if we have your email address, we may notify you by email. You consent to our
                        use of email as a means of such notification. If you prefer for us to use another method to notify you
                        in this situation, please email us at Admin@Media-Matchers.com with the alternative contact
                        information you wish to be used.</Typography>


                    <section id='section107'>
                        <Typography  component="h2" fontSize="35px" fontWeight="800" mb={2}>The Services Are Not for Children</Typography>


                        <Typography mb={2}>The Services are not intended for children under 13 years of age. We do not knowingly collect
                            personal information from children under 13. In the event that we learn that we have collected
                            Personal Information from a child younger than 13 years old, we will endeavor to delete that
                            information and terminate the child's account (if any). If you believe that a child under the age of 13
                            has provided us with personal information without verification of parental consent, please contact us
                            at Admin@Media-Matchers.com.</Typography>
                    </section>
                    <Typography component="h2" fontSize="25px" fontWeight="800" mb={2}>International Users</Typography>

                    <Typography mb={2}>The site was created by and is controlled, operated and administered by Media-Matchers LLC, a
                        California Limited Liability Company, or its agents from offices within the United States of America
                        utilizing servers located in the United States of America. We make no representation that materials
                        at this Site are appropriate or available for transmission to or from, or use in, locations outside of the
                        jurisdiction(s) stated above and accessing any Site from any jurisdiction where such Site’s contents
                        are illegal is prohibited. You may not use the Site or export the materials in violation of import or
                        export laws and regulations. If you access a Site from a location outside of the United States, you
                        are responsible for compliance with all local laws. If you are located outside of the United States,
                        please note that the personal data you provide to us will be transferred to the United States.
                        Whenever you submit any personal data to us, you consent to this transfer.</Typography>

                    <Typography component="h2" fontSize="25px" fontWeight="800" mb={2}>Changes to This Privacy Policy</Typography>
                    <Typography mb={2}>We may update or amend this Privacy Policy at any time. This Privacy Policy will reflect the date it
                        was last updated or amended. If we make any material amendments, we will notify you by posting a
                        notice of the updated or amended Privacy Policy on the Services. All amendments will take effect
                        immediately upon our posting of the updated Privacy Policy on the Services.Under certain
                        circumstances, we may also elect to notify you of changes or updates to our Privacy Policy by
                        additional means, such as posting a notice on the homepage of the Services or sending you an
                        email. Please check this Privacy Policy each time you use the Services to ensure you are aware of
                        any changes in our practices. Your continued use of the Services will indicate your acceptance of
                        the changes to the Privacy Policy.</Typography>

                    <section id='section108'>
                        <Typography component="h2" fontSize="25px" fontWeight="800" mb={2}>Contacting Us</Typography>
                        <Typography mb={2}>If you have questions or concerns about this Privacy Policy, our information practices, or wish to
                            make a request regarding your information, please contact us at any of the following:</Typography>

                        <Typography mb={2}>Compliance Department, c/o Media-Matchers, LLC, 1865 Chestnut Street, San Francisco, CA 94123
                            or Contact us by email at Admin@Media-Matchers.com.</Typography>
                    </section>
                    <Typography component="h2" fontSize="25px" fontWeight="800" mb={2}>Privacy Policy Scope</Typography>
                    <Typography mb={2}>This Privacy Policy applies to the collection of information in association with any of the Media-
                        Matchers, LLC websites and online services that link to this Privacy Policy. Some of our services or
                        offerings may be governed by alternative or additional privacy policies.</Typography>

                    <Typography mb={2}>This Privacy Policy does not apply to our products, services, and offerings for which a separate
                        privacy policy or privacy terms apply. Those products, services, and offerings may include contests,
                        contest entries, contest awards, scholarship applications, and scholarship awards. See the official
                        rules, terms and privacy policy (if applicable) that apply to the specific product or service being
                        offered</Typography>

                    <Typography mb={2}>This Privacy Policy does not apply to Other Media-Matchers LLC websites or online services (if any)
                        that do not link to this Privacy Policy; third-party websites or features to which this Site may link; and
                        information collected by any non-affiliated third party, even if related to this Site or our services or
                        products.</Typography>


                    <Typography component="h2" fontSize="25px" fontWeight="800" mb={2}>Special Notice to California Residents -- Your
                        California Privacy Rights</Typography>
                    <Typography mb={2}>California's Shine the Light law provides California residents with the right to receive disclosures
                        about any sharing of their personal information with other companies. If you are a California resident
                        and you provided your personal information to Media-Matchers LLC, then, once each calendar year,
                        you may submit a written request for information about the information we shared, if any, with other
                        companies for their use in direct marketing. To submit your written request, please send a written
                        request to.</Typography>

                    <Typography mb={2}>Media-Matchers, LLC
                        Compliance Department, c/o Media-Matchers, LLC
                        1865 Chestnut Street, San Francisco, CA 94123</Typography>

                    <Typography mb={2}>with "Request for California Privacy" in the letter subject line</Typography>
                    <Typography mb={2}>You must include sufficient detail for us to locate your file; at a minimum, your name and postal
                        address.Within 30 days of receiving your request, we will send you a list of the categories of
                        personal information disclosed to third parties for third-party direct marketing purposes during the
                        immediately preceding calendar year, along with the names and addresses of these third parties.
                        We reserve our right not to respond to requests sent more than once in a calendar year, or requests
                        submitted to an address other than the one posted in this notice. Please note that the California
                        Shine the Light law does not cover all information sharing. Our disclosure only includes information
                        covered by the law.</Typography>

                    <section id='section109'>
                        <Typography component="h2" fontSize="25px" fontWeight="800" mb={2}>PRIVACY POLICY – CALIFORNIA</Typography>
                        <Typography mb={2}>This PRIVACY POLICY FOR CALIFORNIA RESIDENTS supplements the information contained in
                            the Privacy Policy of Media-Matchers LLC (collectively, "we," "us," or "our") and applies solely to
                            visitors, users, and others who reside in the State of California ("consumers" or "you"). Any terms
                            defined in the California Consumer Privacy Act ("CCPA") have the same meaning when used in this
                            notice.</Typography>
                    </section>

                    <Typography component="h2" fontSize="25px" fontWeight="800" mb={2}>Information We Collect</Typography>
                    <Typography mb={2}>We have collected the following categories of personal information from consumers within the last
                        twelve (12) months:</Typography>

                    <table>
                        <tr>
                            <th></th>
                            <th>Category</th>
                            <th>Examples</th>
                            <th>Collected</th>
                        </tr>

                        <tr>
                            <td>A.</td>
                            <td>Identifiers</td>
                            <td>Real name, postal address, unique personal identifier,  online identifier, Internet Protocol address,
                                email address, or other similar  identifiers.</td>
                            <td>YES</td>
                        </tr>

                        <tr>
                            <td>B.</td>
                            <td>Personal information categories listed in the  California Customer Records statute (Cal. Civ. Code
                                § 1798.80(e)).</td>
                            <td>Name, address, telephone number, or education. Some  personal information included in this
                                category may overlap with other  categories.</td>
                            <td>YES</td>
                        </tr>

                        <tr>
                            <td>C.</td>
                            <td>Protected classification characteristics under  California or federal law.</td>
                            <td>Age, citizenship, religion or creed, marital status, or  veteran or military status.</td>
                            <td>YES</td>
                        </tr>

                        <tr>
                            <td>D.</td>
                            <td>Commercial information.</td>
                            <td>Records of personal property, products or services  purchased, obtained, or considered, or other
                                purchasing or consuming  histories or tendencies.</td>
                            <td>YES</td>
                        </tr>

                        <tr>
                            <td>E.</td>
                            <td>Biometric information</td>
                            <td>Genetic, physiological, behavioral, and biological  characteristics, or activity patterns used to extract
                                a template or other  identifier or identifying information, such as, fingerprints, face prints, and
                                voiceprints, iris or retina scans, keystroke, gait, or other physical  patterns, and sleep, health, or
                                exercise data.</td>
                            <td>NO</td>
                        </tr>

                        <tr>
                            <td>F.</td>
                            <td>Internet or other similar network activity</td>
                            <td>Browsing history, search history, information on a  consumer's interaction with a website, application,
                                or advertisement.</td>
                            <td>YES</td>
                        </tr>

                        <tr>
                            <td>G.</td>
                            <td>Geolocation data</td>
                            <td>Physical location or movements.</td>
                            <td>NO</td>
                        </tr>

                        <tr>
                            <td>H.</td>
                            <td>Sensory data</td>
                            <td>Audio, electronic, visual, thermal, olfactory, or similar  information.</td>
                            <td>NO</td>
                        </tr>

                        <tr>
                            <td>I.</td>
                            <td>Professional or employment-related information.</td>
                            <td> Current or past job history or performance evaluations</td>
                            <td>NO</td>
                        </tr>

                        <tr>
                            <td>J.</td>
                            <td>Non-public education information (per the Family  Educational Rights and Privacy Act (20 U.S.C.
                                Section 1232g, 34 C.F.R. Part  99))</td>
                            <td>Education records directly related to a student maintained  by an educational institution or party
                                acting on its behalf, such as grades,  transcripts, class lists, student schedules, student identification
                                codes,  student financial information, or student disciplinary records.</td>
                            <td>YES</td>
                        </tr>

                        <tr>
                            <td>K.</td>
                            <td> Inferences drawn from other personal information</td>
                            <td>Profile reflecting a person's preferences, characteristics,  psychological trends, predispositions,
                                behavior, attitudes, intelligence,  abilities, and aptitudes.</td>
                            <td>NO</td>
                        </tr>
                    </table>

                    <Typography mb={2} mt={2}>We obtain the categories of personal information listed above from the following categories of
                        sources:</Typography>

                    <Typography mb={2}>
                        <ul>
                            <li>Directly from consumers. For example, from information that consumers provide to us related to the
                                services we provide.</li>
                            <li> Directly and indirectly from schools, our marketing partners, affiliates, and service providers. For
                                example, through information we collect from our partners in the course of providing or receiving
                                services.</li>
                            <li> Directly and indirectly from activity on our website(s). For example, from submissions through our
                                website portal or website usage details collected automatically.</li>
                        </ul>
                    </Typography>

                    <Typography component="h2" fontSize="25px" fontWeight="800" mb={2}>Use of Personal Information</Typography>
                    <Typography mb={2}>We use or disclose the personal information we collect for one or more of the following business
                        purposes:</Typography>
                    <Typography>
                        <ul>
                            <li>To fulfill the purpose for which the information is provided. For example, if you share your personal
                                information to request info on any schools or programs, we will use that personal information to
                                respond to your inquiry</li>
                            <li>To provide you with information, products or services that you request from us.</li>
                            <li>To provide you with emails, event registrations, and other notices concerning our products or
                                services, or events or news, that may be of interest to you. To improve our website and present its
                                contents to you.</li>
                            <li>For testing, research, analysis and product development. As necessary or appropriate to protect
                                the rights, property or safety of us, our clients or others, or to enforce our Terms of Use.</li>
                            <li>To respond to law enforcement requests and as required by applicable law, court order, or
                                governmental regulations.</li>
                        </ul>
                    </Typography>

                    <Typography mb={2} mt={2}>We will not collect additional categories of personal information or use the personal information we
                        collected for additional purposes without providing you notice or obtaining your consent where
                        required by law.</Typography>

                    <Typography component="h2" fontSize="25px" fontWeight="800" mb={2}>Sharing Personal Information</Typography>
                    <Typography mb={2}>We may sell your personal information to a third party or disclose your personal information for a
                        business purpose. When we disclose personal information for a business purpose, we enter into a
                        contract that describes the purpose and requires the recipient to both keep that personal information
                        confidential and not use it for any purpose except performing the contract</Typography>

                    <Typography mb={2}>In the preceding twelve (12) months, we have disclosed the following categories of personal
                        information for a business purpose:</Typography>

                    <table>
                        <tr>
                            <td>Category A:</td>
                            <td>Identifiers</td>
                        </tr>
                        <tr>
                            <td>Category B:</td>
                            <td>California Customer Records personal information categories.</td>
                        </tr>
                        <tr>
                            <td>Category C:</td>
                            <td>Protected classification characteristics under California or federal law.</td>
                        </tr>
                        <tr>
                            <td>Category D:</td>
                            <td>Commercial Information.</td>
                        </tr>
                        <tr>
                            <td>Category F:</td>
                            <td>Internet or other similar network activity.</td>
                        </tr>
                        <tr>
                            <td>Category J:</td>
                            <td>Non-public education information (per the Family Educational Rights and Privacy Act (20
                                U.S.C. Section 1232g, 34 C.F.R. Part 99)).</td>
                        </tr>
                    </table>

                    <Typography mb={2} mt={2}>Business purposes may include, but are not limited to: (1)auditing such as advertising analytics or
                        auditing legal and regulatory compliance; (2) security such as detecting security breaches, protecting
                        against fraud and malicious activity, or taking action against wrongdoers (e.g. fraudsters and
                        hackers); (3) debugging such as identifying and fixing technical errors; (4) short-term uses including
                        contextual ad customization that does not involve or contribute to profiling; (5) performing services
                        such as account maintenance, customer service, or marketing; (6) internal research to develop or
                        demonstrate technology; or (7) testing or improvement of any service or device "owned,
                        manufactured, manufactured for, or controlled by" the business.</Typography>

                    <Typography mb={2}>In the preceding twelve (12) months, we have sold the following categories of personal information:</Typography>
                    <table>
                        <tr>
                            <td>Category A:</td>
                            <td> Identifiers.</td>
                        </tr>
                        <tr>
                            <td>Category B:</td>
                            <td>California Customer Records personal information categories.</td>
                        </tr>
                        <tr>
                            <td>Category C:</td>
                            <td>Protected classification characteristics under California or federal law.</td>
                        </tr>
                    </table>

                    <Typography component="h2" fontSize="25px" fontWeight="800" mb={2} mt={2}>Your Rights and Choices</Typography>
                    <Typography mb={2}>The CCPA provides California residents with specific rights regarding their personal information.
                        This section describes your CCPA rights and explains how to exercise those rights.</Typography>

                    <Typography mb={2}>Access to Specific Information and Data Portability Rights
                        You have the right to request that we disclose certain information to you about our collection and use
                        of your personal information.We will disclose the following information to you upon receiving your
                        verifiable consumer request</Typography>

                    <ul>
                        <li>The categories of personal information we collected about you.</li>
                        <li>The categories of sources for the personal information we collected about you.</li>
                        <li>Our business or commercial purpose for collecting that personal information. The categories of
                            third parties with whom we share that personal information.</li>
                        <li>The specific pieces of personal information we collected about you</li>
                        <li>Our business or commercial purpose for selling or disclosing that personal information, if
                            applicable</li>
                        <li>If we sold or disclosed your personal information for a business purpose, two separate lists
                            disclosing: (1) Sales – Identifying the personal information categories that each category of recipient
                            purchased; and  (2) Disclosures for a business purpose – Identifying the personal information
                            categories that each category of recipient obtained.</li>
                    </ul>

                    <Typography mb={2}>Deletion Request Rights</Typography>
                    <Typography>You have the right to request that we delete personal information that we collected from you, subject
                        to certain exceptions. We will delete (and direct our service providers to delete) your personal
                        information from our records upon receiving your verifiable consumer request, unless an exception
                        applies</Typography>

                    <Typography mb={2}>Exercising Access, Data Portability, and Deletion Rights</Typography>
                    <Typography mb={2}>To exercise the access, data portability, and deletion rights described above, please submit a
                        request to us by either:</Typography>

                    <Typography>Data Deletion Requests:<Link>click here</Link></Typography>
                    <Typography mb={2}>Data Access Requests:<Link>click here</Link></Typography>

                    <Typography mb={2}>Only you or a person registered with the California Secretary of State that you authorize to act on
                        your behalf, may make a request related to your personal information. You may also make a request
                        on behalf of your minor child.</Typography>

                    <Typography mb={2}>We are not required to provide personal information to you more than twice within a 12-month
                        period.We cannot respond to your request or provide you with personal information if we cannot
                        reasonably verify your identity or authority to make the request or confirm the personal information
                        relates to you. We will not require you to create an account with us in order to make a request. We
                        will only use personal information provided in a request to verify your identity or authority to make
                        the request.</Typography>

                    <Typography mb={2}>Response Timing and Format</Typography>

                    <Typography >We endeavor to respond to a verifiable consumer request within 45 days of its receipt. If we require
                        more time, we will inform you of the reason and extension period in writing. If you have an account
                        with us, we will deliver our written response to that account. If you do not have an account with us,
                        we will deliver our written response by mail or electronically. Any disclosures we provide will cover
                        the 12-month period preceding our receipt of the verifiable consumer request. The response we
                        provide will also explain the reasons we cannot comply with a request, if applicable. For data
                        portability requests, we will select a format to provide your personal information that is readily
                        useable and should allow you to transmit the information from one entity to another entity without
                        hindrance.</Typography>


                    <Typography mb={2}>Do Not Sell My Personal Information</Typography>
                    <Typography mb={2}>You have the right to opt-out of the sale of your personal information; however, exercising this right
                        does not affect any of your personal information that was sold prior to the filing of your opt-out
                        request.To exercise your opt-out right, please submit a request at<Link>click here</Link></Typography>

                    <Typography component="h2" fontSize="25px" fontWeight="800" mb={2}>Changes to Our Privacy Notice</Typography>
                    <Typography mb={2}>We reserve the right to amend this privacy notice at our discretion and at any time. When we make
                        changes to this privacy notice, we will notify you through a notice on our website or obtain consent
                        where required by law.</Typography>

                    <Typography component="h2" fontSize="25px" fontWeight="800" mb={2}>Contact Information</Typography>
                    <Typography mb={2}>If you have any questions or comments about this Privacy Policy For California Residents, the ways
                        in which we collect and use your personal information, your choices and rights regarding such use,
                        or wish to exercise your rights under California law, please do not hesitate to contact us at:</Typography>

                    <List>
                        <ListItem>Website:Media-Matchers.com</ListItem>
                        <ListItem>Email: Admin@Media-Matchers.com</ListItem>
                        <ListItem>Postal Address: Media-Matchers, LLC</ListItem>
                        <ListItem>Attn: Compliance Department</ListItem>
                        <ListItem>1865 Chestnut Street, San Francisco, CA 94123</ListItem>
                    </List>

                    <section id='section110'>
                        <Typography component="h2" fontSize="25px" fontWeight="800" mb={2}>PRIVACY POLICY – NEVADA</Typography>
                        <Typography mb={2}>Residents of the State of Nevada have the right to opt out of the sale of certain pieces of their
                            information to third parties who will sell or license their information to others. If you are a Nevada
                            resident and would like to make such a request, please email Admin@Media-Matchers.com or
                            submit a request <Link>here.</Link>
                        </Typography>
                        <Typography mb={2}>Last Updated on January 1, 2020. This Privacy Policy is effective immediately for all users. To
                            review the previous policy, please <Link>click here.</Link></Typography>
                    </section>
                </Container>
            </Box>

        </div>
    )
}
