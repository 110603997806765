import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { useState } from 'react';
interface IDegreeInterestMenu {
  type: string;
  value: string;
}
interface IAreaStudyMenu {
  category_id: string;
  category_name: string;
}
interface IMProgramMenu {
  value: string;
  text: string;
}

interface IMenuDropDownProps {
  degreeInterestDDMenu: IDegreeInterestMenu[];
  degreeInterestValue: string;
  degreeInterestHandler: (event: SelectChangeEvent) => void;
  areaStudyDDMenu: IAreaStudyMenu[];
  areaStudyDDValue: string;
  areaStudyDDHandler: (event: SelectChangeEvent) => void;
  mProgramDDMenu: IMProgramMenu[];
  mProgramDDValue: string;
  mProgramDDHandler: (event: SelectChangeEvent) => void;
  handleSubmit: (event: any) => void;
}

const MenuDropDownComponent: React.FC<IMenuDropDownProps> = ({
  degreeInterestDDMenu,
  degreeInterestValue,
  degreeInterestHandler,
  areaStudyDDMenu,
  areaStudyDDValue,
  areaStudyDDHandler,
  mProgramDDMenu,
  mProgramDDValue,
  mProgramDDHandler,
  handleSubmit,
}) => {
  const [isValid, setIsValid] = useState({
    degreeInterest: false,
    areaStudy: false,
    mProgram: false,
  });

  const handleError = (e: any) => {
    const conditions = [
      { value: degreeInterestValue, stateKey: 'degreeInterest' },
      { value: areaStudyDDValue, stateKey: 'areaStudy' },
      { value: mProgramDDValue, stateKey: 'mProgram' }
    ];
  
    const hasError = conditions.some(condition => {
      if (!condition.value) {
        setIsValid(prevState => ({
          ...prevState,
          [condition.stateKey]: true
        }));
        return true;
      }
      return false;
    });
  
    if (!hasError) {
      handleSubmit(e);
    }
  };

  return (
    <Card sx={{ mb: '30px' }} className='mobile-widget'>
      <CardContent sx={{ p: '16px !important' }}>
        <Box mb={2}>
          <FormControl
            variant="filled"
            fullWidth
            className={`custom-select ${
              isValid.degreeInterest ? 'custom-error' : ''
            }`}
          >
            <InputLabel>
              {degreeInterestValue ? 'I want my' : 'Select Degree Level'}
            </InputLabel>
            <Select
              id="degreeInterest"
              value={degreeInterestValue}
              onChange={e => {
                setIsValid(prevState => ({
                  ...prevState,
                  degreeInterest: false,
                }));
                degreeInterestHandler(e);
              }}
            >
              {degreeInterestDDMenu.map(dDI => {
                return (
                  <MenuItem value={dDI.value} key={dDI.value}>
                    {dDI.type}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
        {degreeInterestValue && (
          <Box mb={2}>
            <FormControl
              variant="filled"
              fullWidth
              className={`custom-select ${
                isValid.areaStudy ? 'custom-error' : ''
              }`}
            >
              <InputLabel>
                {areaStudyDDValue ? 'IN' : 'Select Area of Study'}
              </InputLabel>
              <Select
                id="areaStudy"
                value={areaStudyDDValue}
                onChange={e => {
                  setIsValid(prevState => ({
                    ...prevState,
                    areaStudy: false,
                  }));
                  areaStudyDDHandler(e);
                }}
              >
                {areaStudyDDMenu.map(aAI => {
                  return (
                    <MenuItem value={aAI.category_id} key={aAI.category_id}>
                      {aAI.category_name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        )}
        {(degreeInterestValue && areaStudyDDValue) && (
          <Box mb={2}>
            <FormControl
              variant="filled"
              fullWidth
              className={`custom-select ${
                isValid.mProgram ? 'custom-error' : ''
              }`}
            >
              <InputLabel>
                {mProgramDDValue ? 'FOCUSING ON' : 'Select Program'}
              </InputLabel>
              <Select
                id="mProgram"
                value={mProgramDDValue}
                onChange={e => {
                  setIsValid(prevState => ({
                    ...prevState,
                    mProgram: false,
                  }));
                  mProgramDDHandler(e);
                }}
              >
                {mProgramDDMenu
                  ? mProgramDDMenu.map(mPI => {
                      return (
                        <MenuItem value={mPI.value} key={mPI.value}>
                          {mPI.text}
                        </MenuItem>
                      );
                    })
                  : null}
              </Select>
            </FormControl>
          </Box>
        )}
        <Button
          variant="contained"
          fullWidth
          size="large"
          sx={{
            textTransform: 'none',
            fontSize: '18px',
            borderRadius: '8px',
            minWidth: '150px',
          }}
          onClick={handleError}
        >
          Compare School Options
        </Button>
      </CardContent>
    </Card>
  );
};

export default MenuDropDownComponent;
