import ParentMain from "../Container";
import AreaComponents from "../components/AreaOfStudies/AreaComponents";
import BlogDetailComponent from "../components/AreaOfStudies/BlogDetailComponent";
import PrivacyPolicy from "../components/AreaOfStudies/PrivacyPolicy";
import TermsOfUse from "../components/AreaOfStudies/TermsOfUse";
import FormIndex from "../components/LeadFormSteps/FormIndex";

export const areaRoutes = [
    {
        name: "Home",
        path: "/",
        exact: true,
        component: AreaComponents
    },
    {
        name: "Business",
        path: '/business',
        exact: true,
        component: AreaComponents
    },
    {
        name: "Computers & IT",
        path: "/computers&it",
        exact: true,
        component: AreaComponents
    },
    {
        name: "Criminal Justice",
        path: "/criminaljustice",
        exact: true,
        component: AreaComponents
    },
    {
        name: "Education & Teaching",
        path: "/education&teaching",
        exact: true,
        component: AreaComponents
    }, {
        name: "Healthcare",
        path: "/healthcare",
        exact: true,
        component: AreaComponents
    }, {
        name: "Psychology",
        path: "/psychology",
        exact: true,
        component: AreaComponents
    }, {
        name: "Nursing",
        path: "/nursing",
        exact: true,
        component: AreaComponents
    }, {
        name: "Vocational Training",
        path: "/vocationaltraining",
        exact: true,
        component: AreaComponents
    }, {
        name: "Liberal Arts",
        path: "/liberalarts",
        exact: true,
        component: AreaComponents
    }, {
        name: "General",
        path: "/undecided",
        exact: true,
        component: AreaComponents
    }, {
        name: "Blog",
        path: "/college-review",
        exact: true,
        component: BlogDetailComponent
    }, {
        name: "Terms Of Use",
        path: "/terms-of-use",
        exact: true,
        component: TermsOfUse
    }, {
        name: "PrivacyPolicy",
        path: "/privacy-policy",
        exact: true,
        component: PrivacyPolicy
    }, {
        name: "LeadForm",
        path: "/get-information",
        exact: true,
        component: FormIndex
    },

]

export const areaRoutes1 = [
    {
        name: "Home",
        path: "/",
        exact: true,
        component: ParentMain
    },
    {
        name: "Business",
        path: "/business",
        exact: true,
        component: ParentMain
    },
    {
        name: "Business",
        path: "/business/:id",
        exact: true,
        component: ParentMain
    },
    {
        name: "Computers & IT",
        path: "/computers&it",
        exact: true,
        component: ParentMain
    },
    {
        name: "Computers & IT",
        path: "/computers&it/:id1",
        exact: true,
        component: ParentMain
    },
    {
        name: "Criminal Justice",
        path: "/criminaljustice",
        exact: true,
        component: ParentMain
    },
    {
        name: "Criminal Justice",
        path: "/criminaljustice/:id2",
        exact: true,
        component: ParentMain
    },
    {
        name: "Education & Teaching",
        path: "/education&teaching",
        exact: true,
        component: ParentMain
    },
    {
        name: "Education & Teaching",
        path: "/education&teaching/:id3",
        exact: true,
        component: ParentMain
    },
    {
        name: "Healthcare",
        path: "/healthcare",
        exact: true,
        component: ParentMain
    },
    {
        name: "Healthcare",
        path: "/healthcare/:id4",
        exact: true,
        component: ParentMain
    },
    {
        name: "Psychology",
        path: "/psychology",
        exact: true,
        component: ParentMain
    },
    {
        name: "Psychology",
        path: "/psychology/:id5",
        exact: true,
        component: ParentMain
    },
    {
        name: "Nursing",
        path: "/nursing",
        exact: true,
        component: ParentMain
    },
    {
        name: "Nursing",
        path: "/nursing/:id6",
        exact: true,
        component: ParentMain
    },
    {
        name: "Vocational Training",
        path: "/vocationaltraining",
        exact: true,
        component: ParentMain
    },
    {
        name: "Vocational Training",
        path: "/vocationaltraining/:id7",
        exact: true,
        component: ParentMain
    },
    {
        name: "Liberal Arts",
        path: "/liberalarts",
        exact: true,
        component: ParentMain
    },
    {
        name: "Liberal Arts",
        path: "/liberalarts/:id8",
        exact: true,
        component: ParentMain
    },
    {
        name: "General",
        path: "/undecided",
        exact: true,
        component: ParentMain
    },
    {
        name: "General",
        path: "/undecided/:id9",
        exact: true,
        component: ParentMain
    },
    {
        name: "Blog",
        path: "/college-review",
        exact: true,
        component: BlogDetailComponent
    },
    {
        name: "Terms Of Use",
        path: "/terms-of-use",
        exact: true,
        component: TermsOfUse
    },
    {
        name: "PrivacyPolicy",
        path: "/privacy-policy",
        exact: true,
        component: PrivacyPolicy
    },
    {
        name: "LeadForm",
        path: "/get-information",
        exact: true,
        component: FormIndex
    },

]