import React, { useEffect, useState } from 'react'
import { Box, Typography, Card, CardContent, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { cardProperty } from '../../constants/styles';
import {LeadFormUserGenderAffiliationTitle, LeadFormUserGenderConfirmation } from '../../constants/leadForms';
import ManIcon from '@mui/icons-material/Man';
import WomanIcon from '@mui/icons-material/Woman';

const LeadFormUserGender = ({ onNext, leadFormData, setLeadFormData }: any) => {
  const [isYesClicked, setIsYesClicked] = useState<Boolean>(false);
  const [isMilitaryChange, setIsMilitaryChange] = useState('')
  const [selectedCard, setSelectedCard] = useState(null);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const handleCardClick = (confirmName: any) => {
    setLeadFormData({ ...leadFormData, userInformation: {...leadFormData.userInformation, userGender:confirmName} });
    setSelectedCard(confirmName)
    onNext();
  };

  return (
    <Box>
      <Box textAlign='center'>
        <Typography component="h5" fontSize="30px" maxWidth="600px" lineHeight="1.3" mx="auto" fontWeight="800" mb={2}>{LeadFormUserGenderAffiliationTitle}</Typography>
      </Box>
      <Box display='flex' flexWrap='wrap' justifyContent='center' gap="30px" maxWidth="510px" mx="auto">
        {LeadFormUserGenderConfirmation.map((confirm, index) =>
          <Card sx={cardProperty} key={index} onClick={() => handleCardClick(confirm.value)}
            className={`frm-card ${selectedCard === confirm.name ? 'selected' : ''}`}
          >
          {confirm.value==='M'?  (<ManIcon   sx={{ fontSize: 40 }} />) :(<WomanIcon sx={{ fontSize: 40 }}/>)}
            <CardContent sx={{ p: "0 !important" }}>
              <Typography fontWeight="600" lineHeight="1.3" fontSize="14px" px={2} sx={{ height: "48px" }}> {confirm.name} </Typography></CardContent>
          </Card>
        )}
      </Box>

    </Box >
  )
}

export default LeadFormUserGender