import {
  Card,
  CardContent,
  Typography,
  Box,
  CardMedia,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import CheckboxField from '../../helper/CheckboxField';
import { useState, useEffect,useContext } from 'react';
import Loader from '../Loader';
import DynamicForm from '../DynamicForm';
import { LeadContext } from '../../contextProvider/leadContextProvider';

function AdditionalLead({ onNext, leadFormData }: any) {
  const { availableOffers } = useContext(LeadContext);

  return (
    <Box marginTop="150px">
      { availableOffers?.length>0 ? (
        <Box
          display="flex-column"
          flexDirection="column"
          width="85%"
          mx="auto" 
          alignItems="center" 
          justifyContent="center" 
        >
          {Object.entries(availableOffers).map(([key, item]: [string, any]) =>
            item ? (
              <DynamicForm formItem={item} leadFormData={leadFormData} />
            ) : (
              'No Result Found'
            )
          )}
        </Box>
      ) : (
        'No Result Found'
      )}
      {availableOffers?.length>0 && (
        <Box textAlign="center" mt={2}>
          <Button
            variant="contained"
            color="success"
            size="large"
            sx={{
              textTransform: 'none',
              fontSize: '18px',
              // borderRadius: '8px',
              minWidth: '150px',
            }}
            onClick={onNext}
          >
            See Additional Matches
          </Button>
        </Box>
      )}
    </Box>
  );
}

export default AdditionalLead;
