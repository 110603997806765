import React, { useEffect, useState } from 'react'
import { Box, Card, Chip, Stack, Typography, CardContent } from '@mui/material'
import { TStudyProgram } from '../../types/common';
import { ProgramResponse } from '../../services';
import { AREA_PROGRAM_STUDIES } from '../../constants/url/compareUrl';

const LeadFormMProgram = ({ onNext, leadFormData, setLeadFormData }: any) => {
  const [programs, setPrograms] = useState<TStudyProgram[]>([])

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const fetchedMProgram = async () => {
    const programResponse = await ProgramResponse(`${AREA_PROGRAM_STUDIES}${leadFormData.areaStudy.category_name}`);
    setPrograms(programResponse.value.programs)
  };
  useEffect(() => {
    fetchedMProgram()
  }, [])

  const handleSubmit = (programs: string) => {
    setLeadFormData({ ...leadFormData, mProgram: programs })
    onNext();
  }

  return (
    <Box>
      <Stack display="inline-flex" mb={3}>
        <Typography component="h5" fontSize="30px" maxWidth="600px" lineHeight="1.3" mx="auto" fontWeight="800" mb={2}>{`Great! See Below for Available Online ${leadFormData.areaStudy.category_name} Options`} :
        </Typography>
        <Typography component="h6" fontSize="20px" maxWidth="600px" lineHeight="1.3" mx="auto" fontWeight="800" mb={2}>{`Great! Now Select a Program for: `}
          <Chip label={leadFormData.areaStudy.category_name} variant="outlined" sx={{ background: 'white', fontWeight: "600", fontSize: "14px", p: "10px", ml: "10px", width: "180px", height: "40px" }} />
        </Typography>
      </Stack>
      <Box display="flex" flexWrap="wrap" gap="16px">
        {programs.map(programName =>
          <Card className="radio-btn frm-card" onClick={() => handleSubmit(programName as string)}>
            <CardContent sx={{ textAlign: "center" }}>
              <Typography fontWeight="600" lineHeight="1.2" fontSize="13px" px={2} sx={{ height: "10px" }}>
                {programName.program_name}
              </Typography>
            </CardContent>
          </Card>
        )}
      </Box>
    </Box>
  )
}

export default LeadFormMProgram