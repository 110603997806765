import { FormControlLabel, Checkbox, Typography } from '@mui/material'
import { TCheckboxProps } from '../types/common';

const CheckboxField = ({ label, ...props }: TCheckboxProps) => {
    return (
        <FormControlLabel className='lead-checkbox' control={<Checkbox {...props} />}
            label={<Typography sx={{ fontSize: "14px" }}>{label}</Typography>}
        />
    )
}

export default CheckboxField