import { Box, Typography, Card, CardContent } from '@mui/material';
import { cardProperty } from '../../constants/styles';
import { useEffect, useState } from 'react';
import { TAreaStudy } from '../../types/common';
import { getResponse } from '../../services';
import { AREA_STUDIES } from '../../constants/url/compareUrl';
import { LeadFormAreaIcons, LeadFormAreaStudyTitle } from '../../constants/leadForms';

const LeadFormAreaStudy = ({ onNext, leadFormData, setLeadFormData }: any) => {

  const [categories, setCategories] = useState<TAreaStudy[]>([])

  useEffect(() => {
    leadAreaStudyResponse()
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const leadAreaStudyResponse = async () => {
    const areaResponse: { value: { category: TAreaStudy[] } } = await getResponse(AREA_STUDIES);
    setCategories(areaResponse.value.category);
  }

  const handleSubmit = (categories: string) => {
    setLeadFormData({ ...leadFormData, areaStudy: categories })
    onNext()
  }
  return (
    <Box>
      <Box textAlign="center">
        <Typography component="h5" fontSize="30px" maxWidth="600px" lineHeight="1.3" mx="auto" fontWeight="800" mb={2}>{LeadFormAreaStudyTitle}</Typography>
      </Box>
      <Box display="flex" justifyContent="center" flexWrap="wrap" gap="30px" maxWidth="510px" mx="auto">
        {categories.map((card, index) => {
          const cardIcon = LeadFormAreaIcons.find(data => data.name === card.category_name)
          return (
            <Card onClick={() => handleSubmit(card as string)} sx={cardProperty} key={index} className='frm-card'>
              <img src={cardIcon?.src} alt={card.category_name} />
              <CardContent sx={{ p: "0 !important", textAlign: "center" }}>
                <Typography fontWeight="600" lineHeight="1.3" fontSize="14px" px={2} sx={{ height: "48px" }}>
                  {card.category_name}
                </Typography>
              </CardContent>
            </Card>
          )
        }
        )}
      </Box>
    </Box>
  )
}

export default LeadFormAreaStudy
