export const cardProperty = {
    width: "150px",
    height: "170px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: "16px",
    pt: "16px"
}

export const resultLeadLogo = {
    backgroundColor: '#fff',
    border: "1px solid #dfdfdf",
    borderRadius: "8px",
    padding: "16px 24px"
}