import React from 'react';
import { Box, Grid, Typography } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import AdsClickIcon from '@mui/icons-material/AdsClick';

const loaderData = [{ title: 'Browse', subTitle: 'Programs', Icon: SearchIcon }, { title: 'Find', subTitle: 'School matches', Icon: FindInPageIcon }, { title: 'Request information', subTitle: 'from schools', Icon: AdsClickIcon }]

export default function Loader() {
  return (
    <React.Fragment>
      <Box textAlign="center">
        <Typography component="h5" fontSize="25px" fontWeight="800">Thank You!</Typography>
        <Typography component="h5" fontSize="20px" maxWidth="600px" lineHeight="1.3" mx="auto" fontWeight="800" mb={2}>Your personalized result are just seconds away.</Typography>
      </Box>
      <Box className="dots-container" my={5}>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </Box>

      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        {loaderData.map((loader: any) => (
          <Grid item xs={12} sm={12} md={4}>
            <Box className="lead-srch">
              <loader.Icon sx={{ color: "#cccccc" }} className='icon' />
            </Box>
            <Box m={2} textAlign="center">
              <Typography component="h5" fontSize="20px" maxWidth="600px" lineHeight="1.3" mx="auto" fontWeight="800" mb={1} whiteSpace="nowrap">{loader.title}</Typography>
              <Typography component="h6" mb="30px" whiteSpace="nowrap">{loader.subTitle}</Typography>
            </Box>
          </Grid>
        ))}
      </Grid>

    </React.Fragment>
  );
}