export const STAGE_ENV_BASE_URL = 'https://d2yzhy3sn9m7nt.cloudfront.net/staging'
export const PROD_ENV_BASE_URL = 'https://d2thw90mo99gar.cloudfront.net/production'

export const stagingConfig = {
    baseURL: STAGE_ENV_BASE_URL,
    // listingURL: 'https://api-parallel.media-matchers.com/internal',
    listingURL: 'https://api.media-matchers.com/search',
}

export const ProdConfig = {
    baseURL: PROD_ENV_BASE_URL,
    // listingURL: 'https://api-parallel.media-matchers.com/internal', 
    listingURL: 'https://api.media-matchers.com/search',
}