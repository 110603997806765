// Area Studies banner URL
export const BUSINESS_STUDY = '/v1/content/headers/1'
export const CRIMINAL_JUSTICE_STUDY = '/v1/content/headers/2'
export const PSYCHOLOGY_STUDY = '/v1/content/headers/3'
export const TECHNOLOGY_STUDY = '/v1/content/headers/4'
export const HEALTHCARE_STUDY = '/v1/content/headers/5'
export const EDUCATION_STUDY = '/v1/content/headers/6'
export const VOCATIONAL_TRAINING_STUDY = '/v1/content/headers/7'
export const LIBERAL_ARTS_STUDY = '/v1/content/headers/8'
export const GENERAL_STUDY = '/v1/content/headers/9'
export const NURSING_STUDY = '/v1/content/headers/10'

// mProgram banner URL 
export const GENERAL_BUSINESS = '/v1/content/headers/100'
export const GENERAL_CRIMINAL_JUSTICES = '/v1/content/headers/200'
export const GENERAL_PSYCHOLOGY = '/v1/content/headers/300'
export const GENERAL_TECHNOLOGY = '/v1/content/headers/400'
export const GENERAL_HEALTHCARE = '/v1/content/headers/500'
export const GENERAL_EDUCATION = '/v1/content/headers/600'
export const GENERAL_VOCATIONAL_TRAINING = '/v1/content/headers/700'
export const GENERAL_LIBERAL_ARTS = '/v1/content/headers/800'
export const GENERAL_GENERAL = '/v1/content/headers/900'
export const GENERAL_NURSING = '/v1/content/headers/10000'

export const BODY_CONTENT = '/v1/content/sections'

// Body Content URL
export const BUSINESS_BODY_CONTENT = '/v1/content/sections/1'
export const CRIMINAL_JUSTICE_BODY_CONTENT = '/v1/content/sections/2'
export const PSYCHOLOGY_BODY_CONTENT = '/v1/content/sections/3'
export const TECHNOLOGY_BODY_CONTENT = '/v1/content/sections/4'
export const HEALTHCARE_BODY_CONTENT = '/v1/content/sections/5'
export const EDUCATION_BODY_CONTENT = '/v1/content/sections/6'
export const VOCATIONAL_TRAINING_BODY_CONTENT = '/v1/content/sections/7'
export const LIBERAL_ARTS_BODY_CONTENT = '/v1/content/sections/8'
export const GENERAL_BODY_CONTENT = '/v1/content/sections/9'
export const NURSING_BODY_CONTENT = '/v1/content/sections/10'


// Total click URL
export const TOTAL_CLICK_COUNT = '/v1/compare/count'

// Blog Detail URL
export const BLOG_DETAILS = '/v1/blog/details'

export const SEO_API = 'https://f0720w0one.execute-api.us-east-1.amazonaws.com/staging/v3/seo/handlerById'