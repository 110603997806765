import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Link
} from "@mui/material";
import React from "react";
import { s3PrimaryLogo } from "../constants/url/logoUrl";
import { gtagEvent } from "../services/google-analytics";
import { initCCConvertion } from "../clickCaese";

export default function TopChoiceCards(iItem: any) {
  const { headContent, mPosition, clickURL, bodyContent, mBrandID, mBrandName, requestData, Commission,areaStudy } = iItem
  const handleVisitSiteClick = () => {
    const conversionValue = Commission
    const transactionID = `${requestData}-${mPosition}`
    window.dataLayer.push({
      event: 'mm-cod-gtag',
      conversionValue: conversionValue,
      transactionID: transactionID
    });
    gtagEvent("click", {
      brand_name: mBrandName,
      brand_id: mBrandID,
      link_url: clickURL,
      time_stamp: (new Date()).getTime(),
    });
    initCCConvertion(conversionValue);
  }
  return (
    <React.Fragment>
      <Card className="listing-card top-picks-card">
        <Box className="info-1">
          <Box display="flex" alignItems="center" gap="24px">
            <Box className="count">#{mPosition}</Box>
            <CardMedia
              component="img"
              image={`${s3PrimaryLogo}${mBrandID}.webp`}
              alt={`area_study_${areaStudy}`}
              title={mBrandName}
            />
          </Box>
          <Button
            component={Link}
            href={clickURL}
            target="_blank"
            variant="contained"
            color="success"
            size="large"
            sx={{
              textTransform: "none",
              fontSize: "18px",
              borderRadius: "8px",
              minWidth: "150px",
            }}
            onClick={handleVisitSiteClick}
          >
            Visit Site
          </Button>
        </Box>
        <CardContent className="info-2">
          <Typography
            variant="subtitle1"
            color="text.secondary"
            component="p"
            lineHeight={1.3}
            fontWeight="600"
            className="subtitle"
          >{headContent}</Typography>
          <div dangerouslySetInnerHTML={{ __html: bodyContent }} />
        </CardContent>
      </Card>
    </React.Fragment>
  );
}
