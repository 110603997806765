import React, { useState, useEffect } from 'react';
import LeadFormAreaStudy from './LeadFormAreaStudy'
import LeadFormMProgram from './LeadFormMProgram'
import LeadFormDegreeInterest from './LeadFormDegreeInterest'
import LeadFormDuration from './LeadFormDuration'
import LeadFormMilitary from './LeadFormMilitary'
import LeadFormLocationInformation from './LeadFormLocationInformation'
import LeadFormUserInformation from './LeadFormUserInformation'
import { Box, Toolbar } from '@mui/material'
import LinearProgress from '@mui/material/LinearProgress';
import ListingCom from './ListingCom';
import { TLeadFormsValueTypeProps } from '../../types/common';
import LeadFormUserGender from './LeadFormUserGender';
import { LeadContextProvider } from '../../contextProvider/leadContextProvider';
import ThankYou from './ThankYou';

const toolbarStyle = {
  minHeight: "1px",
};

declare global {
  interface Window {
    myCallbackFunction: (token: string) => void;
  }
}

export default function FormIndex() {
  const [page, setPage] = useState(0);
  const [leadFormData, setLeadFormData] = useState<TLeadFormsValueTypeProps>({
    areaStudy: {},
    mProgram: {},
    highestEducationName: '',
    highestEducationYear: '',
    military: '',
    militaryAffiliationName: '',
    duration: '',
    userLocation: {
      userAddress: '',
      userCity: '',
      userState: '',
      userCampusType: '',
      userZipCode: ''
    },
    userInformation: {
      userFirstName: '',
      userLastName: '',
      userEmail: '',
      userTimeZone: '',
      userPhoneNumber: '',
      userGender: ''
      // userCheck: false
    },
    lead_education: {
      start_date: '',
      education_level_id: '',
      school_type: '',
      zip: '',
      grad_year: ''
    },
    lead_background: {
      military_type: ''
    },
    lead: {
      phone1: '',
      email: '',
      firstName: '',
      lastName: ''
    },
    lead_address: {
      city: '',
      state: ''
    }
  })

  const [leadIdToken, setLeadIdToken] = useState<string | null>(null);
  const [trustedFormUrl, setTrustedFormUrl] = useState<string | null>(null);
  const totalSteps = 8;
  const displayLeadForm = () => {
    if (page === 0) {
      return <LeadFormAreaStudy onNext={handleNextPage} leadFormData={leadFormData} setLeadFormData={setLeadFormData} leadIdToken={leadIdToken} trustedFormUrl={trustedFormUrl} />
    } else if (page === 1) {
      return <LeadFormMProgram onNext={handleNextPage} leadFormData={leadFormData} setLeadFormData={setLeadFormData} leadIdToken={leadIdToken} trustedFormUrl={trustedFormUrl} />
    } else if (page === 2) {
      return <LeadFormDegreeInterest onNext={handleNextPage} leadFormData={leadFormData} setLeadFormData={setLeadFormData} leadIdToken={leadIdToken} trustedFormUrl={trustedFormUrl} />
    } else if (page === 3) {
      return <LeadFormMilitary onNext={handleNextPage} leadFormData={leadFormData} setLeadFormData={setLeadFormData} leadIdToken={leadIdToken} trustedFormUrl={trustedFormUrl} />
    } else if (page === 4) {
      return <LeadFormDuration onNext={handleNextPage} leadFormData={leadFormData} setLeadFormData={setLeadFormData} leadIdToken={leadIdToken} trustedFormUrl={trustedFormUrl} />
    } else if (page === 5) {
      return <LeadFormUserGender onNext={handleNextPage} leadFormData={leadFormData} setLeadFormData={setLeadFormData} leadIdToken={leadIdToken} trustedFormUrl={trustedFormUrl} />
    }else if (page === 6) {
      return <LeadFormLocationInformation onNext={handleNextPage} leadFormData={leadFormData} setLeadFormData={setLeadFormData} leadIdToken={leadIdToken} trustedFormUrl={trustedFormUrl} />
    }else if (page === 7) {
      return <LeadFormUserInformation onNext={handleNextPage} leadFormData={leadFormData} setLeadFormData={setLeadFormData} leadIdToken={leadIdToken} trustedFormUrl={trustedFormUrl} />
    } else if (page === 8) {
      return <ListingCom  onNext={handleNextPage} leadFormData={leadFormData} leadIdToken={leadIdToken} trustedFormUrl={trustedFormUrl} />
    }else{
      return <ThankYou leadFormData={leadFormData}  /> 
    }
  }

console.log('leadIdToken',leadIdToken)
  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'LeadiDscript_campaign';
    script.type = 'text/javascript';
    script.async = true;
    script.src = '//create.lidstatic.com/campaign/d50a3557-9854-7b8f-792c-527750401f5f.js?snippet_version=2&f=reset&callback=myCallbackFunction';

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  // Callback function to handle the token
  window.myCallbackFunction = function (token) {
    setLeadIdToken(token);
  };

  useEffect(() => {
    const field: string = 'xxTrustedFormCertUrl';
    const provideReferrer: boolean = false;

    // Create the script element
    const tf: HTMLScriptElement = document.createElement('script');
    tf.type = 'text/javascript';
    tf.async = true;
    tf.src =
      'http' +
      ('https:' === document.location.protocol ? 's' : '') +
      '://api.trustedform.com/trustedform.js?provide_referrer=' +
      encodeURIComponent(provideReferrer.toString()) +
      '&field=' +
      encodeURIComponent(field) +
      '&l=' +
      new Date().getTime() +
      Math.random();

    // Append the script to the DOM
    const s: HTMLScriptElement | null = document.getElementsByTagName('script')[0];
    if (s && s.parentNode) {
      s.parentNode.insertBefore(tf, s);
    }

    // Set the URL to state when the script is loaded
    tf.onload = () => {
      setTrustedFormUrl(tf.src);
    };
  }, []);

  const handleNextPage = () => {
    setPage(nextPage => nextPage + 1);
  };

  return (
    <React.Fragment>
      <LeadContextProvider>
      <Toolbar style={toolbarStyle} />
      <Box maxWidth={page< 8 ?"600px":'100%'} mx="auto" py={8} flexGrow="1" width="100%">
        {page < 8 && <LinearProgress variant="determinate" value={((page + 1) / totalSteps) * 100} color="success" />}
        <Box m={page < 8 ? 3 : ''}>
          <input type="hidden" name="leadid_token" value={leadIdToken || ''} />
          <div id="LeadiDscript"></div>
          <input type="hidden" name="trustedid_token" value={trustedFormUrl || ''} />
          <div id="LeadiDscript"></div>
          {displayLeadForm()}
        </Box>
      </Box>
      </LeadContextProvider>
      <Box>
      </Box>
    </React.Fragment>
  )
}
