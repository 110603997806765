// /mm-pixel-prod-test.s3.amazonaws.com

// Listing Logo Url
export const s3PrimaryLogo = 'https://edu.media-matchers.com/brand/primary-logo/'

// Editorial Logo Url
export const s3SecondaryLogo = 'https://edu.media-matchers.com/brand/secondary-logo/'

// Blog Image Url
export const s3BlogImage = 'https://edu.media-matchers.com/brand/blog-image/'

//Banner Image URL
export const s3BannerImage = 'https://edu.media-matchers.com/banner'