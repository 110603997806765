import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Logo from "../assets/images/logo.png";
import { useLocation } from "react-router-dom";
import { Container } from "@mui/system";
import CssBaseline from "@mui/material/CssBaseline";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

const toolbarStyle = {
  minHeight: "0",
};

function Header(props: any) {
  const location = useLocation()
  const currentLocation = location.pathname

  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="static"
        component="nav"
        sx={{
          backgroundColor: "#fff",
          boxShadow: "none",
          borderBottom: "1px solid #E2E2E2",
        }}
      >
        <Box className='top-header'>
          <Box className='top-header-content'>
            <Box className='top-header-left'>This site receives compensation, which can influence the order of listings  -
              <Box className='advertiser-tooltip mobile' component="span">
                <ClickAwayListener onClickAway={handleTooltipClose}>
                  <Tooltip
                    PopperProps={{
                      disablePortal: true
                    }}
                    onClose={handleTooltipClose}
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    className="top-header-tooltip-content"
                    title={
                      <>
                        <p>
                          <a href="https://www.compareonlinedegrees.com" target="_blank">
                            compareonlinedegrees.com
                          </a>{' '}
                          is a free online resource that strives to offer helpful content and comparison features to our visitors. We accept advertising compensation from companies that appear on the site, which impacts the location and order in which brands (and/or their products) are presented, and also impacts the score that is assigned to it. Company listings on this page DO NOT imply endorsement. We do not feature all providers on the market. Except as expressly set forth in our terms and conditions, all representations and warranties regarding the information presented on this page are disclaimed. The information, including pricing, which appears on this site is subject to change at any time.
                        </p>
                      </>
                    }
                  >
                    <Box onClick={handleTooltipOpen} className="top-header-btn">Advertiser Disclosure</Box>
                  </Tooltip>
                </ClickAwayListener>
              </Box> 

              <Box className='advertiser-tooltip desktop' sx={{ cursor: 'pointer' }}>
                <Tooltip
                  disableFocusListener disableTouchListener
                  className="top-header-tooltip-content"
                  title={
                    <>
                      <p>
                        <a href="https://www.compareonlinedegrees.com" target="_blank">
                          compareonlinedegrees.com
                        </a>{' '}
                        is a free online resource that strives to offer helpful content and comparison features to our visitors. We accept advertising compensation from companies that appear on the site, which impacts the location and order in which brands (and/or their products) are presented, and also impacts the score that is assigned to it. Company listings on this page DO NOT imply endorsement. We do not feature all providers on the market. Except as expressly set forth in our terms and conditions, all representations and warranties regarding the information presented on this page are disclaimed. The information, including pricing, which appears on this site is subject to change at any time.
                      </p>
                    </>
                  }
                >
                  <Box className="top-header-btn">Advertiser Disclosure</Box>
                </Tooltip>
              </Box>
            </Box>
          </Box>
        </Box>
        <Container maxWidth="lg">
          <Toolbar className="p-0" style={toolbarStyle}>
            <React.Fragment>
              {currentLocation === '/get-information' ? <Box sx={{ flexGrow: 1 }}>
                <Box className="lead-frm-logo">
                  <img src={Logo} alt="header logo" />
                </Box>
              </Box> : ''
              }
            </React.Fragment>
          </Toolbar>
        </Container>
      </AppBar >
    </Box >
  );
}

export default Header;
