import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Link
} from "@mui/material";
import starIcon from "../assets/images/icons/star.svg";
import { s3PrimaryLogo } from "../constants/url/logoUrl";
import { gtagEvent } from "../services/google-analytics";
import { initCCConvertion } from "../clickCaese";

export default function ListingCards(item: any) {
  const { mBrandName, headContent, mPosition, clickURL, bodyContent, introduction, mBrandID, Commission, imageURL, requestData,areaStudy } = item
  const handleVisitSiteClick = () => {
    const conversionValue = Commission
    const transactionID = `${requestData}-${mPosition}`

    window.dataLayer.push({
      event: 'mm-cod-gtag',
      conversionValue: conversionValue,
      transactionID: transactionID
    });

    gtagEvent("click", {
      brand_name: mBrandName,
      brand_id: mBrandID,
      link_url: clickURL,
      time_stamp: (new Date()).getTime(),
    });
    initCCConvertion(conversionValue);
  }
  
  return (
    <Box>
      <Card className="listing-card"
        onClick={handleVisitSiteClick}
        component={Link}
        href={clickURL}
        target="_blank"
        sx={{
          marginBottom: '15px',
          textDecoration: "none",
        }}
      >
        <Box className="info-1">
          {mPosition === 1 ? <Box className="list-rank-count first">{mPosition}</Box> : <Box className="list-rank-count">{mPosition}</Box>}
          <CardMedia
            component="img"
            // image={`${s3PrimaryLogo}${mBrandID}.webp`}
            image={imageURL}
            alt={`area_study_${areaStudy}`}
            title={mBrandName}
          />
          {/* {mPosition === 1 ?
            <Box
              className="popular-tag"
              display="flex"
              alignItems="center"
              gap="6px"
            >
              <img src={starIcon} alt="" /> Most Popular
            </Box>
            : ''} */}
        </Box>

        <CardContent className="info-2">
          <Typography component="div" variant="h5" fontWeight="600" mb={1}  className="mBrandName">
            {mBrandName}
          </Typography>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            component="p"
            lineHeight={1.3}
            fontWeight="600"
            className="subtitle"
          >
            {headContent ? headContent : introduction}
          </Typography>

          {/* TODO */}
          <div dangerouslySetInnerHTML={{ __html: bodyContent }} />
          <Box textAlign="center" mt={2}>
            <Button
              component={Link}
              href={clickURL}
              target="_blank"
              variant="contained"
              color="success"
              size="large"
              sx={{
                textTransform: "none",
                fontSize: "18px",
                borderRadius: "8px",
                minWidth: "150px",
              }}
              onClick={handleVisitSiteClick}
            >
              Visit Site
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}
