import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Logo from "../assets/images/logo.png";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Link, useNavigate } from "react-router-dom";
import { navigation } from "../constants/headerNav/navigations";

export default function Footer() {

  const navigate = useNavigate()

  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box className="footer">
      <Container>
        <Grid container columnSpacing={4}>
          <Grid item xs={12} md={3}>
            <Box display="flex" alignItems="center" height="100%">
              <img src={Logo} alt="" width="180" />
            </Box>
          </Grid>
          <Grid item xs={12} md={9}>
            <List disablePadding>
              <ListItem disablePadding>
                <Button
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  endIcon={<KeyboardArrowDownIcon />}
                  className="footer-link"
                >
                  Area Of Study
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  className="footer-menu"
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >{navigation.map((footerNav, key) => (
                  <MenuItem key={key} onClick={() => {
                    navigate(footerNav.href, { state: footerNav.state });
                    setAnchorEl(null);
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                  }}>
                    {footerNav.name}
                  </MenuItem>
                ))}
                </Menu>
              </ListItem>
            </List>
            <Typography component="p" color="initial" mt={3} fontSize="14px">
              <a href="https://www.compareonlinedegrees.com" target="_blank">
                compareonlinedegrees.com
              </a> is a free online resource that strives to offer helpful content and comparison features to our visitors. We accept advertising compensation from companies that appear on the site, which impacts the location and order in which brands (and/or their products) are presented, and also impacts the score that is assigned to it. Company listings on this page DO NOT imply endorsement. We do not feature all providers on the market. Except as expressly set forth in our terms and conditions, all representations and warranties regarding the information presented on this page are disclaimed. The information, including pricing, which appears on this site is subject to change at any time."
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Box className="footer-links">
        <Container>
          <Link to="http://www.bdmdonotsell.com/" target="_blank">Do not sell my information - 800-354-6965</Link>
          <Link to="https://www.compareonlinedegrees.com/terms-of-use" target="_blank">Terms of Use</Link>
          <Link to="https://www.compareonlinedegrees.com/privacy-policy" target="_blank">Privacy Policy</Link>
          <Link to="https://www.media-matchers.com/contact" target="_blank">Contact</Link>
        </Container>
      </Box>
      <Box className="copyright">Copyright © 2023 Media Matchers LLC. All Rights Reserved.</Box>
    </Box>
  );
}
