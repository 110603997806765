import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { Container } from '@mui/system';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { s3BannerImage } from '../constants/url/logoUrl';
import { Helmet } from 'react-helmet';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { getResponse } from '../services';
import {
  BUSINESS_STUDY,
  CRIMINAL_JUSTICE_STUDY,
  EDUCATION_STUDY,
  GENERAL_STUDY,
  HEALTHCARE_STUDY,
  LIBERAL_ARTS_STUDY,
  NURSING_STUDY,
  PSYCHOLOGY_STUDY,
  TECHNOLOGY_STUDY,
  VOCATIONAL_TRAINING_STUDY,
} from '../constants/url/constantUrl';
import Header from './Header';
import SeoHandler from './SeoHandler';

export default function Banner(props: any) {
  const { selectedDegreeInterest, selectedAreaStudy, selectedMProgram } =
    props.bannerProps;
  const updateDate = new Date();
  const pathName = useLocation();
  const [searchParams] = useSearchParams();
  const currentPath = pathName.pathname;
  const [headerData, setHeaderData] = useState<any>('');
  const [loading, setLoading] = useState(true);
  const { id, id1, id2, id3, id4, id5, id6, id7, id8, id9 } = useParams();
  const date =
    ('0' + (updateDate.getMonth() + 1)).slice(-2) +
    ' / ' +
    ('0' + updateDate.getDate()).slice(-2) +
    ' / ' +
    updateDate.getFullYear();
  const perviousYear = updateDate.getFullYear();
  const nextYear = perviousYear + 1;
  const filterMProgram = selectedMProgram?.text?.includes('General')
    ? selectedMProgram?.text?.replace('General', '').trim()
    : selectedMProgram?.text;
  const newPath = currentPath === '/' || currentPath === '/undecided' || id9;
  const newDegreeInterestType =
    selectedDegreeInterest?.value === '1'
      ? 'Certificate & Diploma Training'
      : selectedDegreeInterest?.type;
  const capitalizeFirstLetter = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  const dynamicBannerNameFirst = searchParams.get('t1');
  const dynamicBannerNameSecond = searchParams.get('t2');
  const dynamicBannerNameThird = searchParams.get('t3');

  const capitalizeWords = (words: string | null) =>
    words
      ? words
          .split(' ')
          .map((word: string) => capitalizeFirstLetter(word))
          .join(' ')
      : '';
  const capitalizedT1 = capitalizeWords(dynamicBannerNameFirst);
  const capitalizedT2 = capitalizeWords(dynamicBannerNameSecond);
  const capitalizedT3 = capitalizeWords(dynamicBannerNameThird);

  useEffect(() => {
    fetchResponseForBanner();
  }, [currentPath]);

  const fetchResponseForBanner = async () => {
    if (currentPath === '/') {
      const bannerResponse = await getResponse(GENERAL_STUDY);
      setHeaderData(bannerResponse);
    } else if (currentPath === '/business' || id) {
      const bannerResponse = await getResponse(BUSINESS_STUDY);
      setHeaderData(bannerResponse);
    } else if (currentPath === '/criminaljustice' || id2) {
      const response = await getResponse(CRIMINAL_JUSTICE_STUDY);
      setHeaderData(response);
    } else if (currentPath === '/healthcare' || id4) {
      const response = await getResponse(HEALTHCARE_STUDY);
      setHeaderData(response);
    } else if (currentPath === '/computers&it' || id1) {
      const response = await getResponse(TECHNOLOGY_STUDY);
      setHeaderData(response);
    } else if (currentPath === '/education&teaching' || id3) {
      const response = await getResponse(EDUCATION_STUDY);
      setHeaderData(response);
    } else if (currentPath === '/psychology' || id5) {
      const response = await getResponse(PSYCHOLOGY_STUDY);
      setHeaderData(response);
    } else if (currentPath === '/nursing' || id6) {
      const response = await getResponse(NURSING_STUDY);
      setHeaderData(response);
    } else if (currentPath === '/vocationaltraining' || id7) {
      const response = await getResponse(VOCATIONAL_TRAINING_STUDY);
      setHeaderData(response);
    } else if (currentPath === '/liberalarts' || id8) {
      const response = await getResponse(LIBERAL_ARTS_STUDY);
      setHeaderData(response);
    } else if (currentPath === '/undecided' || id9) {
      const response = await getResponse(GENERAL_STUDY);
      setHeaderData(response);
    }
    setLoading(false);
  };

  useEffect(() => {
    console.log('selectedDegreeInterest', selectedDegreeInterest);
    // console.log('searchParams',searchParams)
    // console.log('pathName',pathName)
  }, [selectedDegreeInterest]);
  return (
    <Box>
      {headerData?.seo_title !== undefined ? (
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            {' '}
            {headerData?.seo_title
              ? headerData.seo_title
              : headerData.seo_title}
          </title>
          <meta name="description" content={headerData.seo_description}></meta>
          <meta name="keywords" content={headerData.seo_keywords}></meta>
          <meta property="keywords" content={headerData.seo_keywords}></meta>
          <meta
            property="og:title"
            content={
              headerData?.seo_title
                ? headerData.seo_title
                : headerData.seo_title
            }
          />
          <meta
            property="og:description"
            content={headerData.seo_description}
          />
        </Helmet>
      ) : (
        ''
      )}
      <Box className="banner-section">
        {props.bannerProps ? (
          <Box>
            <img
              src={
                props.bannerProps && selectedAreaStudy.category_id
                  ? `${s3BannerImage}/${selectedAreaStudy.category_id}.webp`
                  : ''
              }
              alt=""
            />
            <Container className="mw-1170">
              <Box className="banner-content">
                <React.Fragment>
                  <Typography
                    component="h1"
                    fontSize="36px"
                    fontWeight="bold"
                    lineHeight="1.05"
                  >
                    {newPath &&
                    newDegreeInterestType &&
                    selectedDegreeInterest?.value ? (
                      <span>
                        {' '}
                        {dynamicBannerNameFirst
                          ? capitalizedT1
                          : 'Compare Online'}{' '}
                        <span  className={selectedDegreeInterest?.value ?'text-green':''}>
                          {' '}
                          {selectedDegreeInterest?.value &&
                            newDegreeInterestType}{' '}
                          {'Programs'}
                        </span>
                      </span>
                    ) : (
                      <span>
                        {''}
                        {dynamicBannerNameFirst
                          ? capitalizedT1
                          : 'Compare Online'}{' '}
                        <span  className={selectedDegreeInterest?.value ?'text-green':''}>
                          {' '}
                          {newDegreeInterestType}{' '}
                          {newPath
                            ? selectedDegreeInterest?.value === '1'
                              ? 'for'
                              : 'Degree'
                            : !selectedDegreeInterest?.value
                            ? 'Degrees in'
                            : selectedDegreeInterest?.value === '1'
                            ? 'Programs in'
                            : ' in'}{' '}
                        </span>
                        <span  className={!newPath ?'text-green':''}>
                          {newPath ? 'Programs' : `${filterMProgram}`}
                        </span>
                        <span className='minW-768-inline'>{!loading
                          ? (headerData.state  && headerData.state !=='undefined')
                            ? ` for ${headerData.state}`
                            : ' Near You'
                          : ''}</span>
                      </span>
                    )}
                    <br />
                    <span className="inner-span mw-768-d-none">
                      {dynamicBannerNameSecond ? (
                        `${capitalizedT2} ${!loading
                          ? (headerData.state  && headerData.state !=='undefined')
                            ? ` for ${headerData.state}`
                            : ' Near You'
                          : ''}`
                      ) : (
                        <>
                          Find Online{' '}
                          {!newPath &&
                            selectedAreaStudy.category_id !== '4' &&
                            selectedAreaStudy.category_name}{' '}
                          {/* {newDegreeInterestType === 'Certificate'
          ? 'Schools for'
          : 'Colleges for'}{' '} */}
                          Colleges{' '}
                          {!loading
                            ? (headerData.state  && headerData.state !=='undefined')
                              ? ` for ${headerData.state}`
                              : ' Near You'
                            : ''}
                        </>
                      )}
                    </span>
                  </Typography>
                  <Typography variant="body1" fontSize="20px">
                    {capitalizedT3 ? capitalizedT3 : `Online Colleges are now accepting applications for ${perviousYear}-${nextYear}`}
                  </Typography>
                </React.Fragment>
              </Box>
            </Container>
          </Box>
        ) : (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div className="custom-loader"></div>
          </div>
        )}
      </Box>
      <Header />
      <Box className="banner-head-info">
        <Container maxWidth="lg">
          <Grid container columnSpacing={4}>
            <Grid item xs={12} md={8} className="banner-info">
              <Typography
                variant="body1"
                color="white"
                py={1}
                lineHeight="1.05"
              >
                {'Select a School Below to Request Information'}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4} className="banner-head-mobile">
              <Typography
                variant="body1"
                color="white"
                py={1}
                textAlign="center"
                fontSize="20px"
                lineHeight="1.05"
              >
                {' Last Updated:'} {date}
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}
