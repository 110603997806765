import React from 'react'
import { Box, Container, Toolbar, Typography } from '@mui/material'

const toolbarStyle = {
    minHeight: "71px",
};

export default function TermsOfUse() {
    return (
        <div>
            <Toolbar style={toolbarStyle} />
            <Box>
                <Container maxWidth="lg" sx={{ py: "30px" }}>
                    <Typography style={{ textAlign: 'center' }} variant="h3">Terms of Use</Typography>
                    <Typography fontWeight="600" mb={3}>Last Updated: May 2022</Typography>
                    <Typography mt={2} mb={2}>Welcome to our Website ("CompareOnlineDegrees.com")! ThisSite lets you know how we can help
                        you meet your educational goals. Please read this User Agreement carefully, because by visiting or
                        using this Site, you agree to be legally bound by and comply with its terms.If you do not agree to
                        comply with this User Agreement, you are not authorized to use the Site.</Typography>
                    <Typography mb={3}>We may revise this User Agreement from time to time, so please check this Web page each time
                        you visit the Site. If you continue to use theSite after we post the change, this means you have
                        agreed to the new terms.</Typography>


                    <Typography component="h2" fontSize="25px" fontWeight="800" mb={2}>Ownership and Process</Typography>
                    <Typography mb={2}>Our Site matches the information that you supply to us to particular schools that partner with us to
                        identify interested candidates. Additionally, your information may be supplied to those schools, other
                        schools or multiple schools. Matching criteria may include the number of student seats that the
                        partnering institution has indicated to us it wishes our assistance in filling, the school's other
                        partnership arrangements with us, or other criteria
                    </Typography>

                    <Typography component="h2" fontSize="25px" fontWeight="800" mb={2}>Privacy Policy</Typography>
                    <Typography mb={2}>Please review our Privacy Policy at the footer of CompareOnlineDegrees.com, which is a part of this
                        User Agreement and describes how we handle any personally identifying information collected from
                        visitors to this Site.</Typography>

                    <Typography component="h2" fontSize="25px" fontWeight="800" mb={2}>Suspected Violation of these Terms of Use</Typography>
                    <Typography mb={2}>Violations of these Terms of Use, including unauthorized use of this site, may be investigated and
                        appropriate legal action may be taken, including civil, criminal and injunctive redress. You agree that
                        monetary damages may not provide a sufficient remedy to us for violations of these Terms of Use
                        and you consent to injunctive or other equitable relief for such violations. You understand and agree
                        that in our sole discretion and for any reason whatsoever, or for no reason, and without prior notice,
                        we may terminate your access to the site.</Typography>


                    <Typography component="h2" fontSize="25px" fontWeight="800" mb={2}>Forums and Public Communication</Typography>
                    <Typography mb={2}>For your convenience, we may offer discussion forums and blog opportunities ("Forums") where you
                        and other users can submit content for public viewing ("Forum Content") to facilitate your socializing
                        and networking with others interested in distance or online learning. You agree that all Forum
                        Content is the sole responsibility of the individual or entity from whom such Forum Content
                        originated. This means that you (and not our Site) are entirely responsible for all Forum Content that
                        you submit via the Forum or elsewhere on the site. We do not control or guarantee the accuracy,
                        completeness, usefulness, integrity, or quality of Forum Content. You understand and agree that you
                        may be exposed to Forum Content that is offensive, indecent, or objectionable. Under no circumstances will we be liable in any way for any Forum Content, including any errors or omissions
                        in any Forum Content or any loss or damage of any kind incurred as a result of the use of any Forum
                        Content submitted via this site.</Typography>


                    <Typography component="h2" fontSize="25px" fontWeight="800" mb={2}>Registration and Account Security</Typography>
                    <Typography mb={2}>You represent and warrant that the registration information you provide to us is and will remain true
                        and accurate. You are solely responsible for the security and confidentiality of your password and
                        account. Furthermore, you are solely responsible for any and all activities that occur under your
                        account. You agree to immediately notify us of any unauthorized use of your account or any other
                        breach of security with regard to your use of the site of which you become aware.</Typography>

                    <Typography component="h2" fontSize="25px" fontWeight="800" mb={2}>Your Participation in the Forums</Typography>
                    <Typography mb={2}>You agree that you will not use a Forum or this site to submitor otherwise make available Forum
                        Content that:</Typography>
                    <Typography mb={2}> a.  Is unlawful, harmful, threatening, abusive, harassing, tortious, defamatory, vulgar, obscene, libelous, invasive of another's privacy, racially or otherwise hateful, or otherwise objectionable;</Typography>
                    <Typography mb={2}>b. Harms minors;</Typography>
                    <Typography mb={2}>   c.  Impersonates any person or entity or falsely states or otherwise misrepresents your affiliation
                        with a person or entity;</Typography>
                    <Typography mb={2}>   d. Conflicts with any obligations you have under law including under contractual or fiduciary
                        relationships (such as inside information, proprietary and confidential information learned or
                        disclosed aspart of employment relationships or under non-disclosure agreements);</Typography>
                    <Typography mb={2}>   e.  Infringes any copyright, trademark, patent, trade-secret, or other proprietary rights of any
                        third party;</Typography>
                    <Typography mb={2}>   f.  Constitutes or contains unsolicited or unauthorized advertising, promotional materials, "junk
                        mail,""spam," "chain letters," "pyramid schemes," or any other form of unauthorized solicitation;</Typography>
                    <Typography mb={2}>   g. Constitutes or contains software viruses or any other computer code, files or programs
                        designed to interrupt, destroy, or limit the functionality of any computer software or hardware or
                        telecommunications equipment;</Typography>
                    <Typography mb={2}>   h.  Interferes with or disrupts the site, related services, or servers or networks connected thereto
                        or disobeys any requirements, procedures, policies, or regulations of networks connected to this site
                        or related services;</Typography>
                    <Typography mb={2}>   i.   Violates any applicable local, state, national, or international law or any regulations having
                        the force oflaw;</Typography>
                    <Typography mb={2}>   j.   Is used to stalk or otherwise harass another.</Typography>


                    <Typography component="h2" fontSize="25px" fontWeight="800" mb={2}>Forum Content Posted to Forums</Typography>
                    <Typography mb={2}>You acknowledge that we may or may not pre-screen Forum Content, but that we will have the right
                        in our sole discretion, for any reason whatsoever (or for no reason) to pre-screen, refuse, move, or
                        remove any Forum Content that is available via the site. Without limiting the foregoing, we may
                        remove any Forum Content that violates the Terms of Use or is otherwise objectionable in our sole
                        discretion.</Typography>
                    <Typography mb={2}>We assert no ownership rights over the Forum Content you post, transmit, or otherwise make
                        available for inclusion on the Forums or elsewhere on the site. With respect to such Forum Content,
                        however, you grant our Site a worldwide, royalty-free, perpetual, irrevocable, non-exclusive license
                        to use such Forum Content on the Forums, anywhere on the site, or elsewhere and in any media for
                        the purpose of providing, promoting, or advertising the site and related services; such license will
                        survive the termination, for any reason, of these Terms of Use.</Typography>

                    <Typography component="h2" fontSize="25px" fontWeight="800" mb={2}>DMCA Notice of Alleged Infringement ("Notice")</Typography>
                    <Typography mb={2}>You represent and warrant that the registration information you provide to us is and will remain true
                        and accurate. You are solely responsible for the security and confidentiality of your password and
                        account. Furthermore, you are solely responsible for any and all activities that occur under your
                        account. You agree to immediately notify us of any unauthorized use of your account or any other
                        breach of security with regard to your use of the site of which you become aware.</Typography>
                    <Typography mb={2}>      a.     Identify the copyrighted work that you claim has been infringed, or — if multiple works are
                        covered by this Notice — you may provide a representative list of the copyrighted works that you
                        claim have been infringed.</Typography>

                    <Typography mb={2}>   b.    Identify the material or link you claim is infringing and provide a description of where the
                        infringing work is located on the site. </Typography>

                    <Typography mb={2}>   c.     Provide your mailing address, telephone number and, if available, e-mail address.d.    Include
                        both of the following statements in the body of the Notice:</Typography>
                    <Typography ml={3} mb={2}> ·         hereby state that I have a good-faith belief that the disputed use of the copyrighted material is
                        not authorized by the copyright owner, its agent, or the law (e.g., fair use).</Typography>
                    <Typography ml={3} mb={2}>  ·         I hereby state that the information in this Notice is accurate and, under penalty of perjury, that
                        I am the owner or authorized to act on behalf of the owner, of the copyright or of an exclusive right
                        under the copyright that is allegedly infringed.</Typography>

                    <Typography mb={2}>    e.     Provide your full legal name and your electronic or physical signature.</Typography>

                    <Typography component="h2" fontSize="25px" fontWeight="800" mb={2}>Intellectual Property</Typography>
                    <Typography mb={2}>All material on or available through this site, including text, images, data, and illustrations,
                        (collectively, the "Material") is protected by U.S. and international copyright, trademark, and other
                        intellectual property laws, and is owned, controlled, or licensed by Media-Matchers LLC (“MEDIA-
                        MATCHERS”). UNAUTHORIZED COPYING, REPRODUCTION, REPUBLISHING, UPLOADING,
                        DOWNLOADING, POSTING, TRANSMITTING OR DUPLICATING THE MATERIAL, IN WHOLE OR
                        IN PART, IS PROHIBITED. Provided that you agree to and accept without modification the notices,
                        terms and conditions set forth in these Terms of Use, MEDIA-MATCHERS grants you a personal,
                        non-exclusive, non-assignable and non-transferable license to visit, view, and retain a copy of pages
                        of this site for your own personal, non-commercial use only. You may not modify, copy (except as
                        set forth in the preceding sentence), distribute, transmit, display, perform, reproduce, publish, create
                        derivative works from, license or sublicense, assign, or otherwise transfer this license or any of the Material or any information, material, software, products or services from this site. Moreover, you
                        may not visit or use the site to appropriate MEDIA-MATCHERS's trademarks or tradedress and/or to
                        copy or imitate the "look and feel" of the site, or any portions thereof, for any reason.</Typography>

                    <Typography component="h2" fontSize="25px" fontWeight="800" mb={2}>Access and Interference</Typography>
                    <Typography mb={2}>You will not use any robot, spider, crawling, scraping, or other automated device, process, or means
                        to access this site. Nor will you use any manual process to monitor or copy the site or the content
                        contained thereon for any unauthorized purpose without our prior written permission. You will not
                        use any device, software, routine, or take any action that interferes with the proper working of the
                        site.</Typography>

                    <Typography component="h2" fontSize="25px" fontWeight="800" mb={2}>Links, Frames, and Metatags</Typography>
                    <Typography mb={2}>You may link to the home page of the Site as long as the link does not cast us in a false or
                        misleading light. Please let us know if you link to the Site by contacting us at:</Typography>
                    <Typography mb={2}> CompareOnlineDegrees.com c/o Media-Matchers LLC, 1865 Chestnut Street, San Francisco,
                        California 94123</Typography>
                    <Typography mb={2}>You may not frame the content of the Site. You may not use metatags or any other "hidden text" that
                        incorporates the ourTrademarks or our name without our express written consent.</Typography>


                    <Typography component="h2" fontSize="25px" fontWeight="800" mb={2}>Claims of Infringement</Typography>
                    <Typography mb={2}>We respect the intellectual property of others and require that you do the same. In accordance with
                        the Digital Millennium Copyright Act ("DMCA"), the text of which may be found on the U.S. Copyright
                        Office site at http://www.copyright.gov/legislation/dmca.pdf, MEDIA-MATCHERS will respond
                        expeditiously to notices of alleged copyright infringement that are duly reported to its Designated
                        Copyright Agent identified in the notice below. We will disable and/or terminate the accounts of users
                        who are repeat infringers. If you believe your content has been copied in a way that constitutes
                        copyright infringement, or your intellectual property rights have been otherwise violated, please
                        provide our Copyright Agent the following information:</Typography>

                    <Typography mb={2}>Deliver this Notice, with all items completed, to us at:</Typography>

                    <Typography mb={2}>Compliance Department, CompareOnlineDegrees.com c/o Media-Matchers LLC, 1865 Chestnut
                        Street, San Francisco, California 94123 </Typography>

                    <Typography mb={2}>While we consider all such notices seriously, you may be liable for damages (including costs and
                        attorneys' fees) if you materially misrepresent that content or activity is infringing. For example, one
                        decision ordered a company that sent infringement notification seeking removal of online materials
                        that were protected by copyright's fair use doctrine to pay such costs and attorneys' feels the
                        company agreed to settle the matter at over $100,000. For more information, please
                        seehttp://www.onlinepolicy.org/action/legpolicy/opg_v_diebold/ . Accordingly, if you are uncertain
                        whether material infringes your copyrights (including whether use of copyrighted material may
                        constitute fair use) you may wish to seek the advice of an attorney.</Typography>

                    <Typography variant="h2" fontWeight="800" fontSize="30px" mb={2}>General Legal</Typography>

                    <Typography component="h2" fontSize="25px" fontWeight="800" mb={2}>Warranty Disclaimers</Typography>

                    <Typography mb={2}>YOUR USE AND BROWSING OF THIS SITE IS SOLELY AT YOUR OWN RISK. THE SITE, AND
                        EVERYTHING ON THIS SITE, INCLUDING THE MATERIALS ARE PROVIDED "AS IS" WITHOUT
                        WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED. MEDIA-MATCHERS.COM, ITS
                        AFFILIATES AND AGENTS, SPECIFICALLY DISCLAIM ALL WARRANTIES AND CONDITIONS
                        OF ANY KIND, INCLUDING ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR
                        A PARTICULAR PURPOSE, TITLE, OR NON-INFRINGEMENT. WITHOUT LIMITATION, MEDIA-
                        MATCHERS, ITS AFFILIATES AND AGENTS MAKE NO WARRANTIES OR REPRESENTATIONS
                        THAT THE SITE WILL BE ERROR-FREE OR UNINTERRUPTED, THAT DEFECTS WILL BE
                        CORRECTED, OR THAT THIS SITE, OR ITS SERVER ARE OR WILL BE FREE OF VIRUSES OR
                        OTHER HARMFUL COMPONENTS. IN ADDITION, MEDIA-MATCHERS, ITS AFFILIATES AND
                        AGENTS MAKE NO WARRANTIES OR REPRESENTATIONS REGARDING THE USE OR
                        RESULTS OF THE USE OF THE CONTENT ON THIS SITE OR ON ANY CO-BRANDED SITE.
                        YOU UNDERSTAND AND AGREE THAT APPLICABLE LAW MAY NOT ALLOW THE EXCLUSION
                        OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU.</Typography>


                    <Typography component="h2" fontSize="25px" fontWeight="800" mb={2}>Indemnification</Typography>

                    <Typography mb={2}>You will defend, indemnify, and hold MEDIA-MATCHERS, its affiliates and agents harmless from: (i)
                        your use of and access of the site; (ii) your violation of any of these Terms of Use; (iii) your violation
                        of any third-party right including any copyright, trademark, tradedress, trade secret, or privacy right
                        of content available on the site. This defense and indemnification obligation will survive these Terms
                        of Use and your use of the site</Typography>

                    <Typography component="h2" fontSize="25px" fontWeight="800" mb={2}>Limitation of Liability</Typography>
                    <Typography mb={2}>IN NO EVENT WILL MEDIA-MATCHERS OR ITS AFFILIATES OR AGENTS BE LIABLE FOR ANY
                        INDIRECT, CONSEQUENTIAL, SPECIAL, INCIDENTAL OR PUNITIVE DAMAGES ARISING OUT
                        OF YOUR ACCESS TO, OR USE OF, THIS SITE OR THE CONTENT OR MATERIALS THEREIN,
                        WHETHER IN A CONTRACT ACTION OR BASED ON NEGLIGENCE, OR OTHER TORT ACTION,
                        OR ANY OTHER CLAIM WHATSOEVER, EVEN IF WE HAVE BEEN ADVISED OF THE
                        POSSIBILITY OF SUCH DAMAGES. APPLICABLE LAW MAY NOT ALLOW THE LIMITATION OR
                        EXCLUSION OF LIABILITY OR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE
                        LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU. IN NO EVENT WILL MEDIA-
                        MATCHERS’s TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES, AND CAUSES OF
                        ACTION, WHETHER IN CONTRACT, TORT, NEGLIGENCE, OR OTHERWISE, EXCEED THE
                        AMOUNT PAID BY YOU, IF ANY, IN ACCESSING THIS SITE.</Typography>

                    <Typography component="h2" fontSize="25px" fontWeight="800" mb={2}>Termination</Typography>
                    <Typography mb={2}>The Terms of Use and/or site may be terminated by MEDIA-MATCHERS for any reason (or for no
                        reason) at any time without notice. In the event of termination, you are no longer authorized to
                        access the site and all limitations on MEDIA-MATCHERS’s liability regarding your use of the site and
                        any Forum Content you post will survive</Typography>

                    <Typography component="h2" fontSize="25px" fontWeight="800" mb={2}>Contact Media-Matchers.com</Typography>
                    <Typography mb={2}>If you have questions, please contact us at admin@media-matchers.com. Please include the words,
                        "Terms of Use" in the subject line of your e-mail.</Typography>

                    <Typography component="h2" fontSize="25px" fontWeight="800" mb={2}>General</Typography>
                    <Typography mb={2}>These Terms of Use will be governed by the laws of the state of California without respect to its
                        conflict of laws provision. Any claim or dispute between you and this Site that arises in whole or in
                        part from this site will be decided exclusively by a court of competent jurisdiction located in San
                        Francisco County, California. Use of this site is unauthorized in any jurisdiction that does not give full
                        effect to all provisions of these Terms of Use, including without limitation this paragraph and the
                        warranty disclaimers and liability exclusions above. We have endeavored to comply with all legal
                        requirements known to it in creating and maintaining this site, but make no representation that
                        Materials on this site are appropriate or available for use in any particular jurisdiction. Use of this site
                        is unauthorized in any jurisdiction where all or any portion of this site may violate any legal
                        requirements and you agree not to access this site in any such jurisdiction. You are responsible for
                        compliance with applicable laws. Any use in contravention of this provision or any provision of these
                        Terms of Use is at your own risk. Any other legal notices published by us on this site constitute the
                        entire agreement between you and MEDIA-MATCHERS concerning this site. If any provision of
                        these Terms of Use are deemed invalid by a court of competent jurisdiction, then the invalid or
                        unenforceable provision will be deemed superseded by a valid, enforceable provision that most
                        closely matches the intent of the original provision and the remaining provisions of the Terms of Use
                        shall govern such use. Our failure to insist on or enforce strict performance of any provision of these
                        Terms of Use will not be construed as a waiver of any provision or right.</Typography>
                </Container>
            </Box>

        </div>
    )
}
