import React, { useEffect, useState, useContext } from 'react';
import { Typography, Box } from '@mui/material';
import { fetchListingData, preparedListingQuery } from '../../services';
import AdditionalLead from '../AdditionalLead/AdditionalLead';
import { LeadContext } from '../../contextProvider/leadContextProvider';
import { ResetTvRounded } from '@mui/icons-material';

const ListingCom = ({ onNext, leadFormData }: any) => {
  const { availableOffers } = useContext(LeadContext);
  const [getLocalItem, setGetLocalItem] = useState<any>(null);
  let [leadFormStep] = useState<any>({
    areaStudy: leadFormData?.areaStudy?.category_id,
    mProgram: leadFormData?.mProgram?.program_id,
    highestEducation: leadFormData?.highestEducationName.tcpaValue,
    gradYear: leadFormData.highestEducationYear.value,
    militaryAffiliation: leadFormData.militaryAffiliationName.value
      ? leadFormData.militaryAffiliationName.value
      : '1',
    startTimeFrame: leadFormData.duration.tcpaValue,
    campusType: leadFormData.userLocation.userCampusType.tcpaValue,
    mPubID: 93552,
    mPubCampaignID: 45438,
    webInitiatingURL: 'compareonlinedegrees.com',
    redirect: 1,
    gender: leadFormData.userInformation.userGender,
    zipCode:leadFormData?.userLocation?.userZipCode
  });

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    const delayedExecution = async () => {
      if (!(availableOffers && availableOffers?.length > 0)) {
         const url = await preparedListingQuery(leadFormStep);
        // const response = await fetchListingData(leadFormStep);
        if(!url) return;
        const newTab = window.open(url, '_blank');
        if (newTab) {
          newTab.focus();
        }
      }
    };
    const timeoutId = setTimeout(delayedExecution, 1000);
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <React.Fragment>
      <Box textAlign="center">
        {availableOffers === null ||
        availableOffers === undefined ||
        availableOffers?.length === 0 ? (
          <Typography
          component="h5"
          fontSize="20px"
          maxWidth="600px"
          lineHeight="1.3"
          mx="auto"
          fontWeight="800"
          mb={2}
        >
          Sorry {leadFormData.userInformation.userFirstName}, There were no exact program we can
          find with our partner schools, but here are a few schools that may
          be a good fit
        </Typography>
        ) : (
          <Box>
              <Box
              className="short-info"
              py={3}
              sx={{ backgroundColor: '#57A773' }}
            >
              <Typography
                component="h2"
                fontSize="23px"
                lineHeight="1.3"
                mx="auto"
                fontWeight="800"
                color="#fff"
              >
                {`Great News, ${leadFormData.userInformation.userFirstName}! We’ve found a great match for you`}
              </Typography>
              <Typography
                component="p"
                fontWeight="600"
                mb={1}
                fontSize="18px"
                color="#fff"
              >
                {`A representative will contact you shortly for next steps`}
              </Typography>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                gap="16px"
                mt={3}
              >
                {/* <img src='https://edu.media-matchers.com/brand/CTU-logo/11212.webp' alt='tcpalogo' width="100px" height="60px" /> */}
              </Box>
            </Box>
            {availableOffers !== null && availableOffers?.length > 0 && (
              <AdditionalLead onNext={onNext} leadFormData={leadFormData} />
            )}
          </Box>
        )}
      </Box>
    </React.Fragment>
  );
};

export default ListingCom;
