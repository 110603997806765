import React, { useState, useEffect, useRef } from 'react';
import {
  Toolbar,
  Container,
  Grid,
  Box,
  SelectChangeEvent,
  Button,
  Link,
  Typography,
  Card,
  CardContent,
} from '@mui/material';
import {
  useLocation,
  useNavigate,
  useParams,
  Link as RouteLink,
  useSearchParams,
} from 'react-router-dom';
import {
  fetchListingData,
  getResponse,
  removeSpecialCharacters,
} from '../services';
import { TListingData } from '../types/common';
import {
  BODY_CONTENT,
  BUSINESS_BODY_CONTENT,
  CRIMINAL_JUSTICE_BODY_CONTENT,
  EDUCATION_BODY_CONTENT,
  GENERAL_BODY_CONTENT,
  HEALTHCARE_BODY_CONTENT,
  LIBERAL_ARTS_BODY_CONTENT,
  NURSING_BODY_CONTENT,
  PSYCHOLOGY_BODY_CONTENT,
  TECHNOLOGY_BODY_CONTENT,
  TOTAL_CLICK_COUNT,
  VOCATIONAL_TRAINING_BODY_CONTENT,
} from '../constants/url/constantUrl';
import MenuDropDownComponent from '../components/Sidebars/MenuDropDownComponent';
import TotalClickedProgramCard from '../components/Sidebars/TotalClickedProgramCard';
import EditorialReviewCard from '../components/Sidebars/EditorialReviewCard';
import {
  mPrograms,
  areaStudyOptions,
  degreeInterestOptions,
} from '../mock/mProgramData';
import ListingCards from '../components/ListingCards';
import { s3SecondaryLogo } from '../constants/url/logoUrl';
import Banner from '../components/Banner';
import TopChoiceCards from '../components/TopChoiceCards';
import { gtagEvent } from '../services/google-analytics';
import { initCCConvertion } from '../clickCaese';

const toolbarStyle = {
  minHeight: '0',
  // minHeight: "111px",
};

interface bannerProps {
  selectedAreaStudy: {
    category_name: string;
    category_id: string;
  };
  selectedDegreeInterest: {
    type: string;
    value: string;
  };
  selectedMProgram: {
    text: string;
    value: string;
  };
}

const bannerProps_state: bannerProps = {
  selectedAreaStudy: { category_name: '', category_id: '' },
  selectedDegreeInterest: { type: '', value: '' },
  selectedMProgram: { text: '', value: '' },
};

const ParentMain = () => {
  const pathName = useLocation();
  const currentPath = pathName.pathname;
  const navigate = useNavigate();
  const { id, id1, id2, id3, id4, id5, id6, id7, id8, id9 } = useParams();
  const [degreeInterestMenuData, setDegreeInterestMenuData] = useState(
    degreeInterestOptions
  );
  const [degreeInterestValue, setDegreeInterestValue] = useState('');
  const [areaStudyMenuData, setAreaStudyMenuData] = useState(areaStudyOptions);
  const [areaStudyValue, setAreaStudyValue] = useState('');
  const [mProgramMenuData, setMProgramMenuData] = useState([]);
  const [mProgramValue, setMProgramValue] = useState('');
  const [listingData, setListingData] = useState<TListingData[]>([]);
  const [requestData, setRequestData] = useState('');
  const [bodyContents, setBodyContents] = useState<any[]>([]);
  const [totalClickCount, setTotalClickCount] = useState();
  const [loading, setLoading] = useState(true);
  const [isSubmitButtonTiger, setIsSubmitButtonTiger] = useState(false);
  const [searchParams] = useSearchParams();
  const [error, setError] = useState();
  const [bannerProps, setBannerProps] =
    useState<bannerProps>(bannerProps_state);
  const [searchParamsForListingAPI, setSearchParamsForListingAPI] =
    useState<any>({
      areaStudy: '',
      mProgram: '',
      degreeInterest: '',
      gradYear: 2008,
      campusType: 2,
      militaryAffiliation: 1,
      startTimeframe: 1,
      sub1: '',
      sub2: '',
      sub3: '',
      webLandingURL: '',
      webInitiatingURL: 'compareonlinedegrees.com',
      redirect: 0,
      mPubID: 13060,
      mPubCampaignID: 48106,
      adsMax: 100,
      enableFilterByCPC: 1,
      enableFilterByBrand: 1,
      // zipCode:33303
    });

const leadSectionRef = useRef(null);
    
  useEffect(() => {
    getListingData();
  }, [searchParamsForListingAPI]);

  useEffect(() => {
    fetchResponse();
    fetchedTotalClicks();
  }, [areaStudyMenuData, currentPath]);

  const getListingData = async () => {
    try {
      const res = await fetchListingData(searchParamsForListingAPI);
      if(res?.result?.length){
        const isMobile = window.innerWidth <= 799; 
        if(isMobile && isSubmitButtonTiger){
          scrollToSection(leadSectionRef,1500);
        }else{
         window.scrollTo({ top: 0, behavior: 'smooth' });
        }
      }
      setListingData(res.result);
      setRequestData(res.mRequestId);
      if (mProgramValue) {
        const contentResponse = await getResponse(BODY_CONTENT, mProgramValue);
        setBodyContents(contentResponse[0].sections);
      } else console.log('no mProgram found!');
    } catch (error: any) {
      setError(error);
    }
  };

  const totalLength = listingData?.length;
  const newTotalPickedLength = Math.min(totalLength, 3);

  const generateQueryParamsForNavigation = (queryParams: any, path: string) => {
    const shouldNotNavigate = true;
    // queryParams['shouldNotNavigate']=shouldNotNavigate
    const queryParamsObject = new URLSearchParams(queryParams).toString();
    // if(shouldNotNavigate)
    const navigateToNewPath = `/${path}?${queryParamsObject}`;
    return navigateToNewPath;
  };

  const fetchResponse = async () => {
    const queryParams: any = {};
    const localStorageDegreeInterestValue = localStorage.getItem('degreeValue');
    const searchQueryParamDegreeInterest = searchParams.get('degreeInterest');
    const searchQueryParamAreaStudy = searchParams.get('areaStudy');
    const searchQueryParamMProgram = searchParams.get('mProgram');
    const shouldNotNavigateInQuery = searchParams.get('shouldNotNavigate');
    if (searchParams) {
      Array.from(searchParams.entries()).forEach(
        ([key, value]: [string, string]) => {
          queryParams[key] = value;
        }
      );
    }
    if (currentPath === '/') {
      // setDegreeInterestValue(localStorageDegreeInterestValue ? localStorageDegreeInterestValue : '3')
      setDegreeInterestValue(
        localStorageDegreeInterestValue
          ? localStorageDegreeInterestValue
          : searchQueryParamDegreeInterest
          ? searchQueryParamDegreeInterest
          : ''
      );
      setAreaStudyValue('');
      setMProgramMenuData(mPrograms[9]);
      setMProgramValue('900');
      if (searchParamsForListingAPI) {
        setSearchParamsForListingAPI({
          ...searchParamsForListingAPI,
          degreeInterest: localStorageDegreeInterestValue
            ? localStorageDegreeInterestValue
            : searchQueryParamDegreeInterest
            ? searchQueryParamDegreeInterest
            : '5',
          areaStudy: '9',
          mProgram: '900',
        });
      } else {
        setDegreeInterestValue('3');
      }
      setBannerProps((prevState: any) => ({
        ...prevState,
        selectedAreaStudy: { category_name: 'Undecided', category_id: '9' },
        selectedMProgram: { text: 'Undecided', value: '900' },
        selectedDegreeInterest: { type: '', value: '' },
      }));
      setSearchParamsForListingAPI((prevState: any) => ({
        ...prevState,
        ...queryParams,
      }));
      setLoading(false);
      if (searchQueryParamDegreeInterest) {
        const degree = degreeInterestMenuData.find(
          (i: any) => i.value === searchQueryParamDegreeInterest
        );
        setDegreeInterestValue(searchQueryParamDegreeInterest);
        setBannerProps(prevState => ({
          ...prevState,
          selectedDegreeInterest: {
            type: degree.type,
            value: degree.value,
          },
        }));
      }
      if (searchQueryParamAreaStudy) {
        let datas = mPrograms[searchQueryParamAreaStudy];
        const pathArea = areaStudyMenuData.find(
          (item: any) => item.category_id === searchQueryParamAreaStudy
        ) as any;
        setMProgramMenuData(datas);
        setMProgramValue(datas[0].value.toString());
        setAreaStudyValue(searchQueryParamAreaStudy);
        const path = pathArea.category_name.replace(/\s+/g, '').toLowerCase();
        const navigateToNewPath = generateQueryParamsForNavigation(
          queryParams,
          path
        );
        navigate(navigateToNewPath);
      }
      if (searchQueryParamMProgram) {
        const mProgramZerothValue = parseInt(
          searchQueryParamMProgram.toString()[0]
        );
        const mProgramFirstValue = String(searchQueryParamMProgram).slice(0, 2);
        const filterSearchQuery =
          searchQueryParamMProgram.length < 4
            ? mProgramZerothValue
            : mProgramFirstValue;
        const areaStudyData: any = areaStudyMenuData.filter(
          (areaStudy: any) =>
            String(areaStudy.category_id) === String(filterSearchQuery)
        );
        const datas = mPrograms[areaStudyData[0].category_id];
        const datasFilter = datas.filter(
          (data: any) => String(data.value) === String(searchQueryParamMProgram)
        );
        setMProgramMenuData(datas);
        setAreaStudyValue(areaStudyData[0].category_id);
        setMProgramValue(datasFilter[0].value.toString());
        setBannerProps(prevState => ({
          ...prevState,
          selectedMProgram: {
            text: datasFilter[0].text,
            value: datasFilter[0].value,
          },
        }));
        //navigate
        const navigateToNewPath = generateQueryParamsForNavigation(
          queryParams,
          areaStudyData[0].category_name.replace(/\s+/g, '').toLowerCase()
        );
        navigate(navigateToNewPath);
      }
      if (searchQueryParamDegreeInterest && searchQueryParamAreaStudy) {
        const degree = degreeInterestMenuData.find(
          (i: any) => i.value === searchQueryParamDegreeInterest
        );
        let datas = mPrograms[searchQueryParamAreaStudy];
        const pathArea = areaStudyMenuData.find(
          (item: any) => item.category_id === searchQueryParamAreaStudy
        ) as any;
        setMProgramMenuData(datas);
        setMProgramValue(datas[0].value.toString());
        setAreaStudyValue(searchQueryParamAreaStudy);
        setBannerProps(prevState => ({
          ...prevState,
          selectedDegreeInterest: {
            type: degree.type,
            value: degree.value,
          },
        }));
        const path = pathArea.category_name.replace(/\s+/g, '').toLowerCase();
        const navigateToNewPath = generateQueryParamsForNavigation(
          queryParams,
          path
        );
        navigate(navigateToNewPath);
      }
      if (searchQueryParamDegreeInterest && searchQueryParamMProgram) {
        setDegreeInterestValue(searchQueryParamDegreeInterest);
        const mProgramZerothValue = parseInt(
          searchQueryParamMProgram.toString()[0]
        );
        const mProgramFirstValue = String(searchQueryParamMProgram).slice(0, 2);
        const filterSearchQuery =
          searchQueryParamMProgram.length < 4
            ? mProgramZerothValue
            : mProgramFirstValue;
        const degree = degreeInterestMenuData.find(
          (i: any) => i.value === searchQueryParamDegreeInterest
        );
        const areaStudyData: any = areaStudyMenuData.filter(
          (areaStudy: any) =>
            String(areaStudy.category_id) === String(filterSearchQuery)
        );
        const datas = mPrograms[areaStudyData[0].category_id];
        const datasFilter = datas.filter(
          (data: any) => String(data.value) === String(searchQueryParamMProgram)
        );
        setMProgramMenuData(datas);
        setAreaStudyValue(areaStudyData[0].category_id);
        setMProgramValue(datasFilter[0].value.toString());
        setBannerProps(prevState => ({
          ...prevState,
          selectedDegreeInterest: {
            type: degree.type,
            value: degree.value,
          },
          selectedMProgram: {
            text: datasFilter[0].text,
            value: datasFilter[0].value,
          },
        }));
        // navigate
        navigate(
          `/${areaStudyData[0].category_name
            .replace(/\s+/g, '')
            .toLowerCase()}/${datasFilter[0].text
            .replace(/\s+/g, '')
            .toLowerCase()}`
        );
        const path = areaStudyData[0].category_name
          .replace(/\s+/g, '')
          .toLowerCase();
        const navigateToNewPath = generateQueryParamsForNavigation(
          queryParams,
          path
        );
        navigate(navigateToNewPath);
      }
      if (searchQueryParamAreaStudy && searchQueryParamMProgram) {
        const areaStudyData: any = areaStudyMenuData.filter(
          (areaStudy: any) =>
            String(areaStudy.category_id) === String(searchQueryParamAreaStudy)
        );
        let datas = mPrograms[searchQueryParamAreaStudy];
        const datasFilter = datas.filter(
          (data: any) => String(data.value) === String(searchQueryParamMProgram)
        );
        setAreaStudyValue(searchQueryParamAreaStudy);
        setMProgramMenuData(datas);
        setMProgramValue(searchQueryParamMProgram);
        setBannerProps(prevState => ({
          ...prevState,
          selectedMProgram: {
            text: datasFilter[0].text,
            value: datasFilter[0].value,
          },
        }));
        const path = areaStudyData[0].category_name
          .replace(/\s+/g, '')
          .toLowerCase();
        const navigateToNewPath = generateQueryParamsForNavigation(
          queryParams,
          path
        );
        navigate(navigateToNewPath);
      }
      if (
        searchQueryParamDegreeInterest &&
        searchQueryParamAreaStudy &&
        searchQueryParamMProgram
      ) {
        const degree = degreeInterestMenuData.find(
          (i: any) => i.value === searchQueryParamDegreeInterest
        );
        const areaStudyData: any = areaStudyMenuData.filter(
          (areaStudy: any) =>
            String(areaStudy.category_id) === String(searchQueryParamAreaStudy)
        );
        const datas = mPrograms[areaStudyData[0].category_id];
        const datasFilter = datas.filter(
          (data: any) => String(data.value) === String(searchQueryParamMProgram)
        );
        setDegreeInterestValue(searchQueryParamDegreeInterest);
        setAreaStudyValue(searchQueryParamAreaStudy);
        setMProgramValue(searchQueryParamMProgram);
        setBannerProps(prevState => ({
          ...prevState,
          selectedDegreeInterest: {
            type: degree.type,
            value: degree.value,
          },
          selectedMProgram: {
            text: datasFilter[0].text,
            value: datasFilter[0].value,
          },
        }));
        const path = areaStudyData[0].category_name
          .replace(/\s+/g, '')
          .toLowerCase();
        const navigateToNewPath = generateQueryParamsForNavigation(
          queryParams,
          path
        );
        navigate(navigateToNewPath);
      }
      setLoading(false);
    } else if (currentPath === '/business' || id) {
      if (areaStudyMenuData.length > 0) {
        if (id) {
          const areaStudyData: any = areaStudyMenuData.filter(
            (areaStudy: any) =>
              areaStudy.category_name.toLowerCase() ===
              currentPath.split('/')[1].toLowerCase()
          );
          if (mPrograms[areaStudyData[0].category_id]) {
            let datas = mPrograms[areaStudyData[0].category_id];
            const datasFilter = datas.filter(
              (data: any) =>
                data.text.replace(' ', '').toLocaleLowerCase() ===
                id.replace(' ', '').toLocaleLowerCase()
            );
            setAreaStudyValue(areaStudyData[0].category_id);
            setMProgramMenuData(datas);
            setMProgramValue(datasFilter[0].value.toString());
            // setDegreeInterestValue(
            //   localStorageDegreeInterestValue
            //     ? localStorageDegreeInterestValue
            //     : ''
            // );
            if (searchParamsForListingAPI) {
              setSearchParamsForListingAPI({
                ...searchParamsForListingAPI,
                degreeInterest: localStorageDegreeInterestValue
                  ? localStorageDegreeInterestValue
                  : degreeInterestValue
                  ? degreeInterestValue
                  : '5',
                areaStudy: areaStudyData[0].category_id,
                mProgram: datasFilter[0].value.toString(),
              });
            }
            setBannerProps((prevState: any) => ({
              ...prevState,
              selectedAreaStudy: areaStudyData[0],
              selectedMProgram: datasFilter[0],
              selectedDegreeInterest: !degreeInterestValue? { type: '', value: '' }:prevState?.selectedDegreeInterest,
            }));
            setSearchParamsForListingAPI((prevState: any) => ({
              ...prevState,
              ...queryParams,
            }));
          }
          setLoading(false);
        } else {
          // Need to area study menu data
          const areaStudyData: any = areaStudyMenuData.filter(
            (areaStudy: any) =>
              areaStudy.category_name.toLowerCase() ===
              currentPath.replace('/', '').toLowerCase()
          );
          setAreaStudyValue(areaStudyData[0]?.category_id);
          setMProgramMenuData(
            mPrograms[parseInt(areaStudyData[0]?.category_id)]
          );
          setMProgramValue('100');
          // setDegreeInterestValue(
          //   localStorageDegreeInterestValue
          //     ? localStorageDegreeInterestValue
          //     : ''
          // );
          if (searchParamsForListingAPI) {
            setSearchParamsForListingAPI({
              ...searchParamsForListingAPI,
              degreeInterest: localStorageDegreeInterestValue
                ? localStorageDegreeInterestValue
                : degreeInterestValue
                ? degreeInterestValue
                : '5',
              areaStudy: areaStudyData[0]?.category_id,
              mProgram: '100',
            });
          } else {
            // setDegreeInterestValue('');
          }
          setBannerProps((prevState: any) => ({
            ...prevState,
            selectedAreaStudy: areaStudyData[0],
            selectedMProgram: { text: 'General Business', value: '100' },
            selectedDegreeInterest: !degreeInterestValue? { type: '', value: '' }:prevState?.selectedDegreeInterest,
          }));
          setSearchParamsForListingAPI((prevState: any) => ({
            ...prevState,
            ...queryParams,
          }));
        }
        if (searchQueryParamDegreeInterest) {
          const degree = degreeInterestMenuData.find(
            (i: any) => i.value === searchQueryParamDegreeInterest
          );
          setDegreeInterestValue(searchQueryParamDegreeInterest);
          setBannerProps(prevState => ({
            ...prevState,
            selectedDegreeInterest: {
              type: degree.type,
              value: degree.value,
            },
          }));
        }
        if (searchQueryParamAreaStudy) {
          let datas = mPrograms[searchQueryParamAreaStudy];
          const pathArea = areaStudyMenuData.find(
            (item: any) => item.category_id === searchQueryParamAreaStudy
          ) as any;
          setMProgramMenuData(datas);
          setMProgramValue(datas[0].value.toString());
          setAreaStudyValue(searchQueryParamAreaStudy);
          const navigateToNewPath = generateQueryParamsForNavigation(
            queryParams,
            `${pathArea.category_name.replace(/\s+/g, '').toLowerCase()}`
          );
          navigate(navigateToNewPath);
        }
        if (searchQueryParamMProgram) {
          const mProgramZerothValue = parseInt(
            searchQueryParamMProgram.toString()[0]
          );
          const mProgramFirstValue = String(searchQueryParamMProgram).slice(
            0,
            2
          );
          const filterSearchQuery =
            searchQueryParamMProgram.length < 4
              ? mProgramZerothValue
              : mProgramFirstValue;
          const areaStudyData: any = areaStudyMenuData.filter(
            (areaStudy: any) =>
              String(areaStudy.category_id) === String(filterSearchQuery)
          );
          const datas = mPrograms[areaStudyData[0].category_id];
          const datasFilter = datas.filter(
            (data: any) =>
              String(data.value) === String(searchQueryParamMProgram)
          );
          setMProgramMenuData(datas);
          setAreaStudyValue(areaStudyData[0].category_id);
          setMProgramValue(datasFilter[0].value.toString());
          setBannerProps(prevState => ({
            ...prevState,
            selectedMProgram: {
              text: datasFilter[0].text,
              value: datasFilter[0].value,
            },
          }));
          //navigate
          const navigateToNewPath = generateQueryParamsForNavigation(
            queryParams,
            areaStudyData[0].category_name.replace(/\s+/g, '').toLowerCase()
          );
          navigate(navigateToNewPath);
        }
        if (searchQueryParamDegreeInterest && searchQueryParamAreaStudy) {
          const degree = degreeInterestMenuData.find(
            (i: any) => i.value === searchQueryParamDegreeInterest
          );
          let datas = mPrograms[searchQueryParamAreaStudy];
          const pathArea = areaStudyMenuData.find(
            (item: any) => item.category_id === searchQueryParamAreaStudy
          ) as any;
          setMProgramMenuData(datas);
          setMProgramValue(datas[0].value.toString());
          setAreaStudyValue(searchQueryParamAreaStudy);
          setBannerProps(prevState => ({
            ...prevState,
            selectedDegreeInterest: {
              type: degree.type,
              value: degree.value,
            },
          }));
          const path = pathArea.category_name.replace(/\s+/g, '').toLowerCase();
          const navigateToNewPath = generateQueryParamsForNavigation(
            queryParams,
            path
          );
          navigate(navigateToNewPath);
        }
        if (searchQueryParamDegreeInterest && searchQueryParamMProgram) {
          setDegreeInterestValue(searchQueryParamDegreeInterest);
          const degree = degreeInterestMenuData.find(
            (i: any) => i.value === searchQueryParamDegreeInterest
          );
          const mProgramZerothValue = parseInt(
            searchQueryParamMProgram.toString()[0]
          );
          const mProgramFirstValue = String(searchQueryParamMProgram).slice(
            0,
            2
          );
          const filterSearchQuery =
            searchQueryParamMProgram.length < 4
              ? mProgramZerothValue
              : mProgramFirstValue;
          const areaStudyData: any = areaStudyMenuData.filter(
            (areaStudy: any) =>
              String(areaStudy.category_id) === String(filterSearchQuery)
          );
          const datas = mPrograms[areaStudyData[0].category_id];
          const datasFilter = datas.filter(
            (data: any) =>
              String(data.value) === String(searchQueryParamMProgram)
          );
          setMProgramMenuData(datas);
          setAreaStudyValue(areaStudyData[0].category_id);
          setMProgramValue(datasFilter[0].value.toString());
          setBannerProps(prevState => ({
            ...prevState,
            selectedDegreeInterest: {
              type: degree.type,
              value: degree.value,
            },
            selectedMProgram: {
              text: datasFilter[0].text,
              value: datasFilter[0].value,
            },
          }));
          // navigate
          navigate(
            `/${areaStudyData[0].category_name
              .replace(/\s+/g, '')
              .toLowerCase()}/${datasFilter[0].text
              .replace(/\s+/g, '')
              .toLowerCase()}`
          );
          const path = areaStudyData[0].category_name
            .replace(/\s+/g, '')
            .toLowerCase();
          const navigateToNewPath = generateQueryParamsForNavigation(
            queryParams,
            path
          );
          navigate(navigateToNewPath);
        }
        if (searchQueryParamAreaStudy && searchQueryParamMProgram) {
          const areaStudyData: any = areaStudyMenuData.filter(
            (areaStudy: any) =>
              String(areaStudy.category_id) ===
              String(searchQueryParamAreaStudy)
          );
          let datas = mPrograms[searchQueryParamAreaStudy];
          const datasFilter = datas.filter(
            (data: any) =>
              String(data.value) === String(searchQueryParamMProgram)
          );
          setAreaStudyValue(searchQueryParamAreaStudy);
          setMProgramMenuData(datas);
          setMProgramValue(searchQueryParamMProgram);
          setBannerProps(prevState => ({
            ...prevState,
            selectedMProgram: {
              text: datasFilter[0].text,
              value: datasFilter[0].value,
            },
          }));
          const path = areaStudyData[0].category_name
            .replace(/\s+/g, '')
            .toLowerCase();
          const navigateToNewPath = generateQueryParamsForNavigation(
            queryParams,
            path
          );
          navigate(navigateToNewPath);
        }
        if (
          searchQueryParamDegreeInterest &&
          searchQueryParamAreaStudy &&
          searchQueryParamMProgram
        ) {
          const degree = degreeInterestMenuData.find(
            (i: any) => i.value === searchQueryParamDegreeInterest
          );
          const areaStudyData: any = areaStudyMenuData.filter(
            (areaStudy: any) =>
              String(areaStudy.category_id) ===
              String(searchQueryParamAreaStudy)
          );
          const datas = mPrograms[areaStudyData[0].category_id];
          const datasFilter = datas.filter(
            (data: any) =>
              String(data.value) === String(searchQueryParamMProgram)
          );
          setDegreeInterestValue(searchQueryParamDegreeInterest);
          setAreaStudyValue(searchQueryParamAreaStudy);
          setMProgramValue(searchQueryParamMProgram);
          setBannerProps(prevState => ({
            ...prevState,
            selectedDegreeInterest: {
              type: degree.type,
              value: degree.value,
            },
            selectedMProgram: {
              text: datasFilter[0].text,
              value: datasFilter[0].value,
            },
          }));
          const path = areaStudyData[0].category_name
            .replace(/\s+/g, '')
            .toLowerCase();
          const navigateToNewPath = generateQueryParamsForNavigation(
            queryParams,
            path
          );
          navigate(navigateToNewPath);
        }
        setLoading(false);
      }
    } else if (currentPath === '/criminaljustice' || id2) {
      if (areaStudyMenuData.length > 0) {
        if (id2) {
          const areaStudyData: any = areaStudyMenuData.filter(
            (areaStudy: any) =>
              areaStudy.category_name.replace(' ', '').toLowerCase() ===
              currentPath.split('/')[1].toLowerCase()
          );
          if (mPrograms[areaStudyData[0].category_id]) {
            let datas = mPrograms[areaStudyData[0].category_id];
            const datasFilter = datas.filter(
              (data: any) =>
                data.text.replaceAll(' ', '').toLocaleLowerCase() ===
                id2.replace(' ', '').toLocaleLowerCase()
            );
            setAreaStudyValue(areaStudyData[0].category_id);
            setMProgramMenuData(datas);
            setMProgramValue(datasFilter[0].value.toString());
            // setDegreeInterestValue(
            //   localStorageDegreeInterestValue
            //     ? localStorageDegreeInterestValue
            //     : ''
            // );
            if (searchParamsForListingAPI) {
              setSearchParamsForListingAPI({
                ...searchParamsForListingAPI,
                degreeInterest: localStorageDegreeInterestValue
                  ? localStorageDegreeInterestValue
                  :  degreeInterestValue?degreeInterestValue:'5',
                areaStudy: areaStudyData[0].category_id,
                mProgram: datasFilter[0].value.toString(),
              });
            } else {
            //  setDegreeInterestValue('');
            }
            setBannerProps((prevState: any) => ({
              ...prevState,
              selectedAreaStudy: areaStudyData[0],
              selectedMProgram: datasFilter[0],
              selectedDegreeInterest: !degreeInterestValue? { type: '', value: '' }:prevState?.selectedDegreeInterest,
            }));
            setSearchParamsForListingAPI((prevState: any) => ({
              ...prevState,
              ...queryParams,
            }));
          }
          setLoading(false);
        } else {
          const areaStudyData: any = areaStudyMenuData.filter(
            (areaStudy: any) =>
              areaStudy.category_name.replace(' ', '').toLowerCase() ===
              currentPath.replace('/', '').toLowerCase()
          );
          setAreaStudyValue(areaStudyData[0]?.category_id);
          setMProgramMenuData(
            mPrograms[parseInt(areaStudyData[0]?.category_id)]
          );
          // setDegreeInterestValue(
          //   localStorageDegreeInterestValue
          //     ? localStorageDegreeInterestValue
          //     : ''
          // );
          setMProgramValue('200');
          if (searchParamsForListingAPI) {
            setSearchParamsForListingAPI({
              ...searchParamsForListingAPI,
              degreeInterest: localStorageDegreeInterestValue
                ? localStorageDegreeInterestValue
                :  degreeInterestValue?degreeInterestValue:'5',
              areaStudy: areaStudyData[0]?.category_id,
              mProgram: '200',
            });
          } else {
          //  setDegreeInterestValue('');
          }
          setBannerProps((prevState: any) => ({
            ...prevState,
            selectedAreaStudy: areaStudyData[0],
            selectedMProgram: {
              text: 'General Criminal Justice',
              value: '200',
            },
            selectedDegreeInterest: !degreeInterestValue? { type: '', value: '' }:prevState?.selectedDegreeInterest,
          }));
          setSearchParamsForListingAPI((prevState: any) => ({
            ...prevState,
            ...queryParams,
          }));
        }
        if (searchQueryParamDegreeInterest) {
          const degree = degreeInterestMenuData.find(
            (i: any) => i.value === searchQueryParamDegreeInterest
          );
          setDegreeInterestValue(searchQueryParamDegreeInterest);
          setBannerProps(prevState => ({
            ...prevState,
            selectedDegreeInterest: {
              type: degree.type,
              value: degree.value,
            },
          }));
        }
        if (searchQueryParamAreaStudy) {
          let datas = mPrograms[searchQueryParamAreaStudy];
          const pathArea = areaStudyMenuData.find(
            (item: any) => item.category_id === searchQueryParamAreaStudy
          ) as any;
          setMProgramMenuData(datas);
          setMProgramValue(datas[0].value.toString());
          setAreaStudyValue(searchQueryParamAreaStudy);
          const path = pathArea.category_name.replace(/\s+/g, '').toLowerCase();
          const navigateToNewPath = generateQueryParamsForNavigation(
            queryParams,
            path
          );
          navigate(navigateToNewPath);
        }
        if (searchQueryParamMProgram) {
          const mProgramZerothValue = parseInt(
            searchQueryParamMProgram.toString()[0]
          );
          const mProgramFirstValue = String(searchQueryParamMProgram).slice(
            0,
            2
          );
          const filterSearchQuery =
            searchQueryParamMProgram.length < 4
              ? mProgramZerothValue
              : mProgramFirstValue;
          const areaStudyData: any = areaStudyMenuData.filter(
            (areaStudy: any) =>
              String(areaStudy.category_id) === String(filterSearchQuery)
          );
          const datas = mPrograms[areaStudyData[0].category_id];
          const datasFilter = datas.filter(
            (data: any) =>
              String(data.value) === String(searchQueryParamMProgram)
          );
          setMProgramMenuData(datas);
          setAreaStudyValue(areaStudyData[0].category_id);
          setMProgramValue(datasFilter[0].value.toString());
          setBannerProps(prevState => ({
            ...prevState,
            selectedMProgram: {
              text: datasFilter[0].text,
              value: datasFilter[0].value,
            },
          }));
          //navigate
          const path = `${areaStudyData[0].category_name
            .replace(/\s+/g, '')
            .toLowerCase()}/${datasFilter[0].text
            .toLowerCase()
            .replace(/\s+/g, '')
            .toLowerCase()}`;
          const navigateToNewPath = generateQueryParamsForNavigation(
            queryParams,
            path
          );
          navigate(navigateToNewPath);
        }
        if (searchQueryParamDegreeInterest && searchQueryParamAreaStudy) {
          const degree = degreeInterestMenuData.find(
            (i: any) => i.value === searchQueryParamDegreeInterest
          );
          let datas = mPrograms[searchQueryParamAreaStudy];
          const pathArea = areaStudyMenuData.find(
            (item: any) => item.category_id === searchQueryParamAreaStudy
          ) as any;
          setMProgramMenuData(datas);
          setMProgramValue(datas[0].value.toString());
          setAreaStudyValue(searchQueryParamAreaStudy);
          setBannerProps(prevState => ({
            ...prevState,
            selectedDegreeInterest: {
              type: degree.type,
              value: degree.value,
            },
          }));
          //TODO
          const path = pathArea.category_name.replace(/\s+/g, '').toLowerCase();
          const navigateToNewPath = generateQueryParamsForNavigation(
            queryParams,
            path
          );
          navigate(navigateToNewPath);
        }
        if (searchQueryParamDegreeInterest && searchQueryParamMProgram) {
          const mProgramZerothValue = parseInt(
            searchQueryParamMProgram.toString()[0]
          );
          const mProgramFirstValue = String(searchQueryParamMProgram).slice(
            0,
            2
          );
          const filterSearchQuery =
            searchQueryParamMProgram.length < 4
              ? mProgramZerothValue
              : mProgramFirstValue;
          setDegreeInterestValue(searchQueryParamDegreeInterest);
          const degree = degreeInterestMenuData.find(
            (i: any) => i.value === searchQueryParamDegreeInterest
          );
          const areaStudyData: any = areaStudyMenuData.filter(
            (areaStudy: any) =>
              String(areaStudy.category_id) === String(filterSearchQuery)
          );
          const datas = mPrograms[areaStudyData[0].category_id];
          const datasFilter = datas.filter(
            (data: any) =>
              String(data.value) === String(searchQueryParamMProgram)
          );
          setMProgramMenuData(datas);
          setAreaStudyValue(areaStudyData[0].category_id);
          setMProgramValue(datasFilter[0].value.toString());
          setBannerProps(prevState => ({
            ...prevState,
            selectedDegreeInterest: {
              type: degree.type,
              value: degree.value,
            },
            selectedMProgram: {
              text: datasFilter[0].text,
              value: datasFilter[0].value,
            },
          }));
          // navigate
          const path = `${areaStudyData[0].category_name
            .replace(/\s+/g, '')
            .toLowerCase()}/${datasFilter[0].text
            .toLowerCase()
            .replace(/\s+/g, '')
            .toLowerCase()}`;
          const navigateToNewPath = generateQueryParamsForNavigation(
            queryParams,
            path
          );
          navigate(navigateToNewPath);
        }
        if (searchQueryParamAreaStudy && searchQueryParamMProgram) {
          const areaStudyData: any = areaStudyMenuData.filter(
            (areaStudy: any) =>
              String(areaStudy.category_id) ===
              String(searchQueryParamAreaStudy)
          );
          let datas = mPrograms[searchQueryParamAreaStudy];
          const datasFilter = datas.filter(
            (data: any) =>
              String(data.value) === String(searchQueryParamMProgram)
          );
          setAreaStudyValue(searchQueryParamAreaStudy);
          setMProgramMenuData(datas);
          setMProgramValue(searchQueryParamMProgram);
          setBannerProps(prevState => ({
            ...prevState,
            selectedMProgram: {
              text: datasFilter[0].text,
              value: datasFilter[0].value,
            },
          }));
          const path = `${areaStudyData[0].category_name
            .replace(/\s+/g, '')
            .toLowerCase()}/${datasFilter[0].text
            .toLowerCase()
            .replace(/\s+/g, '')
            .toLowerCase()}`;
          const navigateToNewPath = generateQueryParamsForNavigation(
            queryParams,
            path
          );
          navigate(navigateToNewPath);
        }
        if (
          searchQueryParamDegreeInterest &&
          searchQueryParamAreaStudy &&
          searchQueryParamMProgram
        ) {
          const degree = degreeInterestMenuData.find(
            (i: any) => i.value === searchQueryParamDegreeInterest
          );
          const areaStudyData: any = areaStudyMenuData.filter(
            (areaStudy: any) =>
              String(areaStudy.category_id) ===
              String(searchQueryParamAreaStudy)
          );
          const datas = mPrograms[areaStudyData[0].category_id];
          const datasFilter = datas.filter(
            (data: any) =>
              String(data.value) === String(searchQueryParamMProgram)
          );
          setDegreeInterestValue(searchQueryParamDegreeInterest);
          setAreaStudyValue(searchQueryParamAreaStudy);
          setMProgramValue(searchQueryParamMProgram);
          setBannerProps(prevState => ({
            ...prevState,
            selectedDegreeInterest: {
              type: degree.type,
              value: degree.value,
            },
            selectedMProgram: {
              text: datasFilter[0].text,
              value: datasFilter[0].value,
            },
          }));
          const path = `${areaStudyData[0].category_name
            .replace(/\s+/g, '')
            .toLowerCase()}/${datasFilter[0].text
            .toLowerCase()
            .replace(/\s+/g, '')
            .toLowerCase()}`;
          const navigateToNewPath = generateQueryParamsForNavigation(
            queryParams,
            path
          );
          navigate(navigateToNewPath);
        }
        setLoading(false);
      }
    } else if (currentPath === '/healthcare' || id4) {
      if (areaStudyMenuData.length > 0) {
        if (id4) {
          const areaStudyData: any = areaStudyMenuData.filter(
            (areaStudy: any) =>
              areaStudy.category_name.replaceAll(' ', '').toLowerCase() ===
              currentPath.split('/')[1].toLowerCase()
          );
          if (mPrograms[areaStudyData[0].category_id]) {
            let datas = mPrograms[areaStudyData[0].category_id];
            const datasFilter = datas.filter(
              (data: any) =>
                data.text.replaceAll(' ', '').toLocaleLowerCase() ===
                id4.replace(' ', '').toLocaleLowerCase()
            );
            setAreaStudyValue(areaStudyData[0].category_id);
            setMProgramMenuData(datas);
            setMProgramValue(datasFilter[0].value.toString());
            // setDegreeInterestValue(
            //   localStorageDegreeInterestValue
            //     ? localStorageDegreeInterestValue
            //     : ''
            // );
            if (searchParamsForListingAPI) {
              setSearchParamsForListingAPI({
                ...searchParamsForListingAPI,
                degreeInterest: localStorageDegreeInterestValue
                  ? localStorageDegreeInterestValue
                  : degreeInterestValue?degreeInterestValue:'5',
                areaStudy: areaStudyData[0].category_id,
                mProgram: datasFilter[0].value.toString(),
              });
            } else {
             // setDegreeInterestValue('5');
            }
            setBannerProps((prevState: any) => ({
              ...prevState,
              selectedAreaStudy: areaStudyData[0],
              selectedMProgram: datasFilter[0],
              selectedDegreeInterest: !degreeInterestValue? { type: '', value: '' }:prevState?.selectedDegreeInterest,
            }));
            setSearchParamsForListingAPI((prevState: any) => ({
              ...prevState,
              ...queryParams,
            }));
          }
          setLoading(false);
        } else {
          // Need to area study menu data
          const areaStudyData: any = areaStudyMenuData.filter(
            (areaStudy: any) =>
              areaStudy.category_name.toLowerCase() ===
              currentPath.replace('/', '').toLowerCase()
          );
          setAreaStudyValue(areaStudyData[0]?.category_id);
          setMProgramMenuData(
            mPrograms[parseInt(areaStudyData[0]?.category_id)]
          );
          setMProgramValue('500');
          // setDegreeInterestValue(
          //   localStorageDegreeInterestValue
          //     ? localStorageDegreeInterestValue
          //     : ''
          // );
          if (searchParamsForListingAPI) {
            setSearchParamsForListingAPI({
              ...searchParamsForListingAPI,
              degreeInterest: localStorageDegreeInterestValue
                ? localStorageDegreeInterestValue
                :  degreeInterestValue?degreeInterestValue:'5',
              areaStudy: areaStudyData[0]?.category_id,
              mProgram: '500',
            });
          } else {
           // setDegreeInterestValue('');
          }
          setBannerProps((prevState: any) => ({
            ...prevState,
            selectedAreaStudy: areaStudyData[0],
            selectedMProgram: { text: 'Medical Assisting', value: '500' },
            selectedDegreeInterest: !degreeInterestValue? { type: '', value: '' }:prevState?.selectedDegreeInterest,
          }));
          setSearchParamsForListingAPI((prevState: any) => ({
            ...prevState,
            ...queryParams,
          }));
        }
        if (searchQueryParamDegreeInterest) {
          const degree = degreeInterestMenuData.find(
            (i: any) => i.value === searchQueryParamDegreeInterest
          );
          setDegreeInterestValue(searchQueryParamDegreeInterest);
          setBannerProps(prevState => ({
            ...prevState,
            selectedDegreeInterest: {
              type: degree.type,
              value: degree.value,
            },
          }));
        }
        if (searchQueryParamAreaStudy) {
          let datas = mPrograms[searchQueryParamAreaStudy];
          const pathArea = areaStudyMenuData.find(
            (item: any) => item.category_id === searchQueryParamAreaStudy
          ) as any;
          setMProgramMenuData(datas);
          setMProgramValue(datas[0].value.toString());
          setAreaStudyValue(searchQueryParamAreaStudy);
          const path = pathArea.category_name.replace(/\s+/g, '').toLowerCase();
          const navigateToNewPath = generateQueryParamsForNavigation(
            queryParams,
            path
          );
          navigate(navigateToNewPath);
        }
        if (searchQueryParamMProgram) {
          const mProgramZerothValue = parseInt(
            searchQueryParamMProgram.toString()[0]
          );
          const mProgramFirstValue = String(searchQueryParamMProgram).slice(
            0,
            2
          );
          const filterSearchQuery =
            searchQueryParamMProgram.length < 4
              ? mProgramZerothValue
              : mProgramFirstValue;
          const areaStudyData: any = areaStudyMenuData.filter(
            (areaStudy: any) =>
              String(areaStudy.category_id) === String(filterSearchQuery)
          );
          const datas = mPrograms[areaStudyData[0].category_id];
          const datasFilter = datas.filter(
            (data: any) =>
              String(data.value) === String(searchQueryParamMProgram)
          );
          setMProgramMenuData(datas);
          setAreaStudyValue(areaStudyData[0].category_id);
          setMProgramValue(datasFilter[0].value.toString());
          setBannerProps(prevState => ({
            ...prevState,
            selectedMProgram: {
              text: datasFilter[0].text,
              value: datasFilter[0].value,
            },
          }));
          //navigate
          const path = `${areaStudyData[0].category_name
            .replace(/\s+/g, '')
            .toLowerCase()}/${datasFilter[0].text
            .toLowerCase()
            .replace(/\s+/g, '')
            .toLowerCase()}`;
          const navigateToNewPath = generateQueryParamsForNavigation(
            queryParams,
            path
          );
          navigate(navigateToNewPath);
        }
        if (searchQueryParamDegreeInterest && searchQueryParamAreaStudy) {
          const degree = degreeInterestMenuData.find(
            (i: any) => i.value === searchQueryParamDegreeInterest
          );
          let datas = mPrograms[searchQueryParamAreaStudy];
          const pathArea = areaStudyMenuData.find(
            (item: any) => item.category_id === searchQueryParamAreaStudy
          ) as any;
          setMProgramMenuData(datas);
          setMProgramValue(datas[0].value.toString());
          setAreaStudyValue(searchQueryParamAreaStudy);
          setBannerProps(prevState => ({
            ...prevState,
            selectedDegreeInterest: {
              type: degree.type,
              value: degree.value,
            },
          }));
          const path = pathArea.category_name.replace(/\s+/g, '').toLowerCase();
          const navigateToNewPath = generateQueryParamsForNavigation(
            queryParams,
            path
          );
          navigate(navigateToNewPath);
        }
        if (searchQueryParamDegreeInterest && searchQueryParamMProgram) {
          const mProgramZerothValue = parseInt(
            searchQueryParamMProgram.toString()[0]
          );
          const mProgramFirstValue = String(searchQueryParamMProgram).slice(
            0,
            2
          );
          const filterSearchQuery =
            searchQueryParamMProgram.length < 4
              ? mProgramZerothValue
              : mProgramFirstValue;
          setDegreeInterestValue(searchQueryParamDegreeInterest);
          const degree = degreeInterestMenuData.find(
            (i: any) => i.value === searchQueryParamDegreeInterest
          );
          const areaStudyData: any = areaStudyMenuData.filter(
            (areaStudy: any) =>
              String(areaStudy.category_id) === String(filterSearchQuery)
          );
          const datas = mPrograms[areaStudyData[0].category_id];
          const datasFilter = datas.filter(
            (data: any) =>
              String(data.value) === String(searchQueryParamMProgram)
          );
          setMProgramMenuData(datas);
          setAreaStudyValue(areaStudyData[0].category_id);
          setMProgramValue(datasFilter[0].value.toString());
          setBannerProps(prevState => ({
            ...prevState,
            selectedDegreeInterest: {
              type: degree.type,
              value: degree.value,
            },
            selectedMProgram: {
              text: datasFilter[0].text,
              value: datasFilter[0].value,
            },
          }));
          // navigate
          const path = `${areaStudyData[0].category_name
            .replace(/\s+/g, '')
            .toLowerCase()}/${datasFilter[0].text
            .toLowerCase()
            .replace(/\s+/g, '')
            .toLowerCase()}`;
          const navigateToNewPath = generateQueryParamsForNavigation(
            queryParams,
            path
          );
          navigate(navigateToNewPath);
        }
        if (searchQueryParamAreaStudy && searchQueryParamMProgram) {
          const areaStudyData: any = areaStudyMenuData.filter(
            (areaStudy: any) =>
              String(areaStudy.category_id) ===
              String(searchQueryParamAreaStudy)
          );
          let datas = mPrograms[searchQueryParamAreaStudy];
          const datasFilter = datas.filter(
            (data: any) =>
              String(data.value) === String(searchQueryParamMProgram)
          );
          setAreaStudyValue(searchQueryParamAreaStudy);
          setMProgramMenuData(datas);
          setMProgramValue(searchQueryParamMProgram);
          setBannerProps(prevState => ({
            ...prevState,
            selectedMProgram: {
              text: datasFilter[0].text,
              value: datasFilter[0].value,
            },
          }));
          const path = `${areaStudyData[0].category_name
            .replace(/\s+/g, '')
            .toLowerCase()}/${datasFilter[0].text
            .toLowerCase()
            .replace(/\s+/g, '')
            .toLowerCase()}`;
          const navigateToNewPath = generateQueryParamsForNavigation(
            queryParams,
            path
          );
          navigate(navigateToNewPath);
        }
        if (
          searchQueryParamDegreeInterest &&
          searchQueryParamAreaStudy &&
          searchQueryParamMProgram
        ) {
          const degree = degreeInterestMenuData.find(
            (i: any) => i.value === searchQueryParamDegreeInterest
          );
          const areaStudyData: any = areaStudyMenuData.filter(
            (areaStudy: any) =>
              String(areaStudy.category_id) ===
              String(searchQueryParamAreaStudy)
          );
          const datas = mPrograms[areaStudyData[0].category_id];
          const datasFilter = datas.filter(
            (data: any) =>
              String(data.value) === String(searchQueryParamMProgram)
          );
          setDegreeInterestValue(searchQueryParamDegreeInterest);
          setAreaStudyValue(searchQueryParamAreaStudy);
          setMProgramValue(searchQueryParamMProgram);
          setBannerProps(prevState => ({
            ...prevState,
            selectedDegreeInterest: {
              type: degree.type,
              value: degree.value,
            },
            selectedMProgram: {
              text: datasFilter[0].text,
              value: datasFilter[0].value,
            },
          }));
          const path = `${areaStudyData[0].category_name
            .replace(/\s+/g, '')
            .toLowerCase()}/${datasFilter[0].text
            .toLowerCase()
            .replace(/\s+/g, '')
            .toLowerCase()}`;
          const navigateToNewPath = generateQueryParamsForNavigation(
            queryParams,
            path
          );
          navigate(navigateToNewPath);
        }
        setLoading(false);
      }
    } else if (currentPath === '/computers&it' || id1) {
      if (areaStudyMenuData.length > 0) {
        if (id1) {
          const areaStudyData: any = areaStudyMenuData.filter(
            (areaStudy: any) =>
              areaStudy.category_name.replaceAll(' ', '').toLowerCase() ===
              currentPath.split('/')[1].toLowerCase()
          );
          if (mPrograms[areaStudyData[0].category_id]) {
            let datas = mPrograms[areaStudyData[0].category_id];
            const datasFilter = datas.filter(
              (data: any) =>
                data.text.replaceAll(' ', '').toLocaleLowerCase() ===
                id1.replace(' ', '').toLocaleLowerCase()
            );
            setAreaStudyValue(areaStudyData[0].category_id);
            setMProgramMenuData(datas);
            setMProgramValue(datasFilter[0].value.toString());
            // setDegreeInterestValue(
            //   localStorageDegreeInterestValue
            //     ? localStorageDegreeInterestValue
            //     : ''
            // );
            if (searchParamsForListingAPI) {
              setSearchParamsForListingAPI({
                ...searchParamsForListingAPI,
                degreeInterest: localStorageDegreeInterestValue
                  ? localStorageDegreeInterestValue
                  :  degreeInterestValue?degreeInterestValue:'5',
                areaStudy: areaStudyData[0].category_id,
                mProgram: datasFilter[0].value.toString(),
              });
            } else {
             // setDegreeInterestValue('');
            }
            setBannerProps((prevState: any) => ({
              ...prevState,
              selectedAreaStudy: areaStudyData[0],
              selectedMProgram: datasFilter[0],
              selectedDegreeInterest: !degreeInterestValue? { type: '', value: '' }:prevState?.selectedDegreeInterest,
            }));
            setSearchParamsForListingAPI((prevState: any) => ({
              ...prevState,
              ...queryParams,
            }));
          }
          setLoading(false);
        } else {
          // Need to area study menu data
          const areaStudyData: any = areaStudyMenuData.filter(
            (areaStudy: any) =>
              areaStudy.category_name.replaceAll(' ', '').toLowerCase() ===
              currentPath.replace('/', '').toLowerCase()
          );
          setAreaStudyValue(areaStudyData[0]?.category_id);
          setMProgramMenuData(
            mPrograms[parseInt(areaStudyData[0]?.category_id)]
          );
          setMProgramValue('400');
          // setDegreeInterestValue(
          //   localStorageDegreeInterestValue
          //     ? localStorageDegreeInterestValue
          //     : ''
          // );
          if (searchParamsForListingAPI) {
            setSearchParamsForListingAPI({
              ...searchParamsForListingAPI,
              degreeInterest: localStorageDegreeInterestValue
                ? localStorageDegreeInterestValue
                :  degreeInterestValue?degreeInterestValue:'5',
              areaStudy: areaStudyData[0]?.category_id,
              mProgram: '400',
            });
          } else {
           // setDegreeInterestValue('');
          }
          setBannerProps((prevState: any) => ({
            ...prevState,
            selectedAreaStudy: areaStudyData[0],
            selectedMProgram: { text: 'General Computers & IT', value: '400' },
            selectedDegreeInterest: !degreeInterestValue? { type: '', value: '' }:prevState?.selectedDegreeInterest,
          }));
          setSearchParamsForListingAPI((prevState: any) => ({
            ...prevState,
            ...queryParams,
          }));
        }
        if (searchQueryParamDegreeInterest) {
          const degree = degreeInterestMenuData.find(
            (i: any) => i.value === searchQueryParamDegreeInterest
          );
          setDegreeInterestValue(searchQueryParamDegreeInterest);
          setBannerProps(prevState => ({
            ...prevState,
            selectedDegreeInterest: {
              type: degree.type,
              value: degree.value,
            },
          }));
        }
        if (searchQueryParamAreaStudy) {
          let datas = mPrograms[searchQueryParamAreaStudy];
          const pathArea = areaStudyMenuData.find(
            (item: any) => item.category_id === searchQueryParamAreaStudy
          ) as any;
          setMProgramMenuData(datas);
          setMProgramValue(datas[0].value.toString());
          setAreaStudyValue(searchQueryParamAreaStudy);
          const path = pathArea.category_name.replace(/\s+/g, '').toLowerCase();
          const navigateToNewPath = generateQueryParamsForNavigation(
            queryParams,
            path
          );
          navigate(navigateToNewPath);
        }
        if (searchQueryParamMProgram) {
          const mProgramZerothValue = parseInt(
            searchQueryParamMProgram.toString()[0]
          );
          const mProgramFirstValue = String(searchQueryParamMProgram).slice(
            0,
            2
          );
          const filterSearchQuery =
            searchQueryParamMProgram.length < 4
              ? mProgramZerothValue
              : mProgramFirstValue;
          const areaStudyData: any = areaStudyMenuData.filter(
            (areaStudy: any) =>
              String(areaStudy.category_id) === String(filterSearchQuery)
          );
          const datas = mPrograms[areaStudyData[0].category_id];
          const datasFilter = datas.filter(
            (data: any) =>
              String(data.value) === String(searchQueryParamMProgram)
          );
          setMProgramMenuData(datas);
          setAreaStudyValue(areaStudyData[0].category_id);
          setMProgramValue(datasFilter[0].value.toString());
          setBannerProps(prevState => ({
            ...prevState,
            selectedMProgram: {
              text: datasFilter[0].text,
              value: datasFilter[0].value,
            },
          }));
          //navigate
          const path = `${areaStudyData[0].category_name
            .replace(/\s+/g, '')
            .toLowerCase()}/${datasFilter[0].text
            .toLowerCase()
            .replace(/\s+/g, '')
            .toLowerCase()}`;
          const navigateToNewPath = generateQueryParamsForNavigation(
            queryParams,
            path
          );
          navigate(navigateToNewPath);
        }
        if (searchQueryParamDegreeInterest && searchQueryParamAreaStudy) {
          const degree = degreeInterestMenuData.find(
            (i: any) => i.value === searchQueryParamDegreeInterest
          );
          let datas = mPrograms[searchQueryParamAreaStudy];
          const pathArea = areaStudyMenuData.find(
            (item: any) => item.category_id === searchQueryParamAreaStudy
          ) as any;
          setMProgramMenuData(datas);
          setMProgramValue(datas[0].value.toString());
          setAreaStudyValue(searchQueryParamAreaStudy);
          setBannerProps(prevState => ({
            ...prevState,
            selectedDegreeInterest: {
              type: degree.type,
              value: degree.value,
            },
          }));
          const path = pathArea.category_name.replace(/\s+/g, '').toLowerCase();
          const navigateToNewPath = generateQueryParamsForNavigation(
            queryParams,
            path
          );
          navigate(navigateToNewPath);
        }
        if (searchQueryParamDegreeInterest && searchQueryParamMProgram) {
          const mProgramZerothValue = parseInt(
            searchQueryParamMProgram.toString()[0]
          );
          const mProgramFirstValue = String(searchQueryParamMProgram).slice(
            0,
            2
          );
          const filterSearchQuery =
            searchQueryParamMProgram.length < 4
              ? mProgramZerothValue
              : mProgramFirstValue;
          setDegreeInterestValue(searchQueryParamDegreeInterest);
          const degree = degreeInterestMenuData.find(
            (i: any) => i.value === searchQueryParamDegreeInterest
          );
          const areaStudyData: any = areaStudyMenuData.filter(
            (areaStudy: any) =>
              String(areaStudy.category_id) === String(mProgramZerothValue)
          );
          const datas = mPrograms[areaStudyData[0].category_id];
          const datasFilter = datas.filter(
            (data: any) =>
              String(data.value) === String(searchQueryParamMProgram)
          );
          setMProgramMenuData(datas);
          setAreaStudyValue(areaStudyData[0].category_id);
          setMProgramValue(datasFilter[0].value.toString());
          setBannerProps(prevState => ({
            ...prevState,
            selectedDegreeInterest: {
              type: degree.type,
              value: degree.value,
            },
            selectedMProgram: {
              text: datasFilter[0].text,
              value: datasFilter[0].value,
            },
          }));
          // navigate
          const path = `${areaStudyData[0].category_name
            .replace(/\s+/g, '')
            .toLowerCase()}/${datasFilter[0].text
            .toLowerCase()
            .replace(/\s+/g, '')
            .toLowerCase()}`;
          const navigateToNewPath = generateQueryParamsForNavigation(
            queryParams,
            path
          );
          navigate(navigateToNewPath);
        }
        if (searchQueryParamAreaStudy && searchQueryParamMProgram) {
          const areaStudyData: any = areaStudyMenuData.filter(
            (areaStudy: any) =>
              String(areaStudy.category_id) ===
              String(searchQueryParamAreaStudy)
          );
          let datas = mPrograms[searchQueryParamAreaStudy];
          const datasFilter = datas.filter(
            (data: any) =>
              String(data.value) === String(searchQueryParamMProgram)
          );
          setAreaStudyValue(searchQueryParamAreaStudy);
          setMProgramMenuData(datas);
          setMProgramValue(searchQueryParamMProgram);
          setBannerProps(prevState => ({
            ...prevState,
            selectedMProgram: {
              text: datasFilter[0].text,
              value: datasFilter[0].value,
            },
          }));
          const path = `${areaStudyData[0].category_name
            .replace(/\s+/g, '')
            .toLowerCase()}/${datasFilter[0].text
            .toLowerCase()
            .replace(/\s+/g, '')
            .toLowerCase()}`;
          const navigateToNewPath = generateQueryParamsForNavigation(
            queryParams,
            path
          );
          navigate(navigateToNewPath);
        }
        if (
          searchQueryParamDegreeInterest &&
          searchQueryParamAreaStudy &&
          searchQueryParamMProgram
        ) {
          const degree = degreeInterestMenuData.find(
            (i: any) => i.value === searchQueryParamDegreeInterest
          );
          const areaStudyData: any = areaStudyMenuData.filter(
            (areaStudy: any) =>
              String(areaStudy.category_id) ===
              String(searchQueryParamAreaStudy)
          );
          const datas = mPrograms[areaStudyData[0].category_id];
          const datasFilter = datas.filter(
            (data: any) =>
              String(data.value) === String(searchQueryParamMProgram)
          );
          setDegreeInterestValue(searchQueryParamDegreeInterest);
          setAreaStudyValue(searchQueryParamAreaStudy);
          setMProgramValue(searchQueryParamMProgram);
          setBannerProps(prevState => ({
            ...prevState,
            selectedDegreeInterest: {
              type: degree.type,
              value: degree.value,
            },
            selectedMProgram: {
              text: datasFilter[0].text,
              value: datasFilter[0].value,
            },
          }));
          const path = `${areaStudyData[0].category_name
            .replace(/\s+/g, '')
            .toLowerCase()}/${datasFilter[0].text
            .toLowerCase()
            .replace(/\s+/g, '')
            .toLowerCase()}`;
          const navigateToNewPath = generateQueryParamsForNavigation(
            queryParams,
            path
          );
          navigate(navigateToNewPath);
        }
        setLoading(false);
      }
    } else if (currentPath === '/education&teaching' || id3) {
      if (areaStudyMenuData.length > 0) {
        if (id3) {
          const areaStudyData: any = areaStudyMenuData.filter(
            (areaStudy: any) =>
              areaStudy.category_name.replaceAll(' ', '').toLowerCase() ===
              currentPath.split('/')[1].toLowerCase()
          );
          if (mPrograms[areaStudyData[0].category_id]) {
            let datas = mPrograms[areaStudyData[0].category_id];
            const datasFilter = datas.filter(
              (data: any) =>
                data.text.replaceAll(' ', '').toLocaleLowerCase() ===
                id3.replace(' ', '').toLocaleLowerCase()
            );
            setAreaStudyValue(areaStudyData[0].category_id);
            setMProgramMenuData(datas);
            setMProgramValue(datasFilter[0].value.toString());
            // setDegreeInterestValue(
            //   localStorageDegreeInterestValue
            //     ? localStorageDegreeInterestValue
            //     : ''
            // );
            if (searchParamsForListingAPI) {
              setSearchParamsForListingAPI({
                ...searchParamsForListingAPI,
                degreeInterest: localStorageDegreeInterestValue
                  ? localStorageDegreeInterestValue
                  :  degreeInterestValue?degreeInterestValue:'5',
                areaStudy: areaStudyData[0].category_id,
                mProgram: datasFilter[0].value.toString(),
              });
            } else {
            //  setDegreeInterestValue('');
            }
            setBannerProps((prevState: any) => ({
              ...prevState,
              selectedAreaStudy: areaStudyData[0],
              selectedMProgram: datasFilter[0],
              selectedDegreeInterest: !degreeInterestValue? { type: '', value: '' }:prevState?.selectedDegreeInterest,
            }));
            setSearchParamsForListingAPI((prevState: any) => ({
              ...prevState,
              ...queryParams,
            }));
          }
          setLoading(false);
        } else {
          // Need to area study menu data
          const areaStudyData: any = areaStudyMenuData.filter(
            (areaStudy: any) =>
              areaStudy.category_name.replaceAll(' ', '').toLowerCase() ===
              currentPath.replace('/', '').toLowerCase()
          );
          setAreaStudyValue(areaStudyData[0]?.category_id);
          setMProgramMenuData(
            mPrograms[parseInt(areaStudyData[0]?.category_id)]
          );
          setMProgramValue('600');
          // setDegreeInterestValue(
          //   localStorageDegreeInterestValue
          //     ? localStorageDegreeInterestValue
          //     : ''
          // );
          if (searchParamsForListingAPI) {
            setSearchParamsForListingAPI({
              ...searchParamsForListingAPI,
              degreeInterest: localStorageDegreeInterestValue
                ? localStorageDegreeInterestValue
                :  degreeInterestValue?degreeInterestValue:'5',
              areaStudy: areaStudyData[0]?.category_id,
              mProgram: '600',
            });
          } else {
           // setDegreeInterestValue('');
          }
          setBannerProps((prevState: any) => ({
            ...prevState,
            selectedAreaStudy: areaStudyData[0],
            selectedMProgram: { text: 'General Education', value: '600' },
            selectedDegreeInterest: !degreeInterestValue? { type: '', value: '' }:prevState?.selectedDegreeInterest,
          }));
          setSearchParamsForListingAPI((prevState: any) => ({
            ...prevState,
            ...queryParams,
          }));
        }
        if (searchQueryParamDegreeInterest) {
          const degree = degreeInterestMenuData.find(
            (i: any) => i.value === searchQueryParamDegreeInterest
          );
          setDegreeInterestValue(searchQueryParamDegreeInterest);
          setBannerProps(prevState => ({
            ...prevState,
            selectedDegreeInterest: {
              type: degree.type,
              value: degree.value,
            },
          }));
        }
        if (searchQueryParamAreaStudy) {
          let datas = mPrograms[searchQueryParamAreaStudy];
          const pathArea = areaStudyMenuData.find(
            (item: any) => item.category_id === searchQueryParamAreaStudy
          ) as any;
          setMProgramMenuData(datas);
          setMProgramValue(datas[0].value.toString());
          setAreaStudyValue(searchQueryParamAreaStudy);
          const path = pathArea.category_name.replace(/\s+/g, '').toLowerCase();
          const navigateToNewPath = generateQueryParamsForNavigation(
            queryParams,
            path
          );
          navigate(navigateToNewPath);
        }
        if (searchQueryParamMProgram) {
          const mProgramZerothValue = parseInt(
            searchQueryParamMProgram.toString()[0]
          );
          const mProgramFirstValue = String(searchQueryParamMProgram).slice(
            0,
            2
          );
          const filterSearchQuery =
            searchQueryParamMProgram.length < 4
              ? mProgramZerothValue
              : mProgramFirstValue;
          const areaStudyData: any = areaStudyMenuData.filter(
            (areaStudy: any) =>
              String(areaStudy.category_id) === String(filterSearchQuery)
          );
          const datas = mPrograms[areaStudyData[0].category_id];
          const datasFilter = datas.filter(
            (data: any) =>
              String(data.value) === String(searchQueryParamMProgram)
          );
          setMProgramMenuData(datas);
          setAreaStudyValue(areaStudyData[0].category_id);
          setMProgramValue(datasFilter[0].value.toString());
          setBannerProps(prevState => ({
            ...prevState,
            selectedMProgram: {
              text: datasFilter[0].text,
              value: datasFilter[0].value,
            },
          }));
          //navigate
          const path = `${areaStudyData[0].category_name
            .replace(/\s+/g, '')
            .toLowerCase()}/${datasFilter[0].text
            .toLowerCase()
            .replace(/\s+/g, '')
            .toLowerCase()}`;
          const navigateToNewPath = generateQueryParamsForNavigation(
            queryParams,
            path
          );
          navigate(navigateToNewPath);
        }
        if (searchQueryParamDegreeInterest && searchQueryParamAreaStudy) {
          const degree = degreeInterestMenuData.find(
            (i: any) => i.value === searchQueryParamDegreeInterest
          );
          let datas = mPrograms[searchQueryParamAreaStudy];
          const pathArea = areaStudyMenuData.find(
            (item: any) => item.category_id === searchQueryParamAreaStudy
          ) as any;
          setMProgramMenuData(datas);
          setMProgramValue(datas[0].value.toString());
          setAreaStudyValue(searchQueryParamAreaStudy);
          setBannerProps(prevState => ({
            ...prevState,
            selectedDegreeInterest: {
              type: degree.type,
              value: degree.value,
            },
          }));
          const path = pathArea.category_name.replace(/\s+/g, '').toLowerCase();
          const navigateToNewPath = generateQueryParamsForNavigation(
            queryParams,
            path
          );
          navigate(navigateToNewPath);
        }
        if (searchQueryParamDegreeInterest && searchQueryParamMProgram) {
          const mProgramZerothValue = parseInt(
            searchQueryParamMProgram.toString()[0]
          );
          const mProgramFirstValue = String(searchQueryParamMProgram).slice(
            0,
            2
          );
          const filterSearchQuery =
            searchQueryParamMProgram.length < 4
              ? mProgramZerothValue
              : mProgramFirstValue;
          setDegreeInterestValue(searchQueryParamDegreeInterest);
          const degree = degreeInterestMenuData.find(
            (i: any) => i.value === searchQueryParamDegreeInterest
          );
          const areaStudyData: any = areaStudyMenuData.filter(
            (areaStudy: any) =>
              String(areaStudy.category_id) === String(filterSearchQuery)
          );
          const datas = mPrograms[areaStudyData[0].category_id];
          const datasFilter = datas.filter(
            (data: any) =>
              String(data.value) === String(searchQueryParamMProgram)
          );
          setMProgramMenuData(datas);
          setAreaStudyValue(areaStudyData[0].category_id);
          setMProgramValue(datasFilter[0].value.toString());
          setBannerProps(prevState => ({
            ...prevState,
            selectedDegreeInterest: {
              type: degree.type,
              value: degree.value,
            },
            selectedMProgram: {
              text: datasFilter[0].text,
              value: datasFilter[0].value,
            },
          }));
          // navigate
          const path = `${areaStudyData[0].category_name
            .replace(/\s+/g, '')
            .toLowerCase()}/${datasFilter[0].text
            .toLowerCase()
            .replace(/\s+/g, '')
            .toLowerCase()}`;
          const navigateToNewPath = generateQueryParamsForNavigation(
            queryParams,
            path
          );
          navigate(navigateToNewPath);
        }
        if (searchQueryParamAreaStudy && searchQueryParamMProgram) {
          const areaStudyData: any = areaStudyMenuData.filter(
            (areaStudy: any) =>
              String(areaStudy.category_id) ===
              String(searchQueryParamAreaStudy)
          );
          let datas = mPrograms[searchQueryParamAreaStudy];
          const datasFilter = datas.filter(
            (data: any) =>
              String(data.value) === String(searchQueryParamMProgram)
          );
          setAreaStudyValue(searchQueryParamAreaStudy);
          setMProgramMenuData(datas);
          setMProgramValue(searchQueryParamMProgram);
          setBannerProps(prevState => ({
            ...prevState,
            selectedMProgram: {
              text: datasFilter[0].text,
              value: datasFilter[0].value,
            },
          }));
          const path = `${areaStudyData[0].category_name
            .replace(/\s+/g, '')
            .toLowerCase()}/${datasFilter[0].text
            .toLowerCase()
            .replace(/\s+/g, '')
            .toLowerCase()}`;
          const navigateToNewPath = generateQueryParamsForNavigation(
            queryParams,
            path
          );
          navigate(navigateToNewPath);
        }
        if (
          searchQueryParamDegreeInterest &&
          searchQueryParamAreaStudy &&
          searchQueryParamMProgram
        ) {
          const degree = degreeInterestMenuData.find(
            (i: any) => i.value === searchQueryParamDegreeInterest
          );
          const areaStudyData: any = areaStudyMenuData.filter(
            (areaStudy: any) =>
              String(areaStudy.category_id) ===
              String(searchQueryParamAreaStudy)
          );
          const datas = mPrograms[areaStudyData[0].category_id];
          const datasFilter = datas.filter(
            (data: any) =>
              String(data.value) === String(searchQueryParamMProgram)
          );
          setDegreeInterestValue(searchQueryParamDegreeInterest);
          setAreaStudyValue(searchQueryParamAreaStudy);
          setMProgramValue(searchQueryParamMProgram);
          setBannerProps(prevState => ({
            ...prevState,
            selectedDegreeInterest: {
              type: degree.type,
              value: degree.value,
            },
            selectedMProgram: {
              text: datasFilter[0].text,
              value: datasFilter[0].value,
            },
          }));
          const path = `${areaStudyData[0].category_name
            .replace(/\s+/g, '')
            .toLowerCase()}/${datasFilter[0].text
            .toLowerCase()
            .replace(/\s+/g, '')
            .toLowerCase()}`;
          const navigateToNewPath = generateQueryParamsForNavigation(
            queryParams,
            path
          );
          navigate(navigateToNewPath);
        }
        setLoading(false);
      }
    } else if (currentPath === '/psychology' || id5) {
      if (areaStudyMenuData.length > 0) {
        if (id5) {
          const areaStudyData: any = areaStudyMenuData.filter(
            (areaStudy: any) =>
              areaStudy.category_name.toLowerCase() ===
              currentPath.split('/')[1].toLowerCase()
          );
          if (mPrograms[areaStudyData[0].category_id]) {
            let datas = mPrograms[areaStudyData[0].category_id];
            const datasFilter = datas.filter(
              (data: any) =>
                data.text.replaceAll(' ', '').toLocaleLowerCase() ===
                id5.replace(' ', '').toLocaleLowerCase()
            );
            setAreaStudyValue(areaStudyData[0].category_id);
            setMProgramMenuData(datas);
            setMProgramValue(datasFilter[0].value.toString());
            // setDegreeInterestValue(
            //   localStorageDegreeInterestValue
            //     ? localStorageDegreeInterestValue
            //     : ''
            // );
            if (searchParamsForListingAPI) {
              setSearchParamsForListingAPI({
                ...searchParamsForListingAPI,
                degreeInterest: localStorageDegreeInterestValue
                  ? localStorageDegreeInterestValue
                  :  degreeInterestValue?degreeInterestValue:'5',
                areaStudy: areaStudyData[0].category_id,
                mProgram: datasFilter[0].value.toString(),
              });
            } else {
             // setDegreeInterestValue('');
            }
            setBannerProps((prevState: any) => ({
              ...prevState,
              selectedAreaStudy: areaStudyData[0],
              selectedMProgram: datasFilter[0],
              selectedDegreeInterest: !degreeInterestValue? { type: '', value: '' }:prevState?.selectedDegreeInterest,
            }));
            setSearchParamsForListingAPI((prevState: any) => ({
              ...prevState,
              ...queryParams,
            }));
          }
          setLoading(false);
        } else {
          // Need to area study menu data
          const areaStudyData: any = areaStudyMenuData.filter(
            (areaStudy: any) =>
              areaStudy.category_name.toLowerCase() ===
              currentPath.replace('/', '').toLowerCase()
          );
          setAreaStudyValue(areaStudyData[0]?.category_id);
          setMProgramMenuData(
            mPrograms[parseInt(areaStudyData[0]?.category_id)]
          );
          setMProgramValue('300');
          // setDegreeInterestValue(
          //   localStorageDegreeInterestValue
          //     ? localStorageDegreeInterestValue
          //     : ''
          // );
          if (searchParamsForListingAPI) {
            setSearchParamsForListingAPI({
              ...searchParamsForListingAPI,
              degreeInterest: localStorageDegreeInterestValue
                ? localStorageDegreeInterestValue
                :  degreeInterestValue?degreeInterestValue:'5',
              areaStudy: areaStudyData[0]?.category_id,
              mProgram: '300',
            });
          } else {
          //  setDegreeInterestValue('');
          }
          setBannerProps((prevState: any) => ({
            ...prevState,
            selectedAreaStudy: areaStudyData[0],
            selectedMProgram: { text: 'General Psychology', value: '300' },
            selectedDegreeInterest: !degreeInterestValue? { type: '', value: '' }:prevState?.selectedDegreeInterest,
          }));
          setSearchParamsForListingAPI((prevState: any) => ({
            ...prevState,
            ...queryParams,
          }));
        }
        if (searchQueryParamDegreeInterest) {
          const degree = degreeInterestMenuData.find(
            (i: any) => i.value === searchQueryParamDegreeInterest
          );
          setDegreeInterestValue(searchQueryParamDegreeInterest);
          setBannerProps(prevState => ({
            ...prevState,
            selectedDegreeInterest: {
              type: degree.type,
              value: degree.value,
            },
          }));
        }
        if (searchQueryParamAreaStudy) {
          let datas = mPrograms[searchQueryParamAreaStudy];
          const pathArea = areaStudyMenuData.find(
            (item: any) => item.category_id === searchQueryParamAreaStudy
          ) as any;
          setMProgramMenuData(datas);
          setMProgramValue(datas[0].value.toString());
          setAreaStudyValue(searchQueryParamAreaStudy);
          const path = pathArea.category_name.replace(/\s+/g, '').toLowerCase();
          const navigateToNewPath = generateQueryParamsForNavigation(
            queryParams,
            path
          );
          navigate(navigateToNewPath);
        }
        if (searchQueryParamMProgram) {
          const mProgramZerothValue = parseInt(
            searchQueryParamMProgram.toString()[0]
          );
          const mProgramFirstValue = String(searchQueryParamMProgram).slice(
            0,
            2
          );
          const filterSearchQuery =
            searchQueryParamMProgram.length < 4
              ? mProgramZerothValue
              : mProgramFirstValue;
          const areaStudyData: any = areaStudyMenuData.filter(
            (areaStudy: any) =>
              String(areaStudy.category_id) === String(filterSearchQuery)
          );
          const datas = mPrograms[areaStudyData[0].category_id];
          const datasFilter = datas.filter(
            (data: any) =>
              String(data.value) === String(searchQueryParamMProgram)
          );
          setMProgramMenuData(datas);
          setAreaStudyValue(areaStudyData[0].category_id);
          setMProgramValue(datasFilter[0].value.toString());
          setBannerProps(prevState => ({
            ...prevState,
            selectedMProgram: {
              text: datasFilter[0].text,
              value: datasFilter[0].value,
            },
          }));
          //navigate
          const path = `${areaStudyData[0].category_name
            .replace(/\s+/g, '')
            .toLowerCase()}/${datasFilter[0].text
            .toLowerCase()
            .replace(/\s+/g, '')
            .toLowerCase()}`;
          const navigateToNewPath = generateQueryParamsForNavigation(
            queryParams,
            path
          );
          navigate(navigateToNewPath);
        }
        if (searchQueryParamDegreeInterest && searchQueryParamAreaStudy) {
          const degree = degreeInterestMenuData.find(
            (i: any) => i.value === searchQueryParamDegreeInterest
          );
          let datas = mPrograms[searchQueryParamAreaStudy];
          const pathArea = areaStudyMenuData.find(
            (item: any) => item.category_id === searchQueryParamAreaStudy
          ) as any;
          setMProgramMenuData(datas);
          setMProgramValue(datas[0].value.toString());
          setAreaStudyValue(searchQueryParamAreaStudy);
          setBannerProps(prevState => ({
            ...prevState,
            selectedDegreeInterest: {
              type: degree.type,
              value: degree.value,
            },
          }));
          const path = pathArea.category_name.replace(/\s+/g, '').toLowerCase();
          const navigateToNewPath = generateQueryParamsForNavigation(
            queryParams,
            path
          );
          navigate(navigateToNewPath);
        }
        if (searchQueryParamDegreeInterest && searchQueryParamMProgram) {
          const mProgramZerothValue = parseInt(
            searchQueryParamMProgram.toString()[0]
          );
          const mProgramFirstValue = String(searchQueryParamMProgram).slice(
            0,
            2
          );
          const filterSearchQuery =
            searchQueryParamMProgram.length < 4
              ? mProgramZerothValue
              : mProgramFirstValue;
          setDegreeInterestValue(searchQueryParamDegreeInterest);
          const degree = degreeInterestMenuData.find(
            (i: any) => i.value === searchQueryParamDegreeInterest
          );
          const areaStudyData: any = areaStudyMenuData.filter(
            (areaStudy: any) =>
              String(areaStudy.category_id) === String(filterSearchQuery)
          );
          const datas = mPrograms[areaStudyData[0].category_id];
          const datasFilter = datas.filter(
            (data: any) =>
              String(data.value) === String(searchQueryParamMProgram)
          );
          setMProgramMenuData(datas);
          setAreaStudyValue(areaStudyData[0].category_id);
          setMProgramValue(datasFilter[0].value.toString());
          setBannerProps(prevState => ({
            ...prevState,
            selectedDegreeInterest: {
              type: degree.type,
              value: degree.value,
            },
            selectedMProgram: {
              text: datasFilter[0].text,
              value: datasFilter[0].value,
            },
          }));
          // navigate
          const path = `${areaStudyData[0].category_name
            .replace(/\s+/g, '')
            .toLowerCase()}/${datasFilter[0].text
            .toLowerCase()
            .replace(/\s+/g, '')
            .toLowerCase()}`;
          const navigateToNewPath = generateQueryParamsForNavigation(
            queryParams,
            path
          );
          navigate(navigateToNewPath);
        }
        if (searchQueryParamAreaStudy && searchQueryParamMProgram) {
          const areaStudyData: any = areaStudyMenuData.filter(
            (areaStudy: any) =>
              String(areaStudy.category_id) ===
              String(searchQueryParamAreaStudy)
          );
          let datas = mPrograms[searchQueryParamAreaStudy];
          const datasFilter = datas.filter(
            (data: any) =>
              String(data.value) === String(searchQueryParamMProgram)
          );
          setAreaStudyValue(searchQueryParamAreaStudy);
          setMProgramMenuData(datas);
          setMProgramValue(searchQueryParamMProgram);
          setBannerProps(prevState => ({
            ...prevState,
            selectedMProgram: {
              text: datasFilter[0].text,
              value: datasFilter[0].value,
            },
          }));
          const path = `${areaStudyData[0].category_name
            .replace(/\s+/g, '')
            .toLowerCase()}/${datasFilter[0].text
            .toLowerCase()
            .replace(/\s+/g, '')
            .toLowerCase()}`;
          const navigateToNewPath = generateQueryParamsForNavigation(
            queryParams,
            path
          );
          navigate(navigateToNewPath);
        }
        if (
          searchQueryParamDegreeInterest &&
          searchQueryParamAreaStudy &&
          searchQueryParamMProgram
        ) {
          const degree = degreeInterestMenuData.find(
            (i: any) => i.value === searchQueryParamDegreeInterest
          );
          const areaStudyData: any = areaStudyMenuData.filter(
            (areaStudy: any) =>
              String(areaStudy.category_id) ===
              String(searchQueryParamAreaStudy)
          );
          const datas = mPrograms[areaStudyData[0].category_id];
          const datasFilter = datas.filter(
            (data: any) =>
              String(data.value) === String(searchQueryParamMProgram)
          );
          setDegreeInterestValue(searchQueryParamDegreeInterest);
          setAreaStudyValue(searchQueryParamAreaStudy);
          setMProgramValue(searchQueryParamMProgram);
          setBannerProps(prevState => ({
            ...prevState,
            selectedDegreeInterest: {
              type: degree.type,
              value: degree.value,
            },
            selectedMProgram: {
              text: datasFilter[0].text,
              value: datasFilter[0].value,
            },
          }));
          const path = `${areaStudyData[0].category_name
            .replace(/\s+/g, '')
            .toLowerCase()}/${datasFilter[0].text
            .toLowerCase()
            .replace(/\s+/g, '')
            .toLowerCase()}`;
          const navigateToNewPath = generateQueryParamsForNavigation(
            queryParams,
            path
          );
          navigate(navigateToNewPath);
        }
        setLoading(false);
      }
    } else if (currentPath === '/nursing' || id6) {
      if (areaStudyMenuData.length > 0) {
        if (id6) {
          const areaStudyData: any = areaStudyMenuData.filter(
            (areaStudy: any) =>
              areaStudy.category_name.replaceAll(' ', '').toLowerCase() ===
              currentPath.split('/')[1].toLowerCase()
          );
          if (mPrograms[areaStudyData[0].category_id]) {
            let datas = mPrograms[areaStudyData[0].category_id];
            const datasFilter = datas.filter(
              (data: any) =>
                data.text.replaceAll(' ', '').toLocaleLowerCase() ===
                id6.replace(' ', '').toLocaleLowerCase()
            );
            setAreaStudyValue(areaStudyData[0].category_id);
            setMProgramMenuData(datas);
            setMProgramValue(datasFilter[0].value.toString());
            // setDegreeInterestValue(
            //   localStorageDegreeInterestValue
            //     ? localStorageDegreeInterestValue
            //     : ''
            // );
            if (searchParamsForListingAPI) {
              setSearchParamsForListingAPI({
                ...searchParamsForListingAPI,
                degreeInterest: localStorageDegreeInterestValue
                  ? localStorageDegreeInterestValue
                  :  degreeInterestValue?degreeInterestValue:'5',
                areaStudy: areaStudyData[0].category_id,
                mProgram: datasFilter[0].value.toString(),
              });
            } else {
             // setDegreeInterestValue('');
            }
            setBannerProps((prevState: any) => ({
              ...prevState,
              selectedAreaStudy: areaStudyData[0],
              selectedMProgram: datasFilter[0],
              selectedDegreeInterest: !degreeInterestValue? { type: '', value: '' }:prevState?.selectedDegreeInterest,
            }));
            setSearchParamsForListingAPI((prevState: any) => ({
              ...prevState,
              ...queryParams,
            }));
          }
          setLoading(false);
        } else {
          // Need to area study menu data
          const areaStudyData: any = areaStudyMenuData.filter(
            (areaStudy: any) =>
              areaStudy.category_name.toLowerCase() ===
              currentPath.replace('/', '').toLowerCase()
          );
          setAreaStudyValue(areaStudyData[0]?.category_id);
          setMProgramMenuData(
            mPrograms[parseInt(areaStudyData[0]?.category_id)]
          );
          setMProgramValue('1000');
          // setDegreeInterestValue(
          //   localStorageDegreeInterestValue
          //     ? localStorageDegreeInterestValue
          //     : ''
          // );
          if (searchParamsForListingAPI) {
            setSearchParamsForListingAPI({
              ...searchParamsForListingAPI,
              degreeInterest: localStorageDegreeInterestValue
                ? localStorageDegreeInterestValue
                :  degreeInterestValue?degreeInterestValue:'5',
              areaStudy: areaStudyData[0]?.category_id,
              mProgram: '1000',
            });
          } else {
          //  setDegreeInterestValue('');
          }
          setBannerProps((prevState: any) => ({
            ...prevState,
            selectedAreaStudy: areaStudyData[0],
            selectedMProgram: { text: 'General Nursing', value: '1000' },
            selectedDegreeInterest: !degreeInterestValue? { type: '', value: '' }:prevState?.selectedDegreeInterest,
          }));
          setSearchParamsForListingAPI((prevState: any) => ({
            ...prevState,
            ...queryParams,
          }));
        }
        if (searchQueryParamDegreeInterest) {
          const degree = degreeInterestMenuData.find(
            (i: any) => i.value === searchQueryParamDegreeInterest
          );
          setDegreeInterestValue(searchQueryParamDegreeInterest);
          setBannerProps(prevState => ({
            ...prevState,
            selectedDegreeInterest: {
              type: degree.type,
              value: degree.value,
            },
          }));
        }
        if (searchQueryParamAreaStudy) {
          let datas = mPrograms[searchQueryParamAreaStudy];
          const pathArea = areaStudyMenuData.find(
            (item: any) => item.category_id === searchQueryParamAreaStudy
          ) as any;
          setMProgramMenuData(datas);
          setMProgramValue(datas[0].value.toString());
          setAreaStudyValue(searchQueryParamAreaStudy);
          const path = pathArea.category_name.replace(/\s+/g, '').toLowerCase();
          const navigateToNewPath = generateQueryParamsForNavigation(
            queryParams,
            path
          );
          navigate(navigateToNewPath);
        }
        if (searchQueryParamMProgram) {
          const mProgramZerothValue = parseInt(
            searchQueryParamMProgram.toString()[0]
          );
          const mProgramFirstValue = String(searchQueryParamMProgram).slice(
            0,
            2
          );
          const filterSearchQuery =
            searchQueryParamMProgram.length < 4
              ? mProgramZerothValue
              : mProgramFirstValue;
          const areaStudyData: any = areaStudyMenuData.filter(
            (areaStudy: any) =>
              String(areaStudy.category_id) === String(filterSearchQuery)
          );
          const datas = mPrograms[areaStudyData[0].category_id];
          const datasFilter = datas.filter(
            (data: any) =>
              String(data.value) === String(searchQueryParamMProgram)
          );
          setMProgramMenuData(datas);
          setAreaStudyValue(areaStudyData[0].category_id);
          setMProgramValue(datasFilter[0].value.toString());
          setBannerProps(prevState => ({
            ...prevState,
            selectedMProgram: {
              text: datasFilter[0].text,
              value: datasFilter[0].value,
            },
          }));
          //navigate
          const path = `${areaStudyData[0].category_name
            .replace(/\s+/g, '')
            .toLowerCase()}/${datasFilter[0].text
            .toLowerCase()
            .replace(/\s+/g, '')
            .toLowerCase()}`;
          const navigateToNewPath = generateQueryParamsForNavigation(
            queryParams,
            path
          );
          navigate(navigateToNewPath);
        }
        if (searchQueryParamDegreeInterest && searchQueryParamAreaStudy) {
          const degree = degreeInterestMenuData.find(
            (i: any) => i.value === searchQueryParamDegreeInterest
          );
          let datas = mPrograms[searchQueryParamAreaStudy];
          const pathArea = areaStudyMenuData.find(
            (item: any) => item.category_id === searchQueryParamAreaStudy
          ) as any;
          setMProgramMenuData(datas);
          setMProgramValue(datas[0].value.toString());
          setAreaStudyValue(searchQueryParamAreaStudy);
          setBannerProps(prevState => ({
            ...prevState,
            selectedDegreeInterest: {
              type: degree.type,
              value: degree.value,
            },
          }));
          const path = pathArea.category_name.replace(/\s+/g, '').toLowerCase();
          const navigateToNewPath = generateQueryParamsForNavigation(
            queryParams,
            path
          );
          navigate(navigateToNewPath);
        }
        if (searchQueryParamDegreeInterest && searchQueryParamMProgram) {
          const mProgramZerothValue = parseInt(
            searchQueryParamMProgram.toString()[0]
          );
          const mProgramFirstValue = String(searchQueryParamMProgram).slice(
            0,
            2
          );
          const filterSearchQuery =
            searchQueryParamMProgram.length < 4
              ? mProgramZerothValue
              : mProgramFirstValue;
          setDegreeInterestValue(searchQueryParamDegreeInterest);
          const degree = degreeInterestMenuData.find(
            (i: any) => i.value === searchQueryParamDegreeInterest
          );
          const areaStudyData: any = areaStudyMenuData.filter(
            (areaStudy: any) =>
              String(areaStudy.category_id) === String(filterSearchQuery)
          );
          const datas = mPrograms[areaStudyData[0].category_id];
          const datasFilter = datas.filter(
            (data: any) =>
              String(data.value) === String(searchQueryParamMProgram)
          );
          setMProgramMenuData(datas);
          setAreaStudyValue(areaStudyData[0].category_id);
          setMProgramValue(datasFilter[0].value.toString());
          setBannerProps(prevState => ({
            ...prevState,
            selectedDegreeInterest: {
              type: degree.type,
              value: degree.value,
            },
            selectedMProgram: {
              text: datasFilter[0].text,
              value: datasFilter[0].value,
            },
          }));
          // navigate
          const path = `${areaStudyData[0].category_name
            .replace(/\s+/g, '')
            .toLowerCase()}/${datasFilter[0].text
            .toLowerCase()
            .replace(/\s+/g, '')
            .toLowerCase()}`;
          const navigateToNewPath = generateQueryParamsForNavigation(
            queryParams,
            path
          );
          navigate(navigateToNewPath);
        }
        if (searchQueryParamAreaStudy && searchQueryParamMProgram) {
          const areaStudyData: any = areaStudyMenuData.filter(
            (areaStudy: any) =>
              String(areaStudy.category_id) ===
              String(searchQueryParamAreaStudy)
          );
          let datas = mPrograms[searchQueryParamAreaStudy];
          const datasFilter = datas.filter(
            (data: any) =>
              String(data.value) === String(searchQueryParamMProgram)
          );
          setAreaStudyValue(searchQueryParamAreaStudy);
          setMProgramMenuData(datas);
          setMProgramValue(searchQueryParamMProgram);
          setBannerProps(prevState => ({
            ...prevState,
            selectedMProgram: {
              text: datasFilter[0].text,
              value: datasFilter[0].value,
            },
          }));
          const path = `${areaStudyData[0].category_name
            .replace(/\s+/g, '')
            .toLowerCase()}/${datasFilter[0].text
            .toLowerCase()
            .replace(/\s+/g, '')
            .toLowerCase()}`;
          const navigateToNewPath = generateQueryParamsForNavigation(
            queryParams,
            path
          );
          navigate(navigateToNewPath);
        }
        if (
          searchQueryParamDegreeInterest &&
          searchQueryParamAreaStudy &&
          searchQueryParamMProgram
        ) {
          const degree = degreeInterestMenuData.find(
            (i: any) => i.value === searchQueryParamDegreeInterest
          );
          const areaStudyData: any = areaStudyMenuData.filter(
            (areaStudy: any) =>
              String(areaStudy.category_id) ===
              String(searchQueryParamAreaStudy)
          );
          const datas = mPrograms[areaStudyData[0].category_id];
          const datasFilter = datas.filter(
            (data: any) =>
              String(data.value) === String(searchQueryParamMProgram)
          );
          setDegreeInterestValue(searchQueryParamDegreeInterest);
          setAreaStudyValue(searchQueryParamAreaStudy);
          setMProgramValue(searchQueryParamMProgram);
          setBannerProps(prevState => ({
            ...prevState,
            selectedDegreeInterest: {
              type: degree.type,
              value: degree.value,
            },
            selectedMProgram: {
              text: datasFilter[0].text,
              value: datasFilter[0].value,
            },
          }));
          const path = `${areaStudyData[0].category_name
            .replace(/\s+/g, '')
            .toLowerCase()}/${datasFilter[0].text
            .toLowerCase()
            .replace(/\s+/g, '')
            .toLowerCase()}`;
          const navigateToNewPath = generateQueryParamsForNavigation(
            queryParams,
            path
          );
          navigate(navigateToNewPath);
        }
        setLoading(false);
      }
    } else if (currentPath === '/vocationaltraining' || id7) {
      if (areaStudyMenuData.length > 0) {
        if (id7) {
          const areaStudyData: any = areaStudyMenuData.filter(
            (areaStudy: any) =>
              areaStudy.category_name.replaceAll(' ', '').toLowerCase() ===
              currentPath.split('/')[1].toLowerCase()
          );
          if (mPrograms[areaStudyData[0].category_id]) {
            let datas = mPrograms[areaStudyData[0].category_id];
            const datasFilter = datas.filter(
              (data: any) =>
                data.text.replaceAll(' ', '').toLocaleLowerCase() ===
                id7.replace(' ', '').toLocaleLowerCase()
            );
            setAreaStudyValue(areaStudyData[0].category_id);
            setMProgramMenuData(datas);
            setMProgramValue(datasFilter[0].value.toString());
            // setDegreeInterestValue(
            //   localStorageDegreeInterestValue
            //     ? localStorageDegreeInterestValue
            //     : ''
            // );
            if (searchParamsForListingAPI) {
              setSearchParamsForListingAPI({
                ...searchParamsForListingAPI,
                degreeInterest: localStorageDegreeInterestValue
                  ? localStorageDegreeInterestValue
                  :  degreeInterestValue?degreeInterestValue:'5',
                areaStudy: areaStudyData[0].category_id,
                mProgram: datasFilter[0].value.toString(),
              });
            } else {
            //  setDegreeInterestValue('');
            }
            setBannerProps((prevState: any) => ({
              ...prevState,
              selectedAreaStudy: areaStudyData[0],
              selectedMProgram: datasFilter[0],
              selectedDegreeInterest: !degreeInterestValue? { type: '', value: '' }:prevState?.selectedDegreeInterest,
            }));
            setSearchParamsForListingAPI((prevState: any) => ({
              ...prevState,
              ...queryParams,
            }));
          }
          setLoading(false);
        } else {
          // Need to area study menu data
          const areaStudyData: any = areaStudyMenuData.filter(
            (areaStudy: any) =>
              areaStudy.category_name.replace(' ', '').toLowerCase() ===
              currentPath.replace('/', '').toLowerCase()
          );
          setAreaStudyValue(areaStudyData[0]?.category_id);
          setMProgramMenuData(
            mPrograms[parseInt(areaStudyData[0]?.category_id)]
          );
          setMProgramValue('700');
          // setDegreeInterestValue(
          //   localStorageDegreeInterestValue
          //     ? localStorageDegreeInterestValue
          //     : ''
          // );
          if (searchParamsForListingAPI) {
            setSearchParamsForListingAPI({
              ...searchParamsForListingAPI,
              degreeInterest: localStorageDegreeInterestValue
                ? localStorageDegreeInterestValue
                :  degreeInterestValue?degreeInterestValue:'5',
              areaStudy: areaStudyData[0]?.category_id,
              mProgram: '700',
            });
          } else {
           // setDegreeInterestValue('');
          }
          setBannerProps((prevState: any) => ({
            ...prevState,
            selectedAreaStudy: areaStudyData[0],
            selectedMProgram: {
              text: 'General Trade Vocational Career',
              value: '700',
            },
            selectedDegreeInterest: !degreeInterestValue? { type: '', value: '' }:prevState?.selectedDegreeInterest,
          }));
          setSearchParamsForListingAPI((prevState: any) => ({
            ...prevState,
            ...queryParams,
          }));
        }
        if (searchQueryParamDegreeInterest) {
          const degree = degreeInterestMenuData.find(
            (i: any) => i.value === searchQueryParamDegreeInterest
          );
          setDegreeInterestValue(searchQueryParamDegreeInterest);
          setBannerProps(prevState => ({
            ...prevState,
            selectedDegreeInterest: {
              type: degree.type,
              value: degree.value,
            },
          }));
        }
        if (searchQueryParamAreaStudy) {
          let datas = mPrograms[searchQueryParamAreaStudy];
          const pathArea = areaStudyMenuData.find(
            (item: any) => item.category_id === searchQueryParamAreaStudy
          ) as any;
          setMProgramMenuData(datas);
          setMProgramValue(datas[0].value.toString());
          setAreaStudyValue(searchQueryParamAreaStudy);
          const path = pathArea.category_name.replace(/\s+/g, '').toLowerCase();
          const navigateToNewPath = generateQueryParamsForNavigation(
            queryParams,
            path
          );
          navigate(navigateToNewPath);
        }
        if (searchQueryParamMProgram) {
          const mProgramZerothValue = parseInt(
            searchQueryParamMProgram.toString()[0]
          );
          const mProgramFirstValue = String(searchQueryParamMProgram).slice(
            0,
            2
          );
          const filterSearchQuery =
            searchQueryParamMProgram.length < 4
              ? mProgramZerothValue
              : mProgramFirstValue;
          const areaStudyData: any = areaStudyMenuData.filter(
            (areaStudy: any) =>
              String(areaStudy.category_id) === String(filterSearchQuery)
          );
          const datas = mPrograms[areaStudyData[0].category_id];
          const datasFilter = datas.filter(
            (data: any) =>
              String(data.value) === String(searchQueryParamMProgram)
          );
          setMProgramMenuData(datas);
          setAreaStudyValue(areaStudyData[0].category_id);
          setMProgramValue(datasFilter[0].value.toString());
          setBannerProps(prevState => ({
            ...prevState,
            selectedMProgram: {
              text: datasFilter[0].text,
              value: datasFilter[0].value,
            },
          }));
          //navigate
          const path = `${areaStudyData[0].category_name
            .replace(/\s+/g, '')
            .toLowerCase()}/${datasFilter[0].text
            .toLowerCase()
            .replace(/\s+/g, '')
            .toLowerCase()}`;
          const navigateToNewPath = generateQueryParamsForNavigation(
            queryParams,
            path
          );
          navigate(navigateToNewPath);
        }
        if (searchQueryParamDegreeInterest && searchQueryParamAreaStudy) {
          const degree = degreeInterestMenuData.find(
            (i: any) => i.value === searchQueryParamDegreeInterest
          );
          let datas = mPrograms[searchQueryParamAreaStudy];
          const pathArea = areaStudyMenuData.find(
            (item: any) => item.category_id === searchQueryParamAreaStudy
          ) as any;
          setMProgramMenuData(datas);
          setMProgramValue(datas[0].value.toString());
          setAreaStudyValue(searchQueryParamAreaStudy);
          setBannerProps(prevState => ({
            ...prevState,
            selectedDegreeInterest: {
              type: degree.type,
              value: degree.value,
            },
          }));
          const path = pathArea.category_name.replace(/\s+/g, '').toLowerCase();
          const navigateToNewPath = generateQueryParamsForNavigation(
            queryParams,
            path
          );
          navigate(navigateToNewPath);
        }
        if (searchQueryParamDegreeInterest && searchQueryParamMProgram) {
          const mProgramZerothValue = parseInt(
            searchQueryParamMProgram.toString()[0]
          );
          const mProgramFirstValue = String(searchQueryParamMProgram).slice(
            0,
            2
          );
          const filterSearchQuery =
            searchQueryParamMProgram.length < 4
              ? mProgramZerothValue
              : mProgramFirstValue;
          setDegreeInterestValue(searchQueryParamDegreeInterest);
          const degree = degreeInterestMenuData.find(
            (i: any) => i.value === searchQueryParamDegreeInterest
          );
          const areaStudyData: any = areaStudyMenuData.filter(
            (areaStudy: any) =>
              String(areaStudy.category_id) === String(mProgramZerothValue)
          );
          const datas = mPrograms[areaStudyData[0].category_id];
          const datasFilter = datas.filter(
            (data: any) =>
              String(data.value) === String(searchQueryParamMProgram)
          );
          setMProgramMenuData(datas);
          setAreaStudyValue(areaStudyData[0].category_id);
          setMProgramValue(datasFilter[0].value.toString());
          setBannerProps(prevState => ({
            ...prevState,
            selectedDegreeInterest: {
              type: degree.type,
              value: degree.value,
            },
            selectedMProgram: {
              text: datasFilter[0].text,
              value: datasFilter[0].value,
            },
          }));
          // navigate
          const path = `${areaStudyData[0].category_name
            .replace(/\s+/g, '')
            .toLowerCase()}/${datasFilter[0].text
            .toLowerCase()
            .replace(/\s+/g, '')
            .toLowerCase()}`;
          const navigateToNewPath = generateQueryParamsForNavigation(
            queryParams,
            path
          );
          navigate(navigateToNewPath);
        }
        if (searchQueryParamAreaStudy && searchQueryParamMProgram) {
          const areaStudyData: any = areaStudyMenuData.filter(
            (areaStudy: any) =>
              String(areaStudy.category_id) ===
              String(searchQueryParamAreaStudy)
          );
          let datas = mPrograms[searchQueryParamAreaStudy];
          const datasFilter = datas.filter(
            (data: any) =>
              String(data.value) === String(searchQueryParamMProgram)
          );
          setAreaStudyValue(searchQueryParamAreaStudy);
          setMProgramMenuData(datas);
          setMProgramValue(searchQueryParamMProgram);
          setBannerProps(prevState => ({
            ...prevState,
            selectedMProgram: {
              text: datasFilter[0].text,
              value: datasFilter[0].value,
            },
          }));
          const path = `${areaStudyData[0].category_name
            .replace(/\s+/g, '')
            .toLowerCase()}/${datasFilter[0].text
            .toLowerCase()
            .replace(/\s+/g, '')
            .toLowerCase()}`;
          const navigateToNewPath = generateQueryParamsForNavigation(
            queryParams,
            path
          );
          navigate(navigateToNewPath);
        }
        if (
          searchQueryParamDegreeInterest &&
          searchQueryParamAreaStudy &&
          searchQueryParamMProgram
        ) {
          const degree = degreeInterestMenuData.find(
            (i: any) => i.value === searchQueryParamDegreeInterest
          );
          const areaStudyData: any = areaStudyMenuData.filter(
            (areaStudy: any) =>
              String(areaStudy.category_id) ===
              String(searchQueryParamAreaStudy)
          );
          const datas = mPrograms[areaStudyData[0].category_id];
          const datasFilter = datas.filter(
            (data: any) =>
              String(data.value) === String(searchQueryParamMProgram)
          );
          setDegreeInterestValue(searchQueryParamDegreeInterest);
          setAreaStudyValue(searchQueryParamAreaStudy);
          setMProgramValue(searchQueryParamMProgram);
          setBannerProps(prevState => ({
            ...prevState,
            selectedDegreeInterest: {
              type: degree.type,
              value: degree.value,
            },
            selectedMProgram: {
              text: datasFilter[0].text,
              value: datasFilter[0].value,
            },
          }));
          const path = `${areaStudyData[0].category_name
            .replace(/\s+/g, '')
            .toLowerCase()}/${datasFilter[0].text
            .toLowerCase()
            .replace(/\s+/g, '')
            .toLowerCase()}`;
          const navigateToNewPath = generateQueryParamsForNavigation(
            queryParams,
            path
          );
          navigate(navigateToNewPath);
        }
        setLoading(false);
      }
    } else if (currentPath === '/liberalarts' || id8) {
      if (areaStudyMenuData.length > 0) {
        if (id8) {
          const areaStudyData: any = areaStudyMenuData.filter(
            (areaStudy: any) =>
              areaStudy.category_name.replaceAll(' ', '').toLowerCase() ===
              currentPath.split('/')[1].toLowerCase()
          );
          if (mPrograms[areaStudyData[0].category_id]) {
            let datas = mPrograms[areaStudyData[0].category_id];
            const datasFilter = datas.filter(
              (data: any) =>
                data.text.replaceAll(' ', '').toLocaleLowerCase() ===
                id8.replace(' ', '').toLocaleLowerCase()
            );
            setAreaStudyValue(areaStudyData[0].category_id);
            setMProgramMenuData(datas);
            // setDegreeInterestValue(
            //   localStorageDegreeInterestValue
            //     ? localStorageDegreeInterestValue
            //     : ''
            // );
            setMProgramValue(datasFilter[0].value.toString());
            if (searchParamsForListingAPI) {
              setSearchParamsForListingAPI({
                ...searchParamsForListingAPI,
                degreeInterest: localStorageDegreeInterestValue
                  ? localStorageDegreeInterestValue
                  :  degreeInterestValue?degreeInterestValue:'5',
                areaStudy: areaStudyData[0].category_id,
                mProgram: datasFilter[0].value.toString(),
              });
            } else {
             // setDegreeInterestValue('');
            }
            setBannerProps((prevState: any) => ({
              ...prevState,
              selectedAreaStudy: areaStudyData[0],
              selectedMProgram: datasFilter[0],
              selectedDegreeInterest: !degreeInterestValue? { type: '', value: '' }:prevState?.selectedDegreeInterest,
            }));
            setSearchParamsForListingAPI((prevState: any) => ({
              ...prevState,
              ...queryParams,
            }));
          }
          setLoading(false);
        } else {
          // Need to area study menu data
          const areaStudyData: any = areaStudyMenuData.filter(
            (areaStudy: any) =>
              areaStudy.category_name.replace(' ', '').toLowerCase() ===
              currentPath.replace('/', '').toLowerCase()
          );
          setAreaStudyValue(areaStudyData[0]?.category_id);
          setMProgramMenuData(
            mPrograms[parseInt(areaStudyData[0]?.category_id)]
          );
          setMProgramValue('800');
          // setDegreeInterestValue(
          //   localStorageDegreeInterestValue
          //     ? localStorageDegreeInterestValue
          //     : ''
          // );
          if (searchParamsForListingAPI) {
            setSearchParamsForListingAPI({
              ...searchParamsForListingAPI,
              degreeInterest: localStorageDegreeInterestValue
                ? localStorageDegreeInterestValue
                :  degreeInterestValue?degreeInterestValue:'5',
              areaStudy: areaStudyData[0]?.category_id,
              mProgram: '800',
            });
          } else {
           // setDegreeInterestValue('');
          }
          setBannerProps((prevState: any) => ({
            ...prevState,
            selectedAreaStudy: areaStudyData[0],
            selectedMProgram: { text: 'General Liberal Arts', value: '800' },
            selectedDegreeInterest: !degreeInterestValue? { type: '', value: '' }:prevState?.selectedDegreeInterest,
          }));
          setSearchParamsForListingAPI((prevState: any) => ({
            ...prevState,
            ...queryParams,
          }));
        }
        if (searchQueryParamDegreeInterest) {
          const degree = degreeInterestMenuData.find(
            (i: any) => i.value === searchQueryParamDegreeInterest
          );
          setDegreeInterestValue(searchQueryParamDegreeInterest);
          setBannerProps(prevState => ({
            ...prevState,
            selectedDegreeInterest: {
              type: degree.type,
              value: degree.value,
            },
          }));
        }
        if (searchQueryParamAreaStudy) {
          let datas = mPrograms[searchQueryParamAreaStudy];
          const pathArea = areaStudyMenuData.find(
            (item: any) => item.category_id === searchQueryParamAreaStudy
          ) as any;
          setMProgramMenuData(datas);
          setMProgramValue(datas[0].value.toString());
          setAreaStudyValue(searchQueryParamAreaStudy);
          const path = pathArea.category_name.replace(/\s+/g, '').toLowerCase();
          const navigateToNewPath = generateQueryParamsForNavigation(
            queryParams,
            path
          );
          navigate(navigateToNewPath);
        }
        if (searchQueryParamMProgram) {
          const mProgramZerothValue = parseInt(
            searchQueryParamMProgram.toString()[0]
          );
          const mProgramFirstValue = String(searchQueryParamMProgram).slice(
            0,
            2
          );
          const filterSearchQuery =
            searchQueryParamMProgram.length < 4
              ? mProgramZerothValue
              : mProgramFirstValue;
          const areaStudyData: any = areaStudyMenuData.filter(
            (areaStudy: any) =>
              String(areaStudy.category_id) === String(filterSearchQuery)
          );
          const datas = mPrograms[areaStudyData[0].category_id];
          const datasFilter = datas.filter(
            (data: any) =>
              String(data.value) === String(searchQueryParamMProgram)
          );
          setMProgramMenuData(datas);
          setAreaStudyValue(areaStudyData[0].category_id);
          setMProgramValue(datasFilter[0].value.toString());
          setBannerProps(prevState => ({
            ...prevState,
            selectedMProgram: {
              text: datasFilter[0].text,
              value: datasFilter[0].value,
            },
          }));
          //navigate
          const path = `${areaStudyData[0].category_name
            .replace(/\s+/g, '')
            .toLowerCase()}/${datasFilter[0].text
            .toLowerCase()
            .replace(/\s+/g, '')
            .toLowerCase()}`;
          const navigateToNewPath = generateQueryParamsForNavigation(
            queryParams,
            path
          );
          navigate(navigateToNewPath);
        }
        if (searchQueryParamDegreeInterest && searchQueryParamAreaStudy) {
          const degree = degreeInterestMenuData.find(
            (i: any) => i.value === searchQueryParamDegreeInterest
          );
          let datas = mPrograms[searchQueryParamAreaStudy];
          const pathArea = areaStudyMenuData.find(
            (item: any) => item.category_id === searchQueryParamAreaStudy
          ) as any;
          setMProgramMenuData(datas);
          setMProgramValue(datas[0].value.toString());
          setAreaStudyValue(searchQueryParamAreaStudy);
          setBannerProps(prevState => ({
            ...prevState,
            selectedDegreeInterest: {
              type: degree.type,
              value: degree.value,
            },
          }));
          const path = pathArea.category_name.replace(/\s+/g, '').toLowerCase();
          const navigateToNewPath = generateQueryParamsForNavigation(
            queryParams,
            path
          );
          navigate(navigateToNewPath);
        }
        if (searchQueryParamDegreeInterest && searchQueryParamMProgram) {
          const mProgramZerothValue = parseInt(
            searchQueryParamMProgram.toString()[0]
          );
          const mProgramFirstValue = String(searchQueryParamMProgram).slice(
            0,
            2
          );
          const filterSearchQuery =
            searchQueryParamMProgram.length < 4
              ? mProgramZerothValue
              : mProgramFirstValue;
          setDegreeInterestValue(searchQueryParamDegreeInterest);
          const degree = degreeInterestMenuData.find(
            (i: any) => i.value === searchQueryParamDegreeInterest
          );
          const areaStudyData: any = areaStudyMenuData.filter(
            (areaStudy: any) =>
              String(areaStudy.category_id) === String(filterSearchQuery)
          );
          const datas = mPrograms[areaStudyData[0].category_id];
          const datasFilter = datas.filter(
            (data: any) =>
              String(data.value) === String(searchQueryParamMProgram)
          );
          setMProgramMenuData(datas);
          setAreaStudyValue(areaStudyData[0].category_id);
          setMProgramValue(datasFilter[0].value.toString());
          setBannerProps(prevState => ({
            ...prevState,
            selectedDegreeInterest: {
              type: degree.type,
              value: degree.value,
            },
            selectedMProgram: {
              text: datasFilter[0].text,
              value: datasFilter[0].value,
            },
          }));
          // navigate
          const path = `${areaStudyData[0].category_name
            .replace(/\s+/g, '')
            .toLowerCase()}/${datasFilter[0].text
            .toLowerCase()
            .replace(/\s+/g, '')
            .toLowerCase()}`;
          const navigateToNewPath = generateQueryParamsForNavigation(
            queryParams,
            path
          );
          navigate(navigateToNewPath);
        }
        if (searchQueryParamAreaStudy && searchQueryParamMProgram) {
          const areaStudyData: any = areaStudyMenuData.filter(
            (areaStudy: any) =>
              String(areaStudy.category_id) ===
              String(searchQueryParamAreaStudy)
          );
          let datas = mPrograms[searchQueryParamAreaStudy];
          const datasFilter = datas.filter(
            (data: any) =>
              String(data.value) === String(searchQueryParamMProgram)
          );
          setAreaStudyValue(searchQueryParamAreaStudy);
          setMProgramMenuData(datas);
          setMProgramValue(searchQueryParamMProgram);
          setBannerProps(prevState => ({
            ...prevState,
            selectedMProgram: {
              text: datasFilter[0].text,
              value: datasFilter[0].value,
            },
          }));
          const path = `${areaStudyData[0].category_name
            .replace(/\s+/g, '')
            .toLowerCase()}/${datasFilter[0].text
            .toLowerCase()
            .replace(/\s+/g, '')
            .toLowerCase()}`;
          const navigateToNewPath = generateQueryParamsForNavigation(
            queryParams,
            path
          );
          navigate(navigateToNewPath);
        }
        if (
          searchQueryParamDegreeInterest &&
          searchQueryParamAreaStudy &&
          searchQueryParamMProgram
        ) {
          const degree = degreeInterestMenuData.find(
            (i: any) => i.value === searchQueryParamDegreeInterest
          );
          const areaStudyData: any = areaStudyMenuData.filter(
            (areaStudy: any) =>
              String(areaStudy.category_id) ===
              String(searchQueryParamAreaStudy)
          );
          const datas = mPrograms[areaStudyData[0].category_id];
          const datasFilter = datas.filter(
            (data: any) =>
              String(data.value) === String(searchQueryParamMProgram)
          );
          setDegreeInterestValue(searchQueryParamDegreeInterest);
          setAreaStudyValue(searchQueryParamAreaStudy);
          setMProgramValue(searchQueryParamMProgram);
          setBannerProps(prevState => ({
            ...prevState,
            selectedDegreeInterest: {
              type: degree.type,
              value: degree.value,
            },
            selectedMProgram: {
              text: datasFilter[0].text,
              value: datasFilter[0].value,
            },
          }));
          const path = `${areaStudyData[0].category_name
            .replace(/\s+/g, '')
            .toLowerCase()}/${datasFilter[0].text
            .toLowerCase()
            .replace(/\s+/g, '')
            .toLowerCase()}`;
          const navigateToNewPath = generateQueryParamsForNavigation(
            queryParams,
            path
          );
          navigate(navigateToNewPath);
        }
        setLoading(false);
      }
    } else if (currentPath === '/undecided' || id9) {
      const contentResponse = await getResponse(GENERAL_BODY_CONTENT);
      setBodyContents(contentResponse);
      if (areaStudyMenuData.length > 0) {
        if (id9) {
          const areaStudyData: any = areaStudyMenuData.filter(
            (areaStudy: any) =>
              areaStudy.category_name.replaceAll(' ', '').toLowerCase() ===
              currentPath.split('/')[1].toLowerCase()
          );
          if (mPrograms[areaStudyData[0].category_id]) {
            let datas = mPrograms[areaStudyData[0].category_id];
            const datasFilter = datas.filter(
              (data: any) =>
                data.text.replace(' ', '').toLocaleLowerCase() ===
                id9.replace(' ', '').toLocaleLowerCase()
            );
            setAreaStudyValue(areaStudyData[0].category_id);
            setMProgramMenuData(datas);
            // setDegreeInterestValue(
            //   localStorageDegreeInterestValue
            //     ? localStorageDegreeInterestValue
            //     : ''
            // );
            setMProgramValue(datasFilter[0].value.toString());
            if (searchParamsForListingAPI) {
              setSearchParamsForListingAPI({
                ...searchParamsForListingAPI,
                degreeInterest: localStorageDegreeInterestValue
                  ? localStorageDegreeInterestValue
                  :  degreeInterestValue?degreeInterestValue:'5',
                areaStudy: areaStudyData[0].category_id,
                mProgram: datasFilter[0].value.toString(),
              });
            } else {
            //  setDegreeInterestValue('');
            }
            setBannerProps((prevState: any) => ({
              ...prevState,
              selectedAreaStudy: areaStudyData[0],
              selectedMProgram: datasFilter[0],
              selectedDegreeInterest: !degreeInterestValue? { type: '', value: '' }:prevState?.selectedDegreeInterest,
            }));
            setSearchParamsForListingAPI((prevState: any) => ({
              ...prevState,
              ...queryParams,
            }));
          }
          setLoading(false);
        } else {
          // Need to area study menu data
          const areaStudyData: any = areaStudyMenuData.filter(
            (areaStudy: any) =>
              areaStudy.category_name.toLowerCase() ===
              currentPath.replace('/', '').toLowerCase()
          );
          setAreaStudyValue(areaStudyData[0]?.category_id);
          setMProgramMenuData(
            mPrograms[parseInt(areaStudyData[0]?.category_id)]
          );
          // setDegreeInterestValue(
          //   localStorageDegreeInterestValue
          //     ? localStorageDegreeInterestValue
          //     : ''
          // );
          setMProgramValue('900');
          if (searchParamsForListingAPI) {
            setSearchParamsForListingAPI({
              ...searchParamsForListingAPI,
              degreeInterest: localStorageDegreeInterestValue
                ? localStorageDegreeInterestValue
                :  degreeInterestValue?degreeInterestValue:'5',
              areaStudy: areaStudyData[0]?.category_id,
              mProgram: '900',
            });
          } else {
          //  setDegreeInterestValue('');
          }
          setBannerProps((prevState: any) => ({
            ...prevState,
            selectedAreaStudy: areaStudyData[0],
            selectedMProgram: { text: 'General', value: '900' },
            selectedDegreeInterest: !degreeInterestValue? { type: '', value: '' }:prevState?.selectedDegreeInterest,
          }));
          setSearchParamsForListingAPI((prevState: any) => ({
            ...prevState,
            ...queryParams,
          }));
        }
        if (searchQueryParamDegreeInterest) {
          const degree = degreeInterestMenuData.find(
            (i: any) => i.value === searchQueryParamDegreeInterest
          );
          setDegreeInterestValue(searchQueryParamDegreeInterest);
          setBannerProps(prevState => ({
            ...prevState,
            selectedDegreeInterest: {
              type: degree.type,
              value: degree.value,
            },
          }));
        }
        if (searchQueryParamAreaStudy) {
          let datas = mPrograms[searchQueryParamAreaStudy];
          const pathArea = areaStudyMenuData.find(
            (item: any) => item.category_id === searchQueryParamAreaStudy
          ) as any;
          setMProgramMenuData(datas);
          setMProgramValue(datas[0].value.toString());
          setAreaStudyValue(searchQueryParamAreaStudy);
          const path = pathArea.category_name.replace(/\s+/g, '').toLowerCase();
          const navigateToNewPath = generateQueryParamsForNavigation(
            queryParams,
            path
          );
          navigate(navigateToNewPath);
        }
        if (searchQueryParamMProgram) {
          const mProgramZerothValue = parseInt(
            searchQueryParamMProgram.toString()[0]
          );
          const mProgramFirstValue = String(searchQueryParamMProgram).slice(
            0,
            2
          );
          const filterSearchQuery =
            searchQueryParamMProgram.length < 4
              ? mProgramZerothValue
              : mProgramFirstValue;
          const areaStudyData: any = areaStudyMenuData.filter(
            (areaStudy: any) =>
              String(areaStudy.category_id) === String(filterSearchQuery)
          );
          const datas = mPrograms[areaStudyData[0].category_id];
          const datasFilter = datas.filter(
            (data: any) =>
              String(data.value) === String(searchQueryParamMProgram)
          );
          setMProgramMenuData(datas);
          setAreaStudyValue(areaStudyData[0].category_id);
          setMProgramValue(datasFilter[0].value.toString());
          setBannerProps(prevState => ({
            ...prevState,
            selectedMProgram: {
              text: datasFilter[0].text,
              value: datasFilter[0].value,
            },
          }));
          const path = `${areaStudyData[0].category_name
            .replace(/\s+/g, '')
            .toLowerCase()}/${datasFilter[0].text
            .toLowerCase()
            .replace(/\s+/g, '')
            .toLowerCase()}`;
          const navigateToNewPath = generateQueryParamsForNavigation(
            queryParams,
            path
          );
          navigate(navigateToNewPath);
        }
        if (searchQueryParamDegreeInterest && searchQueryParamAreaStudy) {
          const degree = degreeInterestMenuData.find(
            (i: any) => i.value === searchQueryParamDegreeInterest
          );
          let datas = mPrograms[searchQueryParamAreaStudy];
          const pathArea = areaStudyMenuData.find(
            (item: any) => item.category_id === searchQueryParamAreaStudy
          ) as any;
          setMProgramMenuData(datas);
          setMProgramValue(datas[0].value.toString());
          setAreaStudyValue(searchQueryParamAreaStudy);
          setBannerProps(prevState => ({
            ...prevState,
            selectedDegreeInterest: {
              type: degree.type,
              value: degree.value,
            },
          }));
          const path = pathArea.category_name.replace(/\s+/g, '').toLowerCase();
          const navigateToNewPath = generateQueryParamsForNavigation(
            queryParams,
            path
          );
          navigate(navigateToNewPath);
        }
        if (searchQueryParamDegreeInterest && searchQueryParamMProgram) {
          const mProgramZerothValue = parseInt(
            searchQueryParamMProgram.toString()[0]
          );
          const mProgramFirstValue = String(searchQueryParamMProgram).slice(
            0,
            2
          );
          const filterSearchQuery =
            searchQueryParamMProgram.length < 4
              ? mProgramZerothValue
              : mProgramFirstValue;
          setDegreeInterestValue(searchQueryParamDegreeInterest);
          const degree = degreeInterestMenuData.find(
            (i: any) => i.value === searchQueryParamDegreeInterest
          );
          const areaStudyData: any = areaStudyMenuData.filter(
            (areaStudy: any) =>
              String(areaStudy.category_id) === String(filterSearchQuery)
          );
          const datas = mPrograms[areaStudyData[0].category_id];
          const datasFilter = datas.filter(
            (data: any) =>
              String(data.value) === String(searchQueryParamMProgram)
          );
          setMProgramMenuData(datas);
          setAreaStudyValue(areaStudyData[0].category_id);
          setMProgramValue(datasFilter[0].value.toString());
          setBannerProps(prevState => ({
            ...prevState,
            selectedDegreeInterest: {
              type: degree.type,
              value: degree.value,
            },
            selectedMProgram: {
              text: datasFilter[0].text,
              value: datasFilter[0].value,
            },
          }));
          // navigate
          const path = `${areaStudyData[0].category_name
            .replace(/\s+/g, '')
            .toLowerCase()}/${datasFilter[0].text
            .toLowerCase()
            .replace(/\s+/g, '')
            .toLowerCase()}`;
          const navigateToNewPath = generateQueryParamsForNavigation(
            queryParams,
            path
          );
          navigate(navigateToNewPath);
        }
        if (searchQueryParamAreaStudy && searchQueryParamMProgram) {
          const areaStudyData: any = areaStudyMenuData.filter(
            (areaStudy: any) =>
              String(areaStudy.category_id) ===
              String(searchQueryParamAreaStudy)
          );
          let datas = mPrograms[searchQueryParamAreaStudy];
          const datasFilter = datas.filter(
            (data: any) =>
              String(data.value) === String(searchQueryParamMProgram)
          );
          setAreaStudyValue(searchQueryParamAreaStudy);
          setMProgramMenuData(datas);
          setMProgramValue(searchQueryParamMProgram);
          setBannerProps(prevState => ({
            ...prevState,
            selectedMProgram: {
              text: datasFilter[0].text,
              value: datasFilter[0].value,
            },
          }));
          const path = `${areaStudyData[0].category_name
            .replace(/\s+/g, '')
            .toLowerCase()}/${datasFilter[0].text
            .toLowerCase()
            .replace(/\s+/g, '')
            .toLowerCase()}`;
          const navigateToNewPath = generateQueryParamsForNavigation(
            queryParams,
            path
          );
          navigate(navigateToNewPath);
        }
        if (
          searchQueryParamDegreeInterest &&
          searchQueryParamAreaStudy &&
          searchQueryParamMProgram
        ) {
          const degree = degreeInterestMenuData.find(
            (i: any) => i.value === searchQueryParamDegreeInterest
          );
          const areaStudyData: any = areaStudyMenuData.filter(
            (areaStudy: any) =>
              String(areaStudy.category_id) ===
              String(searchQueryParamAreaStudy)
          );
          const datas = mPrograms[areaStudyData[0].category_id];
          const datasFilter = datas.filter(
            (data: any) =>
              String(data.value) === String(searchQueryParamMProgram)
          );
          setDegreeInterestValue(searchQueryParamDegreeInterest);
          setAreaStudyValue(searchQueryParamAreaStudy);
          setMProgramValue(searchQueryParamMProgram);
          setBannerProps(prevState => ({
            ...prevState,
            selectedDegreeInterest: {
              type: degree.type,
              value: degree.value,
            },
            selectedMProgram: {
              text: datasFilter[0].text,
              value: datasFilter[0].value,
            },
          }));
          const path = `${areaStudyData[0].category_name
            .replace(/\s+/g, '')
            .toLowerCase()}/${datasFilter[0].text
            .toLowerCase()
            .replace(/\s+/g, '')
            .toLowerCase()}`;
          const navigateToNewPath = generateQueryParamsForNavigation(
            queryParams,
            path
          );
          navigate(navigateToNewPath);
        }
        setLoading(false);
      }
    }
  };

  const degreeInterestHandle = (event: SelectChangeEvent) => {
    setDegreeInterestValue(event.target.value as string);
    // localStorage.setItem('degreeValue', event.target.value as string)
  };

  const areaStudyHandle = (event: SelectChangeEvent) => {
    setAreaStudyValue(event.target.value as string);
    if (mPrograms[event.target.value as string]) {
      let datas = mPrograms[event.target.value as string];
      setMProgramMenuData(datas);
      setMProgramValue('');
    }
  };

  const mProgramHandle = (event: SelectChangeEvent) => {
    // setMProgramValue(event.target.value as string)
    const cleanedValue = removeSpecialCharacters(event.target.value as string);
    setMProgramValue(cleanedValue);
  };

  // const scrollToSection = (ref:React.RefObject<HTMLDivElement>) => {
  //   if (ref.current) {
  //     ref.current.scrollIntoView({ behavior: 'smooth' });
  //   }
  // };

  const scrollToSection = (ref: React.RefObject<HTMLDivElement>, duration: number) => {
    if (ref.current) {
      const targetPosition = ref.current.offsetTop; // Get the target position
      const startPosition = window.pageYOffset; // Get the current position
  
      const distance = targetPosition - startPosition;
      const startTime = performance.now();
  
      const easeInOutQuad = (t: number) => t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t; // Easing function
  
      const animateScroll = (currentTime: number) => {
        const elapsedTime = currentTime - startTime;
        const progress = Math.min(elapsedTime / duration, 1); // Calculate progress
  
        window.scrollTo(0, startPosition + distance * easeInOutQuad(progress)); // Update scroll position
  
        if (elapsedTime < duration) {
          requestAnimationFrame(animateScroll); // Continue animation until duration is reached
        }
      };
  
      requestAnimationFrame(animateScroll);
    }
  };

  const handleSubmitDropdown = async () => {
    setIsSubmitButtonTiger(true);
    const pathArea = areaStudyMenuData.find(
      (item: any) => item.category_id === areaStudyValue
    ) as any;
    const pathProgram = mProgramMenuData.find(
      (val: any) => val.value === mProgramValue
    ) as any;
    const pathDegree = degreeInterestMenuData.find(
      (val: any) => val.value === degreeInterestValue
    ) as any;
    const dynamicPath = `${pathArea.category_name
      .replace(/\s+/g, '')
      .toLowerCase()}/${pathProgram.text
      .toLowerCase()
      .replace(/\s+/g, '')
      .toLowerCase()}`;

    setSearchParamsForListingAPI({
      ...searchParamsForListingAPI,
      areaStudy: areaStudyValue,
      mProgram: mProgramValue,
      degreeInterest: degreeInterestValue,
    });
    setBannerProps((prevState: any) => ({
      ...prevState,
      selectedAreaStudy: pathArea,
      selectedMProgram: pathProgram,
      selectedDegreeInterest: pathDegree,
    }));
    const newPath = `/${dynamicPath}`;
    navigate(newPath);
    // const filterCardMobile = document.querySelector(
    //   '.filter-card.mobile'
    // ) as HTMLElement;

    // if (filterCardMobile) {
    //   // Scroll the component to the top
    //  /// window.scrollTo({ top: 100, behavior: 'smooth' });
    //  const isMobile = window.innerWidth <= 799; 
    //  if(isMobile){

    //    scrollToSection(leadSectionRef);
    //  }else{
    //   window.scrollTo({ top: 0, behavior: 'smooth' });
    //  }
    // }
  };

  const universityLists = listingData?.map(i => ({
    mPartner: i.mPartner,
    mBrandID: i.mBrandID,
    mBrandName: i.mBrandName,
    bodyContent: i.bodyContent,
    headContent: i.headContent,
    mPosition: i.mPosition,
    clickURL: i.clickURL,
    Commission: i.Commission,
    imageURL: i.imageURL,
  }));

  const fetchUniversities =
    universityLists &&
    universityLists?.map((iList, id) => {
      return (
        <ListingCards
          {...iList}
          key={id}
          requestData={requestData}
          areaStudy={bannerProps?.selectedAreaStudy?.category_name}
        />
      );
    });

  const fetchedTotalClicks = async () => {
    const totalClickResponse = await getResponse(TOTAL_CLICK_COUNT);
    setTotalClickCount(totalClickResponse);
  };

  const fetchTopChoiceUniversity = universityLists?.map((iItem, id) => {
    if (iItem.mPosition === 1) {
      return <ListingCards {...iItem} key={id} />;
    }
  });

  const fetchTopPickColleges = universityLists?.map((topItem, id) => {
    if (topItem.mPosition <= 1) {
      return (
        <TopChoiceCards
          {...topItem}
          key={id}
          requestData={requestData}
          areaStudy={bannerProps?.selectedAreaStudy?.category_name}
        />
      );
    }
  });

  const isH3Section = (id: number) => {
    const h3SectionIds = [9, 10, 11, 12, 14, 15, 16, 17];
    return mProgramValue === '100' && h3SectionIds.includes(id);
  };

  useEffect(()=>{
    window.scrollTo({ top: 0, behavior: 'smooth' });
  },[]);

  return (
    <Box>
      <Toolbar style={toolbarStyle} />
      <Banner
        bannerProps={bannerProps}
        totalLength={totalLength}
        newBannerProps={searchParamsForListingAPI}
      />
      <Container className="mw-1170" sx={{ py: '10px' }}>
        {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div className="custom-loader"></div>
          </div>
        ) : (
          <Grid container columnSpacing={2}>
            <Grid item xs={12} md={8} className="result-card">
              <Grid item xs={12} className="filter-card mobile">
                <Box sx={{ width: '100%' }}>
                  <MenuDropDownComponent
                    degreeInterestDDMenu={degreeInterestMenuData}
                    degreeInterestValue={degreeInterestValue}
                    degreeInterestHandler={degreeInterestHandle}
                    areaStudyDDMenu={areaStudyMenuData}
                    areaStudyDDValue={areaStudyValue}
                    areaStudyDDHandler={areaStudyHandle}
                    mProgramDDMenu={mProgramMenuData}
                    mProgramDDValue={mProgramValue}
                    mProgramDDHandler={mProgramHandle}
                    handleSubmit={handleSubmitDropdown}
                  />
                  {/* <TotalClickedProgramCard clickCount={totalClickCount} /> */}
                  {/* <Box textAlign="center" mt={2}>
                                        <Button
                                            variant="contained"
                                            className="custom-btn"
                                            onClick={() =>
                                                navigate("/get-information")
                                            }
                                            sx={{
                                                textTransform: "none",
                                                fontSize: "18px",
                                                borderRadius: "8px",
                                                width: "100%"
                                            }}
                                        >
                                            Request Information
                                        </Button>
                                    </Box> */}
                </Box>
              </Grid>
              <section ref={leadSectionRef}>
              {fetchUniversities}
              </section>

              <Box>
                {/* <Typography component="h2" fontWeight="800" fontSize="35px" mb={3} mt={6}>
                                    {'Featured Online College'}
                                </Typography>
                                {fetchTopChoiceUniversity}  */}
                {bodyContents.map((iSection: any, key: number) => {
                  if (iSection.section_id <= 5) {
                    return (
                      <div key={key}>
                        <Typography
                          component="h2"
                          fontWeight="800"
                          fontSize="25px"
                          mb={1}
                          mt={6}
                        >
                          {iSection.title}
                        </Typography>
                        <Typography mb={2}>
                          {' '}
                          {iSection.description.description_content_head}{' '}
                        </Typography>
                        {iSection.description.key_points
                          ? iSection.description.key_points?.map(
                              (iPoints: any, key: number) => {
                                if (iPoints.key && iPoints.value) {
                                  return (
                                    <ul key={key}>
                                      <li>
                                        {iPoints.key}: {iPoints.value}
                                      </li>
                                      {iPoints.more_info ? (
                                        <ul>
                                          <li>
                                            {iPoints.more_info.key}:{' '}
                                            {iPoints.more_info.value}
                                          </li>
                                        </ul>
                                      ) : (
                                        ''
                                      )}
                                    </ul>
                                  );
                                } else {
                                  return (
                                    <ul key={key}>
                                      <li>{iPoints}</li>
                                    </ul>
                                  );
                                }
                              }
                            )
                          : ''}
                        <Typography component="p" mb={2}>
                          {' '}
                          {iSection.description.description_content_end}{' '}
                        </Typography>
                      </div>
                    );
                  }
                })}

                <Typography
                  component="h2"
                  fontWeight="800"
                  fontSize="35px"
                  mb={3}
                  mt={6}
                >
                  {/* Our Top {newTotalPickedLength} Picks for Online Colleges */}
                  Online Schools Matching Your Search
                </Typography>
                {fetchTopPickColleges}
                {bodyContents.map((iSection: any, key: number) => {
                  if (iSection.section_id > 5) {
                    return (
                      <div key={key}>
                        <Typography
                          component={
                            isH3Section(iSection?.section_id) ? 'h3' : 'h2'
                          }
                          fontWeight="800"
                          fontSize={
                            isH3Section(iSection?.section_id)
                              ? '18.5px'
                              : '25px'
                          }
                          mb={1}
                          mt={6}
                        >
                          {iSection.title}
                        </Typography>
                        <Typography component="p" mb={2}>
                          {iSection.description.description_content_head &&
                            (iSection.description.description_content_head?.includes(
                              '\n\n'
                            )
                              ? iSection.description.description_content_head
                                  ?.split('\n\n')
                                  ?.map((paragraph: any, index: any) => (
                                    <React.Fragment key={index}>
                                      {paragraph}
                                      <br />
                                      <br />
                                    </React.Fragment>
                                  ))
                              : iSection.description.description_content_head)}
                        </Typography>
                        {iSection.description.key_points
                          ? iSection.description.key_points?.map(
                              (iPoints: any, key: string) => {
                                if (iPoints.key && iPoints.value) {
                                  return (
                                    <ul key={key}>
                                      <li>
                                        {iPoints.key}: {iPoints.value}
                                      </li>
                                      {iPoints.more_info ? (
                                        <ul>
                                          <li>
                                            {iPoints.more_info?.key}:{' '}
                                            {iPoints.more_info?.value}
                                          </li>
                                        </ul>
                                      ) : (
                                        ''
                                      )}
                                    </ul>
                                  );
                                } else {
                                  return (
                                    <ul key={key}>
                                      <li>{iPoints}</li>
                                    </ul>
                                  );
                                }
                              }
                            )
                          : ''}
                        <Typography component="p" mb={2}>
                          {' '}
                          {iSection.description.description_content_end}{' '}
                        </Typography>
                      </div>
                    );
                  }
                })}
              </Box>
            </Grid>
            <Grid item xs={12} md={4} className="filter-card desktop">
              <Box sx={{ width: '100%' }}>
                <MenuDropDownComponent
                  degreeInterestDDMenu={degreeInterestMenuData}
                  degreeInterestValue={degreeInterestValue}
                  degreeInterestHandler={degreeInterestHandle}
                  areaStudyDDMenu={areaStudyMenuData}
                  areaStudyDDValue={areaStudyValue}
                  areaStudyDDHandler={areaStudyHandle}
                  mProgramDDMenu={mProgramMenuData}
                  mProgramDDValue={mProgramValue}
                  mProgramDDHandler={mProgramHandle}
                  handleSubmit={handleSubmitDropdown}
                />
                {/* <TotalClickedProgramCard clickCount={totalClickCount} /> */}
                <Card className="editorial-reviews">
                  <CardContent sx={{ p: '0px !important' }}>
                    <Typography
                      component="h2"
                      fontWeight="600"
                      textAlign="center"
                    >
                      Request Information
                    </Typography>
                    <Box>
                      {' '}
                      {universityLists?.map((iReviews, key) => {
                        const handleVisitSiteClick = () => {
                          const conversionValue = iReviews.Commission;
                          const transactionID = `${requestData}-${iReviews.mPosition}`;
                          window.dataLayer.push({
                            event: 'mm-cod-gtag',
                            conversionValue: conversionValue,
                            transactionID: transactionID,
                          });
                          gtagEvent('click', {
                            brand_name: iReviews.mBrandName,
                            brand_id: iReviews.mBrandID,
                            link_url: iReviews.clickURL,
                            time_stamp: new Date().getTime(),
                          });
                          initCCConvertion(conversionValue);
                        };
                        return (
                          <div key={key}>
                            <Box p={2} className="list-box">
                              <Box
                                display="flex"
                                alignItems="flex-start"
                                gap="10px"
                                component={Link}
                                href={iReviews.clickURL}
                                target="_blank"
                                sx={{ textDecoration: 'none' }}
                                onClick={handleVisitSiteClick}
                              >
                                <img
                                  src={`${s3SecondaryLogo}${iReviews.mBrandID}.webp`}
                                  alt={
                                    bannerProps?.selectedAreaStudy
                                      ?.category_name
                                  }
                                  title={iReviews?.mBrandName}
                                />
                                <Box flexGrow={1}>
                                  <Typography
                                    component="h3"
                                    fontSize="18px"
                                    color="initial"
                                  >
                                    {iReviews.mBrandName}
                                  </Typography>
                                  {/* <Box display="flex" mt={1} justifyContent="space-between">
                                                                        <RouteLink to='/college-review' state={iReviews}>Read More</RouteLink>
                                                                        <Link href={iReviews.clickURL} target="_blank">Visit Site</Link>
                                                                    </Box> */}
                                </Box>
                              </Box>
                            </Box>
                          </div>
                        );
                      })}
                    </Box>
                  </CardContent>
                </Card>

                {/* <Box textAlign="center" mt={2}>
                                    <Button
                                        variant="contained"
                                        className="custom-btn"
                                        onClick={() =>
                                            navigate("/get-information")
                                        }
                                        sx={{
                                            textTransform: "none",
                                            fontSize: "18px",
                                            borderRadius: "8px",
                                            width: "100%"
                                        }}
                                    >
                                        Request Information
                                    </Button>
                                </Box> */}
              </Box>
            </Grid>
          </Grid>
        )}
      </Container>
    </Box>
  );
};

export default ParentMain;
