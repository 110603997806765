import React, {useEffect} from 'react';
import { Route, Routes } from 'react-router-dom'
import Header from './components/Header'
import "./App.scss"
import Footer from './components/Footer'
import { areaRoutes, areaRoutes1 } from './common/routes'
import Faq from './components/Faq'
import { gtag } from './services/google-analytics';
import NavBar from './components/NavBar';

export default function App() {
  useEffect(()=>{
    gtag('js', new Date());
    gtag('config', 'G-NX7FNDJZX2');
  });

  return (
    <React.Fragment>
      {/* <Header /> */}
      {/* <NavBar/> */}
      <Routes>
        {areaRoutes1.map((route) => {
          return <Route
            key={route.path}
            path={route.path}
            // exact={route.exact}
            element={<route.component />}
            // name={route.name}
          />
        })}
      </Routes>
      <Faq/>
      <Footer />
    </React.Fragment>

  )
}
