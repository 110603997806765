import * as Yup from 'yup';

const addressValidation = /^[a-zA-Z0-9\s,'.-]*$/;
const nameValidation = /[a-zA-Z]+/g;
const emailValidation = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/
// const phoneNumberValidation = /^(\+\d{1,3}[- ]?)?\d{10}$/
const zipCodeValidation = /^\d{5}\D*$/
// /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/

export const leadFormLocationInformationSchema = Yup.object({
    address: Yup.string().matches(addressValidation, 'Please enter valid address').required('Please enter valid address'),
    city: Yup.string().matches(nameValidation, 'Please enter valid city').required('Please enter valid city'),
    state: Yup.string().required('Please enter valid state'),
    // campusType: Yup.string().required('Please enter valid campus type '),
    campusType: Yup.object().shape({
        // Assuming you want to ensure that a campus type is selected
        tcpaValue: Yup.string().required('Please select a campus type'),
        additionalValue: Yup.string().required('Please select a campus type'),
      }),
    zipCode: Yup.string().matches(zipCodeValidation, 'Please enter valid 5-digit zip code').required('Please enter valid zip code')
})

export const leadFormUserInformationSchema = Yup.object({
    firstName: Yup.string().matches(nameValidation, 'Must have character only').required('Please enter valid first name'),
    lastName: Yup.string().matches(nameValidation, 'Must have character only').required('Please enter valid last name'),
    phoneNumber: Yup.string().required('Please enter valid phone number'),
    email: Yup.string().matches(emailValidation, 'Please enter valid email').required('Please enter valid email'),
    check: Yup.boolean().oneOf([true], 'You must agree to the terms and conditions.'),
   // gender: Yup.string().matches(nameValidation, 'Must have character only').required('Please select a gender'),
})

// .matches(phoneNumberValidation, 'Please enter 10-digit Valid Phone Number')