import { ZipCodeStateProps } from "../../types/leadOptions";

export const zipCodesValues: ZipCodeStateProps = {
    '35001-36999': 'AL',
    '99501-99950': 'AK',
    '85001-86556': 'AZ',
    '71601-72959': 'Ar',
    '90001-96162': 'CA',
    '80001-81658': 'CO',
    '06001-06999': 'CT',
    '19701-19971': 'DE',
    '32001-34999': 'FL',
    '30001-31999': 'GA',
    '96701-96899': 'HI',
    '83201-83868': 'ID',
    '60001-62999': 'IL',
    '46001-47999': 'IN',
    '50001-52999': 'IA',
    '66001-66999': 'KS',
    '40001-42999': 'KY',
    '70001-71299': 'LA',
    '03001-04999': 'ME',
    '20001-21999': 'MD',
    '01001-02999': 'MA',
    '48001-49999': 'MI',
    '55001-56999': 'MN',
    '38601-39999': 'MS',
    '63001-65999': 'MO',
    '59001-59999': 'MT',
    '68001-68999': 'NE',
    '89001-89950': 'NV',
    '03001-03999': 'NH',
    '07001-08999': 'NJ',
    '87001-88259': 'NM',
    '10001-14999': 'NY',
    '27001-28999': 'NC',
    '58001-58750': 'ND',
    '43001-45999': 'OH',
    '73001-74959': 'OK',
    '97001-97909': 'OR',
    '15001-19999': 'PA',
    '02901-02999': 'RI',
    '29001-29999': 'SC',
    '57001-57750': 'SD',
    '37001-38999': 'TN',
    '75001-79999': 'TX',
    '84001-84999': 'UT',
    '05001-05999': 'VT',
    '22001-24999': 'VA',
    '98001-98999': 'WA',
    '26001-26999': 'WV',
    '53001-54999': 'WI',
    '82001-82999': 'WY'
}
