import React, { useContext, useEffect, useState } from 'react';
import { Typography, Box, Link, Container, Grid } from '@mui/material';
import { fetchListingData, getResponse } from '../../services';
import AdditionalLead from '../AdditionalLead/AdditionalLead';
import { TListingData } from '../../types/common';
import ListingCards from './../ListingCards';
import { BODY_CONTENT } from '../../constants/url/constantUrl';
import { s3SecondaryLogo } from '../../constants/url/logoUrl';
import { initCCConvertion } from '../../clickCaese';
import { gtagEvent } from '../../services/google-analytics';
import { Link as RouteLink } from 'react-router-dom';
import { LeadContext } from '../../contextProvider/leadContextProvider';
import { CleaningServices } from '@mui/icons-material';
import brokenImage from '../../assets/images/broken-image.png';

const ThankYou = ({ leadFormData }: any) => {
  const { selectedOffers, setSelectedOffers } = useContext(LeadContext);
  const [getLocalItem, setGetLocalItem] = useState<any>(null);
  const [listingData, setListingData] = useState<TListingData[]>([]);
  const [requestData, setRequestData] = useState('');
  const [bodyContents, setBodyContents] = useState<any[]>([]);
  const [mProgramValue, setMProgramValue] = useState('');
  const [error, setError] = useState();

  let [leadFormStep] = useState<any>({
    areaStudy: leadFormData?.areaStudy?.category_id,
    mProgram: leadFormData?.mProgram?.program_id,
    highestEducation: leadFormData?.highestEducationName.tcpaValue,
    gradYear: leadFormData.highestEducationYear.value,
    militaryAffiliation: leadFormData.militaryAffiliationName.value
      ? leadFormData.militaryAffiliationName.value
      : '1',
    startTimeFrame: leadFormData.duration.tcpaValue,
    campusType: leadFormData.userLocation.userCampusType.tcpaValue,
    firstName: leadFormData.userInformation.userFirstName,
    mPubID: 93552,
    mPubCampaignID: 45438,
    webInitiatingURL: 'compareonlinedegrees.com',
    redirect: 0,
    gender: leadFormData.userInformation.userGender,
    zipCode: leadFormData?.userLocation?.userZipCode,
  });

  const [searchParamsForListingAPI, setSearchParamsForListingAPI] =
    useState<any>({
      areaStudy: '1',
      mProgram: '106',
      degreeInterest: '3',
      gradYear: 2008,
      campusType: 2,
      militaryAffiliation: 1,
      startTimeframe: 1,
      sub1: '',
      sub2: '',
      sub3: '',
      webLandingURL: '',
      webInitiatingURL: 'compareonlinedegrees.com',
      redirect: 0,
      mPubID: 93552,
      mPubCampaignID: 25134,
      adsMax: 100,
      enableFilterByCPC: 1,
      enableFilterByBrand: 1,
      zipCode: leadFormData?.userLocation?.userZipCode,
    });

  console.log('searchParamsForListingAPI', searchParamsForListingAPI);
  const listedOffers = localStorage.getItem('offers');

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);
  useEffect(() => {
    getListingData();
  }, [searchParamsForListingAPI]);

  useEffect(() => {
    console.log('selectedOffers', selectedOffers);
    console.log(
      'selectedOffers.details',
      selectedOffers.map((offers: any) => offers.details)
    );

    console.log(
      'selectedOffers.logoURL',
      selectedOffers.map((offers: any) => offers.details.logo_url)
    );
  }, [selectedOffers]);

  const logoURL = selectedOffers.map((offers: any) => offers.details.logo_url);

  const getListingData = async () => {
    try {
      const res = await fetchListingData(searchParamsForListingAPI);
      setListingData(res.result);
      setRequestData(res.mRequestId);
      const contentResponse = await getResponse(BODY_CONTENT, mProgramValue);
      console.log('contentResponse', contentResponse);
      setBodyContents(contentResponse[0].sections);
    } catch (error: any) {
      setError(error);
    }
  };

  const universityLists = listingData?.map(i => ({
    mPartner: i.mPartner,
    mBrandID: i.mBrandID,
    mBrandName: i.mBrandName,
    bodyContent: i.bodyContent,
    headContent: i.headContent,
    mPosition: i.mPosition,
    clickURL: i.clickURL,
    Commission: i.Commission,
    imageURL: i.imageURL,
  }));

  const fetchUniversities =
    universityLists &&
    universityLists?.map((iList, id) => {
      return <ListingCards {...iList} key={id} requestData={requestData} />;
    });

  // Create an array of imageURL values
  const imageURLs = universityLists.map(item => item.imageURL);

  // Console log the array of imageURL values
  console.log('fetchUniversitiesLogo', imageURLs);

  const fetchUniversitiesLogo =
    universityLists &&
    universityLists?.map((iList, id) => {
      return (
        <ListingCards
          imageURL={iList.imageURL}
          key={id}
          requestData={requestData}
        />
      );
    });

  console.log('universityLists', universityLists);

  // useEffect(() => {
  //   const delayedExecution = async () => {
  //     const getOffers = localStorage.getItem('offers');
  //     if (getOffers) {
  //       const parsedGetLocalItem = JSON.parse(getOffers);
  //       console.log('parsedGetLocalItem', parsedGetLocalItem);
  //       setGetLocalItem(parsedGetLocalItem);
  //     } else {
  //       const response = await fetchListingData(leadFormStep);
  //       console.log('response in ListingCom:', response);
  //       const newTab = window.open(response, '_blank');
  //       if (newTab) {
  //         newTab.focus();
  //       }
  //     }
  //   };
  //   const timeoutId = setTimeout(delayedExecution, 5000);
  //   return () => clearTimeout(timeoutId);
  // }, []);

  return (
    <React.Fragment>
      <Box textAlign="center">
        <Box py={1} sx={{ backgroundColor: '#57A773' }}>
          <Container maxWidth="lg">
          <Typography
              component="h2"
              fontSize="23px"
              lineHeight="1.0"
              fontWeight="800"
              color="#fff"
            >
              {`Thanks ${leadFormStep.firstName} for letting us help you take the first step in your educational journey!`}
            </Typography>
          </Container>
        </Box>
        {/*         
        color='#808080' */}
        <Box sx={{ backgroundColor: '#EDEDED', marginTop: '40px' }}>
          <Typography
            component="p"
            fontWeight="700"
            mb={1}
            fontSize="22px"
            textAlign="center"
            marginBottom="16px"
            marginTop="20px"
          >
            {`Your information has been sent to:`}
          </Typography>

          <Grid
            container
            spacing={3}
            sx={{
              //  // textAlign: 'left',
              width: '85%',
              mx: 'auto',
              alignItems: 'center',
              justifyContent: 'center',
              paddingBottom: '20px',
            }}
            // p={2}
          >
            {selectedOffers?.map((data: any, key: any) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  // sx={{ display: 'flex' }}
                  // alignItems="center"
                  // justifyContent="center"
                >
                  <div className="img_card">
                    <img
                      src={`${data.details.logo_url || brokenImage}`}
                      alt=""
                      // width={data.details.logo_url ? '120px' : '60px'}
                      // height="60px"
                      className="img_logo"
                    />
                  </div>
                  {/* <Typography
                    component="h3"
                    fontSize="18px"
                    color="#000000"
                    fontWeight="600"
                    ml={2}
                  >
                    {data.details.name}
                  </Typography> */}
                </Grid>
              );
            })}
          </Grid>
        </Box>
        <Box
          display="flex-column"
          flexDirection="column"
          width="70%"
          mx="auto"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            component="h2"
            fontSize="20px"
            lineHeight="1.3"
            fontWeight="800"
            textAlign="center"
            marginTop="30px"
            marginBottom="30px"
          >
            {`Okay, great! Here are a few sponsored schools you may be interested in:`}
          </Typography>
          <Box
            display="flex-column"
            flexDirection="column"
            width="85%"
            mx="auto"
            alignItems="center"
            justifyContent="center"
          >
            {fetchUniversities}
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default ThankYou;
