export const mPrograms: any = {
    "1": [{ "text": "General Business", "value": "100" }, { "text": "Accounting", "value": "101" }, { "text": "Business Administration", "value": "102" }, { "text": "Business Analytics", "value": "103" }, { "text": "Communications", "value": "104" }, { "text": "Economics", "value": "105" }, { "text": "Finance", "value": "106" }, { "text": "Human Resources", "value": "107" }, { "text": "Management", "value": "108" }, { "text": "Digital Marketing", "value": "109" }, { "text": "Project Management", "value": "110" }, { "text": "Sports Management", "value": "111" }, { "text": "Healthcare Administration", "value": "112" }],

    "2": [{ "text": "General Criminal Justice", "value": "200" }, { "text": "Corrections", "value": "201" }, { "text": "Crime Scence Investigation", "value": "202" }, { "text": "Criminal Justice", "value": "203" }, { "text": "Criminology", "value": "204" }, { "text": "Cyber Security", "value": "205" }, { "text": "Forensic Science", "value": "206" }, { "text": "Homeland Security", "value": "207" }, { "text": "Law Enforcement", "value": "208" }, { "text": "Legal & Paralegal Studies", "value": "209" }, { "text": "Public Safety Administration", "value": "211" }, { "text": "Security", "value": "212" }],

    "3": [{ "text": "General Psychology", "value": "300" }, { "text": "Addictions & Recovery", "value": "301" }, { "text": "Applied Psychology", "value": "302" }, { "text": "Behavioral Psychology", "value": "303" }, { "text": "Child Psychology", "value": "304" }, { "text": "Counseling", "value": "305" }, { "text": "Forensic Psychology", "value": "307" }, { "text": "Human Services", "value": "308" }, { "text": "Organizational Psychology", "value": "310" }, { "text": "Sociology", "value": "311" }],

    "4": [{ "text": "General Computers & IT", "value": "400" }, { "text": "Coding Bootcamps", "value": "401" }, { "text": "Computer Programming", "value": "402" }, { "text": "Data Science & Analytics", "value": "403" }, { "text": "Game Art Development", "value": "404" }, { "text": "Information Systems", "value": "405" }, { "text": "Information Technology", "value": "406" }, { "text": "IT Project Managment", "value": "407" }, { "text": "Network Security", "value": "408" }, { "text": "Product and Graphic Design", "value": "409" }, { "text": "Software Engineering", "value": "410" }, { "text": "Web Development", "value": "411" }, { "text": "CyberSecurity", "value": "412" }],

    "5": [{ "text": "Medical Assisting", "value": "500" }, { "text": "Certified Nursing Assistant (CNA)", "value": "501" }, { "text": "Allied Health", "value": "502" }, { "text": "Emergency Management (EMT)", "value": "503" }, { "text": "Health Science", "value": "505" }, { "text": "Healthcare Administration", "value": "506" }, { "text": "Healthcare Management", "value": "507" }, { "text": "Dental or Pharmacy Technician", "value": "508" }, { "text": "Medical Billing & Coding", "value": "509" }, { "text": "Medical Office Assistant", "value": "510" }, { "text": "Nutition, Fitness, and Sports Science", "value": "511" }, { "text": "Public Health", "value": "512" }],

    "6": [{ "text": "General Education", "value": "600" }, { "text": "Adult Education & Learning", "value": "601" }, { "text": "Early Childhood Development", "value": "602" }, { "text": "Educational Administration", "value": "603" }, { "text": "K-12 Education", "value": "604" }, { "text": "Social Studies", "value": "605" }, { "text": "Special Education", "value": "606" }, { "text": "Teacher Liscensure", "value": "607" }],

    "7": [{ "text": "General Trade Vocational Career", "value": "700" }, { "text": "Automotive focused", "value": "701" }, { "text": "Aviation focused", "value": "702" }, { "text": "Certified Nursing Assistant (CNA)", "value": "703" }, { "text": "Construction management", "value": "704" }, { "text": "Electrical Technican", "value": "705" }, { "text": "Fire Science", "value": "706" }, { "text": "Truck Driver (CDL)", "value": "707" }, { "text": "HVAC", "value": "708" }, { "text": "Welding", "value": "709" }],

    "8": [{ "text": "General Liberal Arts", "value": "800" }, { "text": "Creative Writing & English", "value": "801" }, { "text": "Communications", "value": "802" }, { "text": "History", "value": "803" }, { "text": "Religious Studies", "value": "804" }, { "text": "Socialogy", "value": "805" }, { "text": "Humanities", "value": "806" }, { "text": "Political Science", "value": "807" }],
    "9": [{ "text": "Undecided", "value": "900" }],

    "10": [{ "text": "General Nursing", "value": "1000" }, { "text": "Entry Level", "value": "1001" }, { "text": "RN Degree Programs", "value": "1002" }, { "text": "RN to BSN Bridge Programs", "value": "1003" }, { "text": "MSN Programs", "value": "1004" }]
}

export const degreeInterestOptions: any = [
    {
        "type": "Certificate/Training Programs",
        "value": "1"
    },
    {
        "type": "Associates Degrees",
        "value": "2"
    },
    {
        "type": "Bachelor's Degrees",
        "value": "3"
    },
    {
        "type": "Master's Degrees",
        "value": "4"
    }
]

export const areaStudyOptions: any = [
    {
        "category_id": "1",
        "category_name": "Business"
    },
    {
        "category_id": "2",
        "category_name": "Criminal Justice"
    },
    {
        "category_id": "3",
        "category_name": "Psychology"
    },
    {
        "category_id": "4",
        "category_name": "Computers & IT"
    },
    {
        "category_id": "5",
        "category_name": "Healthcare"
    },
    {
        "category_id": "6",
        "category_name": "Education & Teaching"
    },
    {
        "category_id": "7",
        "category_name": "Vocational Training"
    },
    {
        "category_id": "8",
        "category_name": "Liberal Arts"
    },
    {
        "category_id": "9",
        "category_name": "Undecided"
    },
    {
        "category_id": "10",
        "category_name": "Nursing"
    }
]
