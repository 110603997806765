import {
  Box,
  Button,
  Container,
  Toolbar,
  Link,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { getResponse } from "../../services";
import { BLOG_DETAILS } from "../../constants/url/constantUrl";
import { useLocation, useNavigate } from "react-router-dom";
import { s3BlogImage, s3PrimaryLogo } from "../../constants/url/logoUrl";

const toolbarStyle = {
  minHeight: "71px",
};
export default function BlogDetailComponent() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [blogDetail, setBlogDetail] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    blogInforamtion();
    window.scrollTo(0, 0);
  }, []);

  const blogInforamtion = async () => {
    const blogDetailsResponse = await getResponse(
      `${BLOG_DETAILS}/${state.mBrandID}`
    );
    setBlogDetail(blogDetailsResponse);
    setLoading(false);
  };
  return (
    <div>
      <Toolbar style={toolbarStyle} />
      <Box className="short-info" py={3}>
        <Container>
          <Box display="flex" alignItems="center">
            <Box
              className="uni-info"
              display="flex"
              alignItems="center"
              flexGrow="1"
              gap={3}
            >
              <Box className="img-wrapper" mr={3} mt={1}>
                <img src={`${s3PrimaryLogo}${state.mBrandID}.webp`} alt="" />
              </Box>
              <Box>
                <Typography component="h2" fontWeight="800" fontSize="25px">
                  {state.mBrandName}
                </Typography>
              </Box>
            </Box>
            <Box className="info">
              <Button
                variant="contained"
                className="custom-btn"
                onClick={() =>
                  navigate("/get-information", { state: `${state.mBrandName}` })
                }
              >
                Request Information
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box pb={4} className="blog-info info-2">
        <Container>
          {loading ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div className="custom-loader"></div>
            </div>
          ) : (
            <div>
              <Box position="relative" display="flex" alignItems="center" className="blog-info-outer">
                <Box className="img-wrapper">
                  <img src={`${s3BlogImage}${state.mBrandID}.webp`} alt="" />
                </Box>
                <Box className="blog-info-box" p={3}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                    mb={4}
                  >
                    <Box ml={3} mt={1}>
                      <img
                        src={`${s3PrimaryLogo}${state.mBrandID}.webp`}
                        alt=""
                      />
                    </Box>
                    <Typography component="h2" fontSize="25px" fontWeight="600">
                      {state.mBrandName}
                    </Typography>

                  </Box>
                  <Box>
                    <Typography
                      variant="subtitle1"
                      color="text.secondary"
                      component="p"
                      lineHeight={1.3}
                      fontWeight="600"
                      className="subtitle"
                    >
                      {state.headContent}
                    </Typography>

                    <div
                      dangerouslySetInnerHTML={{ __html: state.bodyContent }}
                    />
                    <Box textAlign="center" mt={2}>
                      <Link href={state.clickURL} target="_blank">
                        <Button
                          variant="contained"
                          color="success"
                          size="large"
                          sx={{
                            textTransform: "none",
                            fontSize: "18px",
                            borderRadius: "8px",
                            minWidth: "150px",
                          }}
                          component={Link}
                        // to={state.clickURL}
                        >
                          Visit Site
                        </Button>
                      </Link>
                    </Box>
                  </Box>
                </Box>
              </Box>
              {blogDetail.sections?.map(
                (
                  detail: { title: string; description: string },
                  index: number
                ) => {
                  return (
                    <div key={index}>
                      <Typography component="h3" fontSize="25px" fontWeight="800" mb={1} mt={6}>
                        {detail.title}
                      </Typography>
                      <Typography>{detail.description}</Typography>
                    </div>
                  );
                }
              )}
            </div>
          )}
        </Container>
      </Box>
    </div>
  );
}
