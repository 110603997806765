import { Box, Container } from "@mui/material";
import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useLocation } from "react-router-dom";
import { questionAnswerFAQ } from "../constants/Faq/faqs";

function Faq() {
  const location = useLocation()
  const currentLocation = location.pathname
  const [expanded, setExpanded] = React.useState<boolean | string>(false);
  const handleChange = (panel: any) => (event: any, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <React.Fragment>
      {currentLocation === '/get-information' ? " " :
        <Box className="faq" py={5}>
          <Container>
            <Typography component="h2" fontWeight="800" color="#fff" fontSize="35px" mb={4}>Frequently Asked Questions</Typography>
            {questionAnswerFAQ.map((quesAns, index) => (
              <Accordion key={index}
                expanded={expanded === `panel${index}`}
                onChange={handleChange(`panel${index}`)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                  <Typography fontSize="20px" fontWeight="600">{quesAns.question} </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography> {quesAns.answer}</Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </Container>
        </Box>
      }
    </React.Fragment>

  );
}

export default Faq;
