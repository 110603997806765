import { TFaq } from "../../types/common";

export const questionAnswerFAQ: TFaq[] = [
    {
        question: 'What types of degrees can I pursue online, and how do they compare to traditional in-person degrees?',
        answer: "You can pursue a wide range of degrees online, from associate and bachelor's degrees to master's and even doctoral degrees. Online degrees often cover the same material as traditional in-person degrees, and many reputable institutions offer them. Ensure that the program you choose is accredited, as this will have a significant impact on the degree's recognition"
    },
    {
        question: 'How do online classes work, and what technical requirements will I need? ',
        answer: "Online classes typically utilize a virtual learning environment where you can access lectures, assignments, and interact with instructors and classmates. The technical requirements vary by institution but generally include a reliable internet connection, a computer or laptop with certain specifications, and sometimes specific software related to your course of study"
    },
    {
        question: 'Can I get financial aid for online college? ',
        answer: "Yes, financial aid is often available for online college just as it is for traditional college. It's essential to fill out the Free Application for Federal Student Aid (FAFSA) and consult with the financial aid office at the institution you're interested in. Keep in mind that eligibility may vary depending on the school and the specific program"
    },
    {
        question: 'How do I balance online college with work and other responsibilities? ',
        answer: "Online college offers flexibility that may make it easier to balance school with work and other life commitments. However, time management is key. Creating a schedule, setting goals, and seeking support from friends, family, or support services provided by the institution may help you maintain a healthy balance"
    },
    {
        question: 'What support services are available to online students? ',
        answer: "Many online colleges offer support services similar to those available to on-campus students. These might include academic advising, tutoring, library services, technical support, and career counseling. It's a good idea to explore what's available at your chosen institution and take advantage of these resources to ensure a successful online learning experience."
    },

]