import { TNavigationOption } from "../../types/route";

export const navigation: TNavigationOption[] = [
    { name: 'Business', href: '/business', state: { categoryName: 'Business' }, showInNavbar: true },
    { name: 'Criminal Justice', href: '/criminaljustice', state: { categoryName: 'Criminal Justice' }, showInNavbar: true },
    { name: 'Healthcare', href: '/healthcare', state: { categoryName: 'Healthcare' }, showInNavbar: true },
    { name: 'Computers & IT', href: '/computers&it', state: { categoryName: 'Computers & IT' }, showInNavbar: true },
    { name: 'Education & Teaching', href: '/education&teaching', state: { categoryName: 'Education & Teaching' }, showInNavbar: true },
    { name: 'Psychology', href: '/psychology', state: { categoryName: 'Psychology' }, showInNavbar: true },
    { name: 'Nursing', href: '/nursing', state: { categoryName: 'Nursing' }, showInNavbar: true },
    { name: 'Vocational Training', href: '/vocationaltraining', state: { categoryName: 'Vocational Training' }, showInNavbar: false },
    { name: 'Liberal Arts', href: '/liberalarts', state: { categoryName: 'Liberal Arts' }, showInNavbar: false },
    { name: 'Undecided', href: '/undecided', state: { categoryName: 'Undecided' }, showInNavbar: false }
]

// export const navigation: TNavigationOption[] = [
//     { name: 'Business', href: '/business/generalbusiness', state: { categoryName: 'Business' }, showInNavbar: true },
//     { name: 'Criminal Justice', href: '/criminaljustice/generalcriminaljustice', state: { categoryName: 'Criminal Justice' }, showInNavbar: true },
//     { name: 'Healthcare', href: '/healthcare/medicalassisting', state: { categoryName: 'Healthcare' }, showInNavbar: true },
//     { name: 'Computers & IT', href: '/computers&it/generalcomputers&it', state: { categoryName: 'Computers & IT' }, showInNavbar: true },
//     { name: 'Education & Teaching', href: '/education&teaching/generaleducation', state: { categoryName: 'Education & Teaching' }, showInNavbar: true },
//     { name: 'Psychology', href: '/psychology/generalpsychology', state: { categoryName: 'Psychology' }, showInNavbar: true },
//     { name: 'Nursing', href: '/nursing/generalnursing', state: { categoryName: 'Nursing' }, showInNavbar: true },
//     { name: 'Vocational Training', href: '/vocationaltraining/generaltradevocationalcareer', state: { categoryName: 'Vocational Training' }, showInNavbar: false },
//     { name: 'Liberal Arts', href: '/liberalarts/generalliberalarts', state: { categoryName: 'Liberal Arts' }, showInNavbar: false },
//     { name: 'General', href: '/general/general', state: { categoryName: 'General' }, showInNavbar: false }
// ]